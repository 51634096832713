import {getCategoryPrdList} from '../../../utils/main';

export const mutations = {
    SET_MAIN_BACKGROUND_IMAGE:(state,data)=> state.backgroundImgs =data,
    // SET_COMPANY_GOTO_SUB_TITLE:(state,data)=> state.companyList.title =data,
    // SET_COMPANY_GOTO_BANNER_LIST:(state,data)=> state.companyList.list =data,
    SET_BEST_PRD_SUB_TITLE:(state,data)=> state.bestTabCategory.title =data,
    SET_BEST_PRD_LIST (state,data) {
        let {list,activeDispNo,activeTabName,tabList} = getCategoryPrdList(data);
        state.bestPrdList = list;
        state.bestTabCategory = {...state.bestTabCategory,...{activeDispNo,activeTabName,tabList}};
    },
    SET_BEST_TAB_ACTIVE_DISP_NO:(state,data)=>state.bestTabCategory = {...state.bestTabCategory, ...{activeDispNo:data.dispObjNo, activeTabName:data.tabName}},
    SET_WEEKEND_SPECIAL_SUB_TITLE:(state,data)=> state.weekendSpcInfo.title =data,
    SET_WEEKEND_SPECIAL_PRD_LIST(state,data){

        let pageNum = Math.ceil(data.length / state.weekendSpcInfo.pageSize),
            pageNavigate = [],
            totalCnt = data.length;

        if(pageNum > 0){
            for (let i = 1; i <= pageNum; i++) {
                pageNavigate.push(i)
            }
        }

        let list = data.map(prd=>{
            if(prd.lnkBnnrImgUrl){
                prd.dpLivePrdBO.orgImgUrl = prd.lnkBnnrImgUrl
            }
            return prd.dpLivePrdBO;
        });
        state.weekendSpcList= list;
        state.weekendSpcInfo ={...state.weekendSpcInfo, ...{pageNum,pageNavigate,totalCnt}};
    },
    SET_WEEKEND_SPECIAL_CURRENTPAGE : (state,data) => state.weekendSpcInfo.currentPage = data,
    SET_TRIP_DEAL_SUB_TITLE:(state,data)=>state.dealTabCategory.title =data,
    SET_TRIP_DEAL_PRD_LIST(state,data){
        let {list,activeDispNo,activeTabName,tabList} = getCategoryPrdList(data);
        state.dealPrdList = list;
        state.dealTabCategory = {...state.dealTabCategory,...{activeDispNo,activeTabName,tabList}};
    },
    SET_DEAL_TAB_ACTIVE_DISP_NO:(state,data)=>state.dealTabCategory = {...state.dealTabCategory, ...{activeDispNo:data.dispObjNo, activeTabName:data.tabName}},
    SET_LINE_BANNER:(state,data)=>state.lineBanner=data,
    SET_PROMOTION_BANNER_SUB_TITLE:(state,data)=>state.promotionBanner.title=data,
    SET_PROMOTION_BANNER_LIST:(state,data)=>state.promotionBanner.list=data,
    SET_RECOMMEND_TOUR_LIST:(state,data)=>state.recomTourList=data,
    SET_BILLBOARD_BANNER_LIST:(state,data)=>state.billboardBanners=data,
    SET_TOUR_CATEGORY_LIST:(state,data)=>state.categoryList=data,
    SET_TOUR_MAJOR_CITY_LIST(state,data){
        let result =[];
        data.forEach(item=>{
            let cityList = [];
            if(item.cityList.length > 13){
                let num = Math.ceil(item.cityList.length/13);

                for (let i = 0; i < num; i++) {
                    let start = i*13,
                        end = (i+1)*13;

                    if(i ==0) cityList.push(item.cityList.slice(start,end));
                    else cityList.push(item.cityList.slice(start,end));
                }
            }else{
                cityList.push(item.cityList)
            }
            result.push({cityList, country: item.country});
        });
        state.majorList=result;

    },
    SET_POPULAR_CITY_LIST(state,data){

        state.popularCityList = data.map(city=>{
            let {dispObjNm, dispObjNo, extraText} = city;
            return {dispObjNm, dispObjNo};
        })
    },
    SET_DHOTEL_AUTO_COMPLETE_KEYWORD(state,data){

        let result = data.filter(item=>item.keywordBoList && item.keywordBoList.length > 0);

        state.dhotelKeyWords = result;

    },
    SET_ATOTEL_AUTO_COMPLETE_KEYWORD(state,{data,keyword}){

        if(data.length > 0){
            let formatList = data.reduce((acc, cur,currentIndex)=>{
                let type = cur.type;
                if(acc.hasOwnProperty(type)){
                    cur.index = currentIndex;
                    acc[type].data.push(cur);
                }
                return acc;
            },{
                city : {
                    name : "도시",
                    data : []
                },
                spot : {
                    name : "명소",
                    data : [],
                },
                airport : {
                    name : "공항",
                    data : []
                },
                hotel : {
                    name : "호텔",
                    data : []
                }
            });

            let result = [];
            Object.keys(formatList).forEach(type=>{
                result.push({title:formatList[type].name, keywordBoList:formatList[type].data})
            })

            state.ahotelKeyWords = result;
        }
    },
    SET_IS_SHOW_FLIGHT_QUICKSEARCH:(state,data)=> state.isFlight=data,
    SET_IS_INSPECT_TIME_TO_FLIGHT :(state,data) => state.inspectTimeToFlight = (data ==='TRUE'),
    SET_RECENT_SEARCH_KEYWORD:(state,data) =>state.recentKeyword =data,
    SET_MAIN_DOMESTIC_AREA_INFOS : (state, data) => {                     // 검색 주요지역선택 리스트

      let domesticAreaInfoList  = data.getDomesticAreaInfos;

      //지역 클릭 상태값에 대한 바인딩을 위해 추가 및 값 초기화
      domesticAreaInfoList.forEach((obj,index)=>{
        obj.isActive = index == 0?true:false;          //mobile에서 사용
        obj.stat2 = {'selected' : false};   //pc에서 사용

        let list = Object.assign([], obj.areaDetailList);
        obj.areaDetailList = list.filter(detail =>Number(detail.hotelCount) >0);

      });

      state.areaDetailList = domesticAreaInfoList;
    } ,
}

