import Swiper from "swiper";

export default {
  inserted(el, { value = {} }, vnode) {

    const rakeLogController = (value.stopRakeLog) ? false : true;

    const options = {
      slidesPerView: "auto",
      spaceBetween: 0,
      loop: false,
      on: {
        slideChange() {
          this.swiperActiveIndex = this.realIndex ? this.realIndex + 1 : 1;
          if(rakeLogController)
            window.rakeLog.scrollHandler();
        },
        transitionEnd() {
          if (this.params && this.params.loop === false) return;
          // swiper loop 모드 활성화시, duplicate된 dom은 클릭 이벤트 미적용 이슈.
          if (vnode.context.swiper) this.slideToLoop(this.realIndex, 0, false);
        }
      },
      ...value.options
    };

    vnode.context.swiper = new Swiper(el, options);
  

    vnode.context.$on("swiper-slideTo", index => slideToSwiper(index));
    vnode.context.$on("swiper-refresh", () => {
      vnode.context.swiper.update();
    });

    const slideToSwiper = index => {
      vnode.context.swiper.slideTo(index);
    };

    if(value.initSwiperIndex){
      slideToSwiper(value.initSwiperIndex);
    }
  },

  update(el, { value = {} }, vnode) {
    if (vnode.context.swiper) {
      vnode.context.swiper.update && vnode.context.swiper.update();
    }
  },

  componentUpdated(el, { value = {} }, vnode) {
    if (vnode.context.swiper) {
      vnode.context.swiper.update && vnode.context.swiper.update();
    }
  },

  unbind(el, binding, vnode) {
    if (vnode.context.swiper && el.id == vnode.context.swiper.el.id) {
      vnode.context.swiper.destroy && vnode.context.swiper.destroy();
    }
  }
};
