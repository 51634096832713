<template>
  <div class="trip_type option_2">
    <fieldset>
      <legend>숙박상품 검색</legend>
      <ul class="option_type_box">
        <li v-for="[key, tab] of tabs" :key="key" @click="selectedTab(key)">
          <input :id="'option_type_2_'+tab.index" name="type_select_2" class="radio_design" type="radio"
                 :checked="tab.isActive"/>
          <label :for="'option_type_2_'+tab.index" :class="['css-label',{on:tab.isActive}]">{{ tab.name }}</label></li>
      </ul>
      <!-- 국내 -->
      <dhotel-search v-if="tabsMap.get('DH').isActive"/>
      <ahotel-search v-else :popularCity="popularCity"/>
      <!-- TODO : 해외호텔만 노출하고 있음 -->
      <popular-city-list v-if="tabsMap.get('OH').isActive" :categoryId="tabsMap.get('OH').id" @setPopularCity="setPopularCity"/>
      <!--<popular-city-list v-show="tabsMap.get('dhotel').isActive" :categoryId="tabsMap.get('dhotel').id"/>-->
    </fieldset>
    <trip-company-list :bannerKey="bannerKey" :index="2"/>
  </div>
</template>

<script>
import * as CONST from '@/common/constants/tourConst' ;
import dhotelSearch from "./hotel/dhotelSearch";
import ahotelSearch from "./hotel/ahotelSearch";
import popularCityList from "./hotel/popularCityList";
import tripCompanyList from "./common/tripCompanyList";
import {QUICKSEARCH_HOTEL_SEARCH_TYPE} from '../../../utils/main'
export default {
    components: {
        dhotelSearch,
        ahotelSearch,
        popularCityList,
        tripCompanyList,
    },
    data() {
        return {
            bannerKey: CONST.PC_TOUR_MAIN_HOTEL_BRAND_LOGO_KEY,
            tabs: Array.from(QUICKSEARCH_HOTEL_SEARCH_TYPE),
            tabsMap: QUICKSEARCH_HOTEL_SEARCH_TYPE,
            popularCity : ""
        }
    },
    created() {
        this.selectedTab('DH')
    },
    methods:{
        selectedTab(selectedKey) {
            this.tabsMap.forEach((value, key) => value.isActive = key === selectedKey);
        },
        setPopularCity(data){
            this.popularCity = data;
        }
    }
}
</script>
