<template>
  <div class="season_recom_list">
    <h1><img :src="V_PC_IMG_URL+'/img/trip/common/tit_sam.png'" alt="요즘가면 좋은 추천 여행지"/></h1>
    <div class="list_box">
      <ul v-if="banners && banners.length > 0">
        <li v-for="(banner, index) in banners" :key="index">

          <a :href="banner.dispObjLnkUrl" v-if="banner" v-log="{ data: { area: 'recommend_tour', label: 'banner', body : {'content_type':'CATEGORY','content_name': banner.dispObjNm,
'content_no':banner.dispObjNo,'position_l2':index+1} }}">
            <div class="thumb_box">
              <img :src="V_UPLOAD_URL+banner.lnkBnnrImgUrl" :alt="banner.dispObjNm"
                   @error="setPcPrdBaseNoImg($event,'200')"/>
            </div>
            <div class="text_box">
              <p v-html="banner.extraText"></p>
            </div>
            <div class="category_box">
              <div class="dim"></div>
              <div class="info">
                <span :class="'icon '+banner.slctIconCd">{{ banner.dlvIcon }}</span>
                <p class="text">{{ banner.dispObjNm }}</p>
                <span class="result" v-if="banner.tmpltType=='ctgr'">여행상품 <strong>{{ banner.goodCnt | comma}}</strong>개<span
                  class="arrow"></span></span>
                <span class="result" v-else-if="banner.tmpltType=='prd'">상품보기<span class="arrow"></span></span>
              </div>
            </div>
          </a>
          <div v-else class="thumb_box">
            <img :src="V_PC_PRD_NO_IMG_100" alt="준비중">
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import log from '@/common/directives/log';
import {mapGetters} from "vuex";
import {V_PC_IMG_URL,V_PC_PRD_NO_IMG_100,V_UPLOAD_URL} from '@/common/const.js';
import {comma, setPcPrdBaseNoImg} from '@/common/utils.js';
export default {
    directives: {log},
    computed : {
        ...mapGetters({
            banners : 'TOUR_MAIN/GET_RECOMMEND_TOUR_LIST',
        })
    },
    data(){
        return {
            V_UPLOAD_URL,
            V_PC_IMG_URL,
            V_PC_PRD_NO_IMG_100
        }
    },
    methods : {
        setPcPrdBaseNoImg
    },
    filters : {
        comma
    },
}
</script>
