
export const getters = {
    GET_MAIN_BACKGROUND_IMAGE(state){
        if(state.backgroundImgs && state.backgroundImgs.length>0){
            return state.backgroundImgs[0].linkBannerImageUrl
        }else{
            return "";
        }
    },
    //GET_COMPANY_GOTO_LIST:(state)=>state.companyList,
    GET_BEST_PRD_LIST(state,getters){
        let list = [];
        if(state.bestPrdList && state.bestPrdList.length > 0){
            list = state.bestPrdList.filter(prd=>prd.dispObjNo ==getters.GET_BEST_TAB_CATEGORY.activeDispNo);
        }
        return list && list.length > 0? list[0].dpLivePrdBOList.slice(0,10) : null;
    },
    GET_BEST_TAB_CATEGORY :(state)=> state.bestTabCategory,
    GET_WEEKEND_SPECIAL_INFO:(state)=>state.weekendSpcInfo,
    GET_WEEKEND_SPECIAL_PAGING_LIST(state){
        const  {pageSize, currentPage} = state.weekendSpcInfo;
        const startIndex = (currentPage-1)*pageSize,
              endIndex = currentPage*pageSize;
        return state.weekendSpcList && state.weekendSpcList.length > 0? state.weekendSpcList.slice(startIndex,endIndex):null;
    },
    GET_DEAL_TAB_CATEGORY : (state)=>state.dealTabCategory,
    GET_DEAL_PRD_LIST(state,getters){
        let list = [];
        if(state.dealPrdList && state.dealPrdList.length > 0){
            list = state.dealPrdList.filter(prd=>prd.dispObjNo ==getters.GET_DEAL_TAB_CATEGORY.activeDispNo);
        }
        return list && list.length > 0? list[0].dpLivePrdBOList.slice(0,6) : null;
    },
    GET_LINE_BANNER:(state)=>state.lineBanner,
    GET_PROMOTION_BANNER_LIST(state){
        let list = null;
        if(state.promotionBanner.list&&state.promotionBanner.list.length > 0){
            list = state.promotionBanner.list.slice(0,2);
        }
        return {title: state.promotionBanner.title, list}
    },
    GET_RECOMMEND_TOUR_LIST:(state)=>state.recomTourList,
    GET_BILLBOARD_BANNER_LIST:(state)=>state.billboardBanners && state.billboardBanners.length > 0? state.billboardBanners.slice(0,10):null,
    GET_TOUR_CATEGORY_LIST :(state)=>state.categoryList,
    GET_TOUR_MAJOR_CITY_LIST:(state)=>state.majorList,
    GET_POPULAR_CITY_LIST: (state)=>state.popularCityList,
    GET_DHOTEL_AUTO_COMPLETE_KEYWORD: (state)=>state.dhotelKeyWords,
    GET_ATOTEL_AUTO_COMPLETE_KEYWORD: (state)=>state.ahotelKeyWords,
    GET_IS_SHOW_FLIGHT_QUICKSEARCH:(state)=> state.isFlight,
    GET_IS_INSPECT_TIME_TO_FLIGHT :(state) => state.inspectTimeToFlight,
    GET_RECENT_SEARCH_KEYWORD:(state) => state.recentKeyword,
    GET_RECENT_SEARCH_STORAGE:(state) => state.mainStorageData,
    GET_MAIN_DOMESTIC_AREA_INFOS : (state) => state.areaDetailList,
}
