import moment from "moment-timezone";
require("moment/locale/ko");
moment.locale("ko");

import {isEmpty} from "@/common/utils"
import axios from "axios"

const plugins = {
    install(Vue){
        Vue.prototype.$moment = moment;
        Vue.filter("moment", (value, format) => {
            if (!value) return "";
            return moment(value).format(format);
        });
        Vue.prototype.$isEmpty = isEmpty;
        Vue.prototype.$http = axios;
    }
}

export default plugins;
