//여행 카테고리 번호
export const PC_FLIGHT_CATEGORY_NO = "283010"  //항공권 카테고리 번호
export const DHOTEL_CATEGORY_NO = "980727"  //국내숙박 카테고리번호
export const AHOTE_CATEGORY_NO = "980731" // MW 해외호텔 카테고리번호
export const V_TOUR_LARGE_LCTGR_NO = "980724" //여행 대 카테고리 번호
export const V_TOUR_FLIGHT_CTGR_NO = "983422" //항공권 카테고리 번호
export const V_FOREIGN_PASS_MLCTGR_NO = "980732"; //해외패스 카테고리 번호
export const V_RENTCAR_MAIN_SCTGRNO_NO = "1018855"; //제주렌트카 메인 카테고리 번호
export const PC_AHOTEL_CATEGORY_NO = "952281"; //PC 해외호텔 카테고리 번호
export const PC_FOREIGN_PASS_CATEGORY_NO = "1017886";// PC 해외패스 중카테고리 번호
export const PC_LOCAL_TOUR_CATEGORY_NO = "1018648";// PC 현지투어 카테고리 번호
export const PC_FOREIGN_TOUR_CATEGORY_NO = "1017958";// PC 해외여행 카테고리 번호
export const TOUR_BEST_CATEGORY_NO = "2878"  //여행 베스트 카테고리번호
export const TOUR_DEAL_CATEGORY_NO = "1017958"  //여행 딜 카테고리번호
export const PC_DOMESTIC_CATEGORY_NO 	= "1017895"; //PC 국내숙박 GNB 카테고리 번호
export const TOUR_PACKAGE_CATEGORY_NO = "980730"; //MW 패키지 모바일 카테고리 번호
export const PC_TOUR_PACKAGE_CATEGORY_NO = "1017958"; //패키지 PC 카테고리 번호
//국내숙박 전시 구좌
export const PC_DHOTEL_MAIN_BANNER_KEY = "BN_1017895_8558";
export const MW_DHOTEL_MAIN_BANNER_KEY = "BN_1017895_8362";

export const MW_DHOTEL_MAIN_TYPE = {
    A : "DP_1017895_8360", // 국내숙박 메인 테마 상품 전시구좌
    B : "DP_2878_10160", //[MW] 국내숙박 메인_11번가 인기 테마 숙소(B타입)
    C : "DP_2878_10161", //[MW] 국내숙박 메인_MD 추천 테마 숙소(C타입)
    P : "DP_1017895_23807" // [MW] 국내숙박 메인_객실패키지
}

export const MW_TOUR_TYPE = {
    D : "DP_2878_10162"  //[MW] 국내숙박|제주렌터카 메인_인스턴트 배너 및 배경 이미지(D타입)
}

export const MW_DHOTEL_LISTING_BANNER_KEY = "BN_1017895_9815";
export const PC_DHOTEL_LISTING_BANNER_KEY = "BN_1017895_9816";

export const MW_DHOTEL_LISTING_COUPON = "DP_2878_10156"; //메인, 테마리스트 쿠폰 영역 전시 구좌
export const MW_DHOTEL_THEME_TITLE = "DP_2878_10131"; // 메인, 테마리스트 타이틀 및 서브 타이틀 전시구좌

export const MW_DHOTEL_DETAIL_BANNER_KEY = "BN_1017895_9817"; //MW 국내숙박 상품상세 배너
export const PC_DHOTEL_DETAIL_BANNER_KEY = "BN_1017895_9818";

//해외호텔 전시 구좌
export const MW_AHOTEL_LISTING_BANNER_KEY = "BN_952281_8778";
export const MW_AHOTEL_DETAIL_BANNER_KEY = "BN_952281_8924"; //MW 해외호텔 모바일 상세 배너
export const PC_AHOTEL_MAIN_BANNER_KEY = "BN_952281_8666"; // 해외호텔 메인 전시 배너
export const PC_AHOTEL_COMPANY_BANNER_KEY = "BN_2878_8866"; // 해외호텔 메인 제휴사 배너
export const PC_AHOTEL_DETAIL_BANNER_KEY = "BN_952281_8703"; //PC 해외호텔 상세 배너
export const MW_AHOTEL_MAIN_BANNER_KEY = "BN_952281_8548";	// MW 해외호텔 프로모션 배너 (띠배너)

//해외패키지 전시 구좌
export const MW_TOUR_PACKAGE_LISTING_BANNER_KEY = "BN_1017958_9388";

//여행 메인 전시 구좌
export const MW_TOUR_MAIN_CATEGIRY_LIST_DISPLAY_NO = "BN_2878_8829";	// MW 여행 메인 카테고리 리스트 구좌 번호
export const MW_TOUR_MAIN_LINE_BANNER_KEY = "BN_2878_8827";	// MW 여행 메인 라인 배너 리스트 구죄번호
export const MW_TOUR_MAIN_BILLBOARD_BANNER_KEY = "BN_2878_23550";	// MW 여행 메인 빌보드 배너 구좌 번호
export const MW_TOUR_MAIN_COUPON_BANNER_KEY = "BN_2878_23551";	// MW 여행 메인 쿠폰 바로가기 구좌 번호
export const MW_TOUR_MAIN_BRAND_THEMA_BANNER_KEY = "BN_2878_23552";	// MW 여행 메인 브랜드테마 구좌 번호
export const MW_TOUR_MAIN_DEAL_PRODUCT_KEY = "DP_2878_8135"; //여행 메인 딜 상품 구좌 번호

export const PC_TOUR_MAIN_BACKGROUND_IMG_DISPLAY_KEY ="BN_2878_8131"; //PC 여행 메인 배경이미지 구좌번호
export const PC_TOUR_MAIN_COMPANY_GOTO_SUB_TITLE_KEY = "DP_2878_8140"; //PC 여행사 바로가기 서브텍스트 구좌번호
export const PC_TOUR_MAIN_COMPANY_GOTO_BANNER_LIST_KEY = "BN_2878_8141"; //PC 여행사 바로가기 배너목록 구좌번호
export const PC_TOUR_MAIN_BEST_PRD_SUB_TITLE_KEY = "DP_2878_8138"; //PC 여행BEST 서브텍스트 구좌번호
export const PC_TOUR_MAIN_BEST_PRD_DISPLAY_LIST_KEY = "DP_2878_8139"; //PC 여행BEST 상품목록 구좌번호
export const PC_TOUR_MAIN_WEEKEND_SPECIAL_SUB_TITLE_KEY = "DP_2878_8136"; //PC 이번 주말에 뭐할까 서브텍스트 구좌번호
export const PC_TOUR_MAIN_WEEKEND_SPECIAL_DISPLAY_LIST_KEY = "DP_2878_8137"; //PC 이번 주말에 뭐할까 상품목록 구좌번호
export const PC_TOUR_MAIN_TRIP_DEAL_SUB_TITLE_KEY ="DP_2878_8134"; //PC 여행deal 서브텍스트 구좌번호
export const PC_TOUR_MAIN_TRIP_DEAL_DISPLAY_LIST_KEY ="DP_2878_8135"; //PC 여행deal 상품목록 구좌번호
export const PC_TOUR_MAIN_LINE_RENTCAR_BANNER_KEY = "BN_2878_9847"; //PC여행 렌트카 배너 구좌번호
export const PC_TOUR_MAIN_TOURISM_OFFICE_SUB_TITLE_KEY="DP_2878_8344"; //PC 여행 메인 관광청 배너 서브텍스트 구좌번호
export const PC_TOUR_MAIN_TOURISM_OFFICE_BANNER_KEY="BN_2878_8341"; //PC 여행 메인 관광청 배너 구좌번호
// export const PC_TOUR_MAIN_SEASON_RECOMMEND_BANNER_KEY="DP_2878_8132"; //PC 여행 메인 추천여행지 구좌번호 (서버로직에 구좌번호가 하드코딩 되어 있음.)
export const PC_TOUR_MAIN_BILL_BOARD_BANNER_KEY="BN_2878_8133"; //PC 여행 메인 빌보드배너 구좌번호
export const PC_TOUR_MAIN_TRAVEL_BRAND_LOGO_KEY = "BN_2878_8337"; //PC 여행 메인 퀵서치 여행 브랜드 로고 이미지 배너 구좌번호
export const PC_TOUR_MAIN_HOTEL_BRAND_LOGO_KEY= "BN_2878_8336"; //PC 여행 메인 퀵서치 호텔 브랜드 로고 이미지 배너 구좌번호
export const PC_TOUR_MAIN_FLIGHT_BRAND_LOGO_KEY= "BN_2878_8335"; //PC 여행 메인 퀵서치 호텔 브랜드 로고 이미지 배너 구좌번호

//여행 메인 퀵서치 카테고리 목록 조회
export const PC_TOUR_MAIN_QUICKSEARCH_JEJUTOUR_CATEGORY_ID ="TOURFRONT_MAIN_JEJUTOUR_CATEGORY_NO"; //PC 여행 메인 퀵서치 여행상품 : 제주여행 카테고리 property id
export const PC_TOUR_MAIN_QUICKSEARCH_DOMESTICTOUR_CATEGORY_ID ="TOURFRONT_MAIN_DOMESTICTOUR_CATEGORY_NO"; //PC 여행 메인 퀵서치 여행상품 : 국내여행 카테고리 property id
export const PC_TOUR_MAIN_QUICKSEARCH_OPASSTOUR_CATEGORY_ID ="TOURFRONT_MAIN_OPASSTOUR_CATEGORY_NO"; //PC 여행 메인 퀵서치 여행상품 : 해외패스 카테고리 property id
export const PC_TOUR_MAIN_QUICKSEARCH_APTOUR_CATEGORY_ID ="TOURFRONT_MAIN_APTOUR_CATEGORY_NO"; //PC 여행 메인 퀵서치 여행상품 : 현지투어 카테고리 property id
export const PC_TOUR_MAIN_QUICKSEARCH_POPULAR_CITY_CATEGORY_ID_AHOTEL = "TOURFRONT_MAIN_OVERSEAHOTCITY_CATEGORY_ID"; //PC 여행 메인 퀵서치 숙박 : 해외숙박
export const PC_TOUR_MAIN_QUICKSEARCH_POPULAR_CITY_CATEGORY_ID_DHOTEL = "TOURFRONT_MAIN_DOMESTICHOTCITY_CATEGORY_ID"; //PC 여행 메인 퀵서치 숙박 : 국내숙박


export const PC_OVERSEAS_TOUR_PRD_TYPE_PACKAGE = "68781481_15426"; //PC 여행 메인 여행상품 타입 : 패키지
export const PC_OVERSEAS_TOUR_PRD_TYPE_FREE_TRAVEL = "68781481_12421@68781481_11043"; //PC 여행 메인 여행상품 타입 : 자유/배낭

//제주렌트카 전시 구좌
export const MW_RENTCAR_MAIN_BANNER_KEY = "BN_952088_8958";

//해외호텔 키보드 이벤트 코드
export const KEY_CODE_BACK = 8;
export const KEY_CODE_ENTER = 13;
export const KEY_CODE_ARROW_UP = 38;
export const KEY_CODE_ARROW_DOWN = 40;

//해외호텔 메인 인기도시의 코드
export const POPULAR_SAIPAN_CODE = "6142849"; //사이판
export const POPULAR_CEBU_CODE = "6049857"; //세부
export const POPULAR_GUAM_CODE = "6023599"; //괌

//로컬 스토리지 OR 쿠키 KEY
export const PC_AHOTEL_MAIN_SEARCH_STORAGE_KEY = 'cookieSearchData'; //해외호텔메인 퀵서치 로컬스토리지  KEY
export const PC_AHOTEL_MAIN_RECENT_HOTEL_STORAGE_KEY ='cookieRecentHotel'; //해외호텔메인 최근본 호텔 로컬스토리지 KEY
export const DHOTEL_SEARCH_KEYWORD_KEY = 'dhotelSrchKwd'; //국내숙박 메인 퀵서치 로컬스토리지 KEY
export const DHOTEL_RECENT_HOTEL_STORAGE_KEY = 'tourDomesticDetailKey'; //국내숙박 메인 최근본 숙소 로컬스토리지 KEY
export const MW_TOUR_MAIN_COOCKIE_KEY = 'tourMainCookie'; //MW 여행메인 Coockie KEY
export const MW_RENTCAR_MAIN_STORAGE_KEY = 'rentcarStorage'; //MW 렌트카 로컬스토리지 KEY
export const MW_CATEGORY_LIST_FILTER_STORAGE_KEY = 'CATEGORY_LIST_FILTER_KEY'; //카테고리 목록 상품목록 필터 로컬스토리지 KEY
export const MW_AHOTEL_MAIN_RECENT_SEARCH_STORAGE_KEY='saveSearchParam';//해외호텔 최근검색 로컬스토리지 KEY
export const PC_MAIN_STORAGE_OLD_KEY = "TOURDETAIL2";//PC 기존 여행메인 여행상품 퀵서치 스토리지 키
export const PC_MAIN_SEARCH_TOUR_STORAGE_KEY = "MAIN_SEARCH_TOUR";//PC 신규 여행메인 여행상품 퀵서치 스토리지 키
export const TOUR_PASS_SEARCH_CITY_STORAGE_KEY = "passKeywordHis"; // 해외패스 도시 검색어 스토리지 KEY
export const TOUR_LIST_POSITION = "tourPosition";
export const PAKAGE_COMPARE_PRD_STORAGE_KEY = "PAKAGE_COMPARE_PRD_DATA"; //해외패키지 상품비교하기 스토리지 key


export const PAKAGE_SEARCH_PRD_STORAGE_KEY = "PAKAGE_SEARCH_PRD_DATA";
export const PAKAGE_SEARCH_DEL_STORAGE_KEY = "PAKAGE_SEARCH_DEL_DATA";

//정규식
export const NUMBER_REGEXP = /[^[0-9]/gi; //숫자만 체크
export const SPECIAL_CHARACTER_REGEXP = /[\{\}\[\]\/?.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/gi; //특수문자 체크
export const KOREA_LANGUAGE_CHECK_REGEXP =/[^ㄱ-ㅎㅏ-ㅣ가-힝]+/g; //한글만 체크

//카테고리 목록
export const TRAVEL_AGENCY = "DP_TOURTICKET_7615";	// 제휴여행사구좌정보

//cdn image dims quality 비율
export const CDN_IMAGE_DIMS_QUALITY_RATIO = "85";

// 여행 상품타입
export const PC_PRD_TYPE_TOUR = "29";
export const PC_PRD_TYPE_HOTEL = "30";

//국내 출발지 코드
export const DOMESTIC_STARTING_POINT_CODE_INCHEON_KIMPO = "68785302_68789697@68785302_68789698@68785302_68789705"; //인천/김포
export const DOMESTIC_STARTING_POINT_CODE_BUSAN = "68785302_68789700"; // 부산
export const DOMESTIC_STARTING_POINT_CODE_DAEGU = "68785302_68789701"; //대구
export const DOMESTIC_STARTING_POINT_CODE_GWANGJU_MUAN = "68785302_68789706@68785302_68789704"; //광주/무안
export const DOMESTIC_STARTING_POINT_CODE_JEJU = "68785302_68789699";//제주
export const DOMESTIC_STARTING_POINT_CODE_ETC = "68785302_68789702@68785302_68789703@68785302_68789707@68785302_68789708@68785302_68789709@68785302_68789710"; //기타



export const TOUR_PASS_MAIN_MAJOR_REGION = "DP_1017886_8669"; //해외패스 인기도시 구좌목록
export const TOUR_PASS_MAIN_RECOMMEND_CITY = "DP_1017886_8668"; //해외패스 추천 여행 도시
export const TOUR_PASS_THEME_TYPE_CODE = "104070097"; //해외패스 테마코드
export const TOUR_PASS_DELIVERY_TYPE_CODE = "23760248"; //해외패스 배송코드
export const ONLINETOUR_SELLER_NO = "42402601"; //온라인 투어 셀러번호





