var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "trip_type option_2" },
    [
      _c(
        "fieldset",
        [
          _c("legend", [_vm._v("숙박상품 검색")]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "option_type_box" },
            _vm._l(_vm.tabs, function (ref) {
              var key = ref[0]
              var tab = ref[1]
              return _c(
                "li",
                {
                  key: key,
                  on: {
                    click: function ($event) {
                      return _vm.selectedTab(key)
                    },
                  },
                },
                [
                  _c("input", {
                    staticClass: "radio_design",
                    attrs: {
                      id: "option_type_2_" + tab.index,
                      name: "type_select_2",
                      type: "radio",
                    },
                    domProps: { checked: tab.isActive },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      class: ["css-label", { on: tab.isActive }],
                      attrs: { for: "option_type_2_" + tab.index },
                    },
                    [_vm._v(_vm._s(tab.name))]
                  ),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _vm.tabsMap.get("DH").isActive
            ? _c("dhotel-search")
            : _c("ahotel-search", { attrs: { popularCity: _vm.popularCity } }),
          _vm._v(" "),
          _vm.tabsMap.get("OH").isActive
            ? _c("popular-city-list", {
                attrs: { categoryId: _vm.tabsMap.get("OH").id },
                on: { setPopularCity: _vm.setPopularCity },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("trip-company-list", {
        attrs: { bannerKey: _vm.bannerKey, index: 2 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }