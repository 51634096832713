var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.prdList && _vm.prdList.length > 0
    ? _c("section", { staticClass: "weekend_special_box" }, [
        _c(
          "div",
          { staticClass: "inner_box" },
          [
            _c("sub-title-comp", { attrs: { subTitle: _vm.prdinfo.title } }, [
              _c("h1", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v("이번 주말에 뭐할까?"),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "list_box", attrs: { id: "slideImage" } },
              [
                _c(
                  "ul",
                  { staticStyle: { width: "1525px" } },
                  _vm._l(_vm.prdList, function (prd, index) {
                    return _c(
                      "product-item-comp",
                      {
                        key: index,
                        attrs: {
                          prd: prd,
                          index: index,
                          area: "weekend_tour",
                          categoryNo: _vm.tabList.activeDispNo,
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            slot: "thumb",
                            src: _vm.imgConvert(prd.orgImgUrl),
                            alt: prd.prdNm,
                          },
                          on: {
                            error: function ($event) {
                              return _vm.setPcPrdBaseNoImg($event, 300)
                            },
                          },
                          slot: "thumb",
                        }),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "border_frame",
                          attrs: { slot: "border_frame_type1" },
                          slot: "border_frame_type1",
                        }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: {
                              slot: "company",
                              href: "javascript:",
                              "data-log-actionid-area": "weekend_tour",
                              "data-log-actionid-label": "category",
                              "data-log-body": JSON.stringify({
                                content_no: prd.dctgrNo
                                  ? prd.dctgrNo
                                  : prd.sctgrNo
                                  ? prd.sctgrNo
                                  : prd.mctgrNo,
                                content_type: "CATEGORY",
                                content_name: prd.dctgrNm
                                  ? prd.dctgrNm
                                  : _vm.sctgrNm,
                              }),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.moveCategory(prd, false)
                              },
                            },
                            slot: "company",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(prd.dctgrNm ? prd.dctgrNm : _vm.sctgrNm)
                            ),
                            _c("span", { staticClass: "arrow" }),
                          ]
                        ),
                      ]
                    )
                  }),
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "paging" },
                  _vm._l(_vm.prdinfo.pageNavigate, function (page, index) {
                    return _c(
                      "button",
                      {
                        key: index,
                        class: ["ico", { on: page == _vm.prdinfo.currentPage }],
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.pageChange(page)
                          },
                        },
                      },
                      [_vm._v("상품목록 " + _vm._s(page) + "\n        ")]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.prdinfo.totalCnt > _vm.prdinfo.pageSize
                  ? _c("p", { staticClass: "billboard_func" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn_paging btn_prev",
                          attrs: {
                            type: "button",
                            "data-log-actionid-area": "weekend_tour",
                            "data-log-actionid-label": "prev",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.pageChange(_vm.prdinfo.currentPage - 1)
                            },
                          },
                        },
                        [_vm._v("이전 상품\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn_paging btn_next",
                          attrs: {
                            type: "button",
                            "data-log-actionid-area": "weekend_tour",
                            "data-log-actionid-label": "next",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.pageChange(_vm.prdinfo.currentPage + 1)
                            },
                          },
                        },
                        [_vm._v("다음 상품\n        ")]
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }