import * as PATH from '@/common/const.js';
import axios from "axios";

export const getAxios = function makeRequestCreator(){
    var call;
    return function({query, variables}) {
        if (call) {
            call.cancel();
        }
        call = axios.CancelToken.source();
        return axios.post( PATH.V_TOUR_ROOT_GRAPHQL_ ,{
            query: query,
            variables : variables
        },{
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json'
            },
            cancelToken: call.token,
          });
    }
  }();

export const FETCH_GRAPHQL = ({variables,query}) =>{
    return axios.post( PATH.V_TOUR_ROOT_GRAPHQL_ ,{
        query: query,
        variables : variables
    },{
        headers: {
            'Cache-Control' : 'no-cache',
            'Access-Control-Allow-Origin' : '*',
            'Content-Type': 'application/json'
        }
    });
}

