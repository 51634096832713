var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.prdList && _vm.prdList.length > 0
    ? _c(
        "section",
        { staticClass: "trip_deal_box" },
        [
          _c("sub-title-comp", { attrs: { subTitle: _vm.tabList.title } }, [
            _c("h1", { attrs: { slot: "title" }, slot: "title" }, [
              _vm._v("여행딜"),
            ]),
          ]),
          _vm._v(" "),
          _c("category-tab-comp", {
            attrs: { prdInfo: _vm.tabList, id: "deal" },
          }),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "list_box" },
            _vm._l(_vm.prdList, function (prd, index) {
              return _c(
                "product-item-comp",
                {
                  key: index,
                  attrs: {
                    prd: prd,
                    index: index,
                    area: "tour_deal",
                    categoryNo: _vm.tabList.activeDispNo,
                  },
                },
                [
                  prd.dealPrdYn == "Y"
                    ? _c(
                        "span",
                        {
                          staticClass: "icon_shockingdeal",
                          attrs: { slot: "tag" },
                          slot: "tag",
                        },
                        [_vm._v("쇼킹딜")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "txt_op",
                      attrs: { slot: "promotionText" },
                      slot: "promotionText",
                    },
                    [_vm._v(_vm._s(prd.advrtStmt))]
                  ),
                  _vm._v(" "),
                  _c("img", {
                    attrs: {
                      slot: "thumb",
                      src: _vm.imgConvert(prd.lnkBnnrImgUrl),
                      alt: prd.prdNm,
                    },
                    on: {
                      error: function ($event) {
                        return _vm.setPcPrdBaseNoImg($event, "385x189")
                      },
                    },
                    slot: "thumb",
                  }),
                  _vm._v(" "),
                  prd.selQty > 0
                    ? _c(
                        "span",
                        {
                          staticClass: "buy_history",
                          attrs: { slot: "buyQty" },
                          slot: "buyQty",
                        },
                        [
                          _vm._v(
                            _vm._s(_vm._f("comma")(prd.selQty)) + "개 구매"
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.cutPercent(prd.buySatisfy) > 0
                    ? _c("div", { attrs: { slot: "star" }, slot: "star" }, [
                        _c(
                          "span",
                          {
                            class:
                              "selr_star selr_star" +
                              _vm.cutPercent(prd.buySatisfy),
                          },
                          [_vm._v("판매자 평점 별5개 중 5개")]
                        ),
                        _vm._v(" "),
                        _c("strong", { staticClass: "num" }, [
                          _vm._v(_vm._s(_vm.reviewScore(prd.buySatisfy))),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  prd.nckNmImgUrl
                    ? _c(
                        "span",
                        {
                          staticClass: "company",
                          attrs: { slot: "company" },
                          slot: "company",
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.V_PC_IMG_URL + prd.nckNmImgUrl,
                              alt: prd.sellerHmpgNm,
                            },
                            on: {
                              error: function ($event) {
                                _vm.V_PC_IMG_URL +
                                  "/img/tour/product/no_image_sellerlogo2.png"
                              },
                            },
                          }),
                        ]
                      )
                    : _c("span", {
                        staticClass: "company",
                        attrs: { slot: "company" },
                        domProps: { innerHTML: _vm._s(prd.sellerHmpgNm) },
                        slot: "company",
                      }),
                ]
              )
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }