var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.prd
    ? _c("li", [
        _c(
          "div",
          { staticClass: "product_type1" },
          [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "log",
                    rawName: "v-log",
                    value: {
                      data: {
                        area: _vm.area,
                        label: "product",
                        body: {
                          content_type: "PRODUCT",
                          position_l2: _vm.index + 1,
                          last_discount_price: _vm.prd.finalDscPrice,
                          content_no: _vm.prd.prdNo,
                          content_name: _vm.prd.prdNm,
                          product_no: _vm.prd.prdNo,
                          product_price: _vm.prd.finalDscPrc,
                          category_no: _vm.categoryNo,
                        },
                      },
                    },
                    expression:
                      "{ data: { area: area, label: 'product', body : { content_type : 'PRODUCT', position_l2:index+1, last_discount_price: prd.finalDscPrice,\ncontent_no:prd.prdNo,content_name:prd.prdNm,product_no:prd.prdNo,product_price:prd.finalDscPrc, category_no : categoryNo}}}",
                  },
                ],
                attrs: { href: "javascript:" },
                on: {
                  click: function ($event) {
                    return _vm.goSellerPrdDetail(_vm.prd.prdNo)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "thumb" },
                  [
                    _vm._t("tag"),
                    _vm._v(" "),
                    _vm._t("thumb"),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "line_frame",
                      staticStyle: { display: "none" },
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "summary_in" },
                  [
                    _c(
                      "div",
                      {
                        class: [
                          "subject",
                          { subject_row2: _vm.prd.prdNm.length > 19 },
                        ],
                      },
                      [_vm._v(_vm._s(_vm.prd.prdNm))]
                    ),
                    _vm._v(" "),
                    _vm._t("promotionText"),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class: [
                          "info",
                          {
                            have_price_normal:
                              _vm.prd.selPrc > _vm.prd.finalDscPrc,
                          },
                        ],
                      },
                      [
                        _vm.discountRate(_vm.prd.selPrc, _vm.prd.finalDscPrc) >
                        0
                          ? _c("span", { staticClass: "sale" }, [
                              _c("span", { staticClass: "n" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.discountRate(
                                      _vm.prd.selPrc,
                                      _vm.prd.finalDscPrc
                                    )
                                  )
                                ),
                              ]),
                              _vm._v("%"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", { staticClass: "price" }, [
                          _vm.prd.selPrc > _vm.prd.finalDscPrc
                            ? _c("span", { staticClass: "price_normal" }, [
                                _c("span", { staticClass: "skip" }, [
                                  _vm._v("정상가"),
                                ]),
                                _c("span", { staticClass: "n" }, [
                                  _vm._v(
                                    _vm._s(_vm._f("comma")(_vm.prd.selPrc))
                                  ),
                                ]),
                                _vm._v("원"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("span", { staticClass: "skip" }, [
                            _vm._v("판매가"),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "n" }, [
                            _vm._v(
                              _vm._s(_vm._f("comma")(_vm.prd.finalDscPrc))
                            ),
                          ]),
                          _vm._v("원"),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.prd.prdTypCdDe ==
                                      _vm.TOUR_CONST.PC_PRD_TYPE_TOUR ||
                                    _vm.prd.prdTypCdDe ==
                                      _vm.TOUR_CONST.PC_PRD_TYPE_HOTEL ||
                                    _vm.prd.optYn == "Y",
                                  expression:
                                    "prd.prdTypCdDe==TOUR_CONST.PC_PRD_TYPE_TOUR || prd.prdTypCdDe==TOUR_CONST.PC_PRD_TYPE_HOTEL || prd.optYn=='Y'",
                                },
                              ],
                            },
                            [_vm._v("~")]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._t("buyQty"),
                      ],
                      2
                    ),
                  ],
                  2
                ),
                _vm._v(" "),
                _vm._t("border_frame_type1"),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "add" },
              [_vm._t("star"), _vm._v(" "), _vm._t("company")],
              2
            ),
            _vm._v(" "),
            _vm._t("border_frame_type2"),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }