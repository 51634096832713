var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.keyWords && _vm.keyWords.length > 0
    ? _c("div", { staticClass: "search_history_box" }, [
        _c("p", { staticClass: "title" }, [_vm._v("최근 검색")]),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "list_box" },
          _vm._l(_vm.keyWords, function (word, index) {
            return _c("li", { key: index }, [
              word.method == "search"
                ? _c("div", [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:" },
                        on: {
                          click: function ($event) {
                            return _vm.search(word)
                          },
                        },
                      },
                      [
                        _c("strong", [_vm._v(_vm._s(word.startCityName))]),
                        _vm._v(" "),
                        _c("span", { staticClass: "type_1" }),
                        _c("strong", [_vm._v(_vm._s(word.tourCityName))]),
                        _c("span", { staticClass: "tx_bar" }),
                        _vm._v(" "),
                        word.dprtBgnDyGrpInfoList
                          ? _c("span", { staticClass: "date" }, [
                              _vm._v(
                                _vm._s(
                                  word.dprtBgnDyGrpInfoList.replace(/-/gi, "")
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn_del",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteWord(word)
                          },
                        },
                      },
                      [_vm._v("삭제")]
                    ),
                  ])
                : _c("div", [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:" },
                        on: {
                          click: function ($event) {
                            return _vm.search(word)
                          },
                        },
                      },
                      [_c("strong", [_vm._v(_vm._s(word.dispCtgrName))])]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn_del",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteWord(word)
                          },
                        },
                      },
                      [_vm._v("삭제")]
                    ),
                  ]),
            ])
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }