import {COMMON_API} from "@/common/api/commonApi";
import axios from "axios";
import * as PATH from '@/common/const';
import * as KEY from '@/common/constants/tourConst' ;
import {MAIN_API} from '../../../api/mainApi';
import {API_LIST} from "../../../../dhotel/api/mainApi";

axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers['Cache-Control'] ="no-cache";
import jsonp from "jsonp";
import {V_TOUR_ROOT_GRAPHQL_} from "@/common/const";

const FETCH_GRAPHQL = ({variables,query}) =>{
    return axios.post( PATH.V_TOUR_ROOT_GRAPHQL_ ,{
        query: query,
        variables : variables
    },{
        headers: {
            'Cache-Control' : 'no-cache',
            'Access-Control-Allow-Origin' : '*',
            'Content-Type': 'application/json'
        }
    });
}

export const actions = {

    FETCH_DISPLAY_OBJECT({commit},dispSpceId){
        return FETCH_GRAPHQL({
            query: COMMON_API.GET_DISPLAY_SPACE_BANNER,
            variables : {dispSpceId : dispSpceId}
        })
    },

    FETCH_MAIN_BACKGROUND_IMAGE({commit,dispatch}){
        dispatch('FETCH_DISPLAY_OBJECT',KEY.PC_TOUR_MAIN_BACKGROUND_IMG_DISPLAY_KEY).then(response=>{
            if(response.data.data.getDisplaySpaceObject){
                commit('SET_MAIN_BACKGROUND_IMAGE',response.data.data.getDisplaySpaceObject);
            }
        });
    },

    FETCH_BEST_PRD_LIST({commit},dispSpceId){
        FETCH_GRAPHQL({
            query: MAIN_API.GET_BEST_DISPLAY_PRD_LIST,
            variables : {displaySpaceId : dispSpceId, isDispPrd:true}
        }).then(response=>{
            if(response.data.data.getBestDispPrdList){
                commit('SET_BEST_PRD_LIST', response.data.data.getBestDispPrdList);
            }
        })
    },

    FETCH_WEEK_DISPLAY_PRD_LIST({commit},dispSpceId){
        FETCH_GRAPHQL({
            query: MAIN_API.GET_WEEK_DISPLAY_PRD_LIST,
            variables : {displaySpaceId : dispSpceId, isDispPrd:true}
        }).then(response=>{
            if(response.data.data.getWeekDispPrdList){
                commit('SET_WEEKEND_SPECIAL_PRD_LIST', response.data.data.getWeekDispPrdList);
            }
        })
    },

    FETCH_TRIP_DEAL_PRD_LIST({commit},dispSpceId){
        FETCH_GRAPHQL({
            query: MAIN_API.GET_TRIP_DEAL_PRD_LIST,
            variables : {displaySpaceId : dispSpceId, isDispPrd:true}
        }).then(response=>{
            if(response.data.data.getTripDealPrdList){
                commit('SET_TRIP_DEAL_PRD_LIST', response.data.data.getTripDealPrdList);
            }
        })
    },

    FETCH_RECOMMEND_TOUR_LIST({commit}){
        FETCH_GRAPHQL({
            query: MAIN_API.GET_RECOMMEND_TOUR_LIST,
            variables : {isDispPrd:true}
        }).then(response=>{
            if(response.data.data.getRecommendTourList){
                commit('SET_RECOMMEND_TOUR_LIST', response.data.data.getRecommendTourList);
            }
        })
    },

    FETCH_TOUR_CATEGORY_LIST({commit},categoryId){
        FETCH_GRAPHQL({
            query: MAIN_API.GET_TOUR_CATEGORY_LIST,
            variables : {categoryId}
        }).then(response=>{
            if(response.data.data.getTourCategoryList){
                commit('SET_TOUR_CATEGORY_LIST', response.data.data.getTourCategoryList);
            }
        })
    },

    FETCH_TOUR_MAJOR_CITY_LIST({commit}){
        FETCH_GRAPHQL({
            query: MAIN_API.GET_TOUR_MAJOR_CITY_LIST
        }).then(response=>{
            if(response.data.data.getTourMajorCityList){
                commit('SET_TOUR_MAJOR_CITY_LIST',response.data.data.getTourMajorCityList)
            }
        })
    },

    FETCH_POPULAR_CITY_LIST({commit},categoryId){
        FETCH_GRAPHQL({
            query: MAIN_API.GET_POPULAR_CITY_LIST,
            variables : {categoryId : categoryId, isDispPrd:true}
        }).then(response=>{
            if(response.data.data.getPopularCityList){
                commit('SET_POPULAR_CITY_LIST',response.data.data.getPopularCityList)
            }
        })
    },

    FETCH_DHOTEL_AUTO_COMPLETE_KEYWORD({commit},keyword){
        FETCH_GRAPHQL({
            query: MAIN_API.GET_AUTO_COMPLETE_KEYWORD,
            variables : {keyword}
        }).then(response=>{
            if(response.data.data.getDomesticACK){
                commit('SET_DHOTEL_AUTO_COMPLETE_KEYWORD',response.data.data.getDomesticACK)
            }
        })
    },

    FETCH_AHOTEL_AUTO_COMPLETE_KEYWORD({commit},keyword){
        FETCH_GRAPHQL({
            query: MAIN_API.GET_AHOTEL_AUTO_COMPLETE_KEYWORD,
            variables : {keyword}
        }).then(response=>{
            if(response.data.data.getAhotelAutoKeyWordList){
                commit('SET_ATOTEL_AUTO_COMPLETE_KEYWORD',{data:response.data.data.getAhotelAutoKeyWordList,keyword})
            }
        })

    },

    FETCH_POPULAR_CITY_KEYWORD({commit},keyword){
        return FETCH_GRAPHQL({
            query: MAIN_API.GET_AHOTEL_AUTO_COMPLETE_KEYWORD,
            variables : {keyword}
        })
    },

    FETCH_IS_INSPECT_TIME_TO_FLIGHT({commit}){
        FETCH_GRAPHQL({
          query: MAIN_API.GET_IS_INSPECT_TIME_TO_FLIGHT,
        }).then(response=>{
            commit('SET_IS_INSPECT_TIME_TO_FLIGHT',response.data.data.getIsInspectTimeToFlight)
        })
    },

    // 검색 주요지역선택 리스트
    FETCH_MAIN_DOMESTIC_AREA_INFOS : async({commit}) => {
      await axios.post( V_TOUR_ROOT_GRAPHQL_ ,
        { query : API_LIST.GET_MAIN_DOMESTIC_AREA_INFOS
        }).then(response=>{
        if(response?.data?.data !== null){
          commit('SET_MAIN_DOMESTIC_AREA_INFOS', response.data.data);
        }
      }).catch(error=>{
        console.log(error);
      });
    },
}
