<template>
  <span>
    <button type="button" class="info_input_1" @click="moveToCategoryMain">
      <span class="in_btn">{{data[0]}}</span>
    </button>
  </span>
</template>

<script>
import {goCategoryMain} from "../../../../utils/main";
import {PC_FOREIGN_TOUR_CATEGORY_NO} from '@/common/constants/tourConst';

export default {
    props : {
        data:{
            type: Array,
            default() {
                return null
            }
        }
    },
    data(){
        return {
            isSelectCity : false,
        }
    },
    methods: {
        moveToCategoryMain(){
          goCategoryMain(PC_FOREIGN_TOUR_CATEGORY_NO);
        }
    }
}
</script>
