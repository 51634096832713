
//import PATH from '../const.js'
import * as PATH from '@/common/const.js';
import {isNotEmptyValue, isEmpty} from '@/common/utils';
import * as TOUR_CONST from '@/common/constants/tourConst'

export const imageUtil = {

  getThumbImageMobile: function (baseImgUrl, width, height, ratioGb, quality) {
    return getThumbImgUrlDynamic(baseImgUrl, width, height, ratioGb, quality, 1, "");
  }
};

//pd/19/7/6/4/5/9/3/lWakG/2112764593_B.jpg 100,100,90,
export function getThumbImageMobile(baseImgUrl, width, height, ratioGb, quality){
    return getThumbImgUrlDynamic(baseImgUrl, width, height, ratioGb, quality, 1, "");
}

// ratioGb : 비율 유지 구분 : 0 - 비율 미유지, 1 - 비율 유지
// quality : 이미지 퀄리티 ( Max : 100 )
// maxFrame :  최대 프레임수 (default : 1)
// frameIndex : 추출할 프레임 인덱스 ( 0부터 시작, A : 전체 프레임에 대한 개별 URL 리스트 출력 )

//  /t/080/pd/17/9/9/0/2/6/8/DSstV/1244990268_B.jpg
// 위 이미지를 받아 아래와 같이 리턴
//  http://cdn.011st.com/ex_t/R/300x300/1/100/1/src/pd/17/9/9/0/2/6/8/DSstV/1244990268_B.jpg
function getThumbImgUrlDynamic(baseImgUrl, width, height, ratioGb, quality, maxFrame, frameIndex){

    let url = ""

    if ( !isEmpty(baseImgUrl) && 0 < width && width < 2000 && height < 2000 && maxFrame <= 10 )	{

        //패턴은 /t/080 같은 것을 제거
        let patt = new RegExp('/t/[0-9]{3}[A-Z_]*[0-9]*', 'g');

        baseImgUrl = baseImgUrl.replace(patt, "");

        let srcStr = "/src";
        // let index = baseImgUrl.toLowerCase().lastIndexOf(".");
        // if(index != -1){

        //     let ext = baseImgUrl.substring(index + 1).toLowerCase().trim();

        //     if (ext != "jpg" && ext != "jpeg") {
        //         srcStr = "/JPG/src";
        //         frameIndex = "0";
        //     }
        // }

        let tmpString = '';
        if(frameIndex == '') {
          tmpString = "";
        } else {
          tmpString = "/" + frameIndex;
        }

        url = PATH.getUploadURL();
        url += '/ex_t/R/';
        url +=  width;
        url +=  'x';
        url +=  height;
        url +=  '/';
        url +=  ratioGb;
        url +=  '/';
        url +=  quality;
        url +=  '/';
        url +=  maxFrame;
        url +=  tmpString;
        url +=  srcStr;
        url +=  baseImgUrl;
    }

    return url;
}

export function cropThumbImgUrl({imgurl, width, height, centerCrop, autoRatio}){

    if(imgurl.indexOf('http://') > -1 || imgurl.indexOf('https://') > -1){
        //console.log('check image');
        return imgurl;
    }

    if(imgurl.indexOf('RoL') > -1){
        return getThumbImgUrlDynamic(imgurl,width*2,height*2,1,85,1,"");
    }

    if ( !isEmpty(imgurl) && 0 < width && width < 2000 && height < 2000 ) {

        let patt = new RegExp('/t/[0-9]{3}[A-Z_]*[0-9]*', 'g');

        imgurl = imgurl.replace(patt, "");

        let baseArr = imgurl.split('?');
        let _width = width*2;
        let _height = height*2;

        let imageUrl = baseArr[0];
        let cropsize = { w: _width, h: _height }
        if(baseArr.length > 1){
            let hasSize = ((baseArr[1].indexOf('h') > -1) && (baseArr[1].indexOf('w') > -1));
            if(hasSize){
                let sizeArr = baseArr[1].split('&');
                let s_size = 0;
                if(sizeArr.length == 2){
                    let result = sizeArr.map(i=>parseInt(i.split('=')[1]));
                    s_size =  (result[0] < result[1]) ? result[0] : result[1];
                }
                if(s_size > 0){
                    cropsize.w = s_size;
                    cropsize.h = s_size;
                }
            }
        }
        //http://cdn.011st.com/11dims/thumbnail/350x350/11src/pd/18/5/6/1/0/7/8/XyvDt/2003561078_L300.jpg
        let centerCropUrl = '';
        if(centerCrop){
            centerCropUrl = `/cropcenter/${cropsize.w}x${cropsize.h}`
        }

        //비율 유지 구분 : true - 비율 미유지, false - 비율 유지
        let autoRatioStr = '';
        if(autoRatio){
            autoRatioStr = '!'; //비율 무시
        }

        return `${PATH.V_CDN_UPLOAD_URL}/11dims${centerCropUrl}/thumbnail/${_width}x${_height}${autoRatioStr}/11src${imageUrl}`
    }

    return "";
}

//국내숙박을 제외한 이미지 dims 처리
export function cropDimsImgUrl(data){

    let {imgurl, width=null, height=null, centerCrop=false, thumbs=false, quality=false, autoRatio=false, sharpen=false} = data;

    if (isEmpty(imgurl)){
        return "";
    }

    if(imgurl.includes(PATH.V_CDN_UPLOAD_URL)){
        return imgurl;
    }

    let imgsrc =`/11src/${imgurl}`;
    let _width = width;
    let _height = height;

    //11번가 이미지
    if(imgurl.includes(PATH.V_UPLOAD_URL)){

        if(imgurl.includes('http://')|| imgurl.includes('https://')){
            imgurl.replace('http:',"").replace('https:',"");
        }

        let src = imgurl.replace(PATH.V_UPLOAD_URL,"").replace("/ex_t/R/","");

        //상세 메인 이미지
        imgsrc = src;

        //썸네일
        if(src.includes("/src")){

            let options = src.split("/src/")[0].split("/")||[];
            imgsrc = `/11src/${src.split("/src/")[1]}`;

            if(isNotEmptyValue(options)){
                _width = (width != null? width:options[0].split("x")[0]);
                _height = (height != null? height:options[0].split("x")[1]);
            }
        }
    }

    //비율 유지 구분 : true - 비율 미유지, false - 비율 유지
    let _autoRatio = '';
    if(autoRatio){
        _autoRatio = '!'; //비율 무시
    }

    const _prefix = (thumbs||centerCrop||quality)?'/11dims':'',
          _resize = thumbs?`/resize/${_width}x${_height}${_autoRatio}`:'',
          _cropcenter = centerCrop?`/cropcenter/${_width}x${_height}`:'',
          _quality = quality?`/quality/${TOUR_CONST.CDN_IMAGE_DIMS_QUALITY_RATIO}`:'',
          _sharpen = (sharpen && _width !== _height)?"/sharpen/0x1":""; //PC 직사각형 이미지인 경우

    return `${PATH.V_CDN_UPLOAD_URL}${_prefix}${_cropcenter}${_resize}${_sharpen}${_quality}${imgsrc}`;
}

export function imgUrlPatternRemove(imgStr){
    if(!imgStr) return "";

    let imageUrl = imgStr;

    //_10으로 시작하는 이미지 URL제거
    let pattern1 = new RegExp('^_[0-9]{1,2}','g');
    imageUrl = imageUrl.replace(pattern1,"");

    // /t/100 or /t/100_5/ 패턴 제거 로직
    let pattern2 = new RegExp('/t/[0-9]{3}[A-Z_]*[0-9]*', 'g');
    imageUrl = imageUrl.replace(pattern2, "");


    let imageIndex = imageUrl.indexOf('/');
    if(imageIndex ==0){
        imageUrl = imageUrl.substr(1,imageUrl.length);
    }

    return imageUrl;
}

export function cropCdnImageSize({imgUrl, width, height}) {
    const srcTail = imgUrl.slice(imgUrl.lastIndexOf('11src')+'11src'.length);
    return `${PATH.V_CDN_UPLOAD_URL}/11dims/resize/${width}x${height}!/sharpen/0x1/quality/75/11src${srcTail}`
}

export function cropCdnImageSizeWithRawPath({width=124, height=124, imageRawPath}){
    return `https://cdn.011st.com/11dims/resize/${width}x${height}/quality/75/11src${imageRawPath}`;
}