import gql from 'graphql-tag';

import jsonp from "jsonp";
import * as PATH from '@/common/const.js';
import axios from 'axios';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers['Cache-Control'] ="no-cache";

const COMMON_FRAGMENT = {
    DISPLAY_CATEGORY_BO : `
        fragment  displayCategoryBO on DisplayCategoryBO{
            dispCtgrNo
            dispCtgrNm
            hgrnkCtgrNo
            url
            recentlyCreatedYn
        }
    `,
}

export const COMMON_API = {
    GET_DISPLAY_CATEGORY:`
        query($categoryNo : Long) {
            getTourDisplayAddCategoryies(categoryNo : $categoryNo) {
                dispCtgrNo
                dispCtgrCd
                dispCtgrNm
                prdTotCnt
                mobilePrdTotCnt
                abrdBrandYn
                dispCtgr1No
                dispCtgr2No
                dispCtgr3No
                dispCtgr4No
                dispCtgr1Nm
                dispCtgr2Nm
                dispCtgr3Nm
                dispCtgr4Nm
                dispCtgrKindCd
                dispCtgrUrl
                dispCtgrLevel
                categoryLinkUrl
            }
        }
    `,

    GET_CALENDAR_HOLIDAYS : `
        query {
            getHolidays {
                holidays
            }
        }
        `,

    GET_DISPLAY_SPACE_BANNER : `
          query ($dispSpceId : String){
              getDisplaySpaceObject(dispSpceId: $dispSpceId) {
                  linkBannerImageUrl
                  linkBannerImageUrl2
                  displayObjectName
                  displayObjectLinkUrl
                  displayObjectLinkUrl2
                  extraText
                  trackingNo
                  displayObjectNo
              }
          }
      `,

      GET_DISPLAY_SPACE_BANNER_TYPE : `
            query ($dispSpceId : String, $type : String){
                getDisplaySpaceObjectByType(dispSpceId: $dispSpceId, type : $type) {
                    linkBannerImageUrl
                    linkBannerImageUrl2
                    displayObjectName
                    displayObjectLinkUrl
                    displayObjectLinkUrl2
                    extraText
                    trackingNo
                    displayObjectNo
                    replaceTextContent
                }
            }
        `,

    GET_TOUR_FESTIVAL_IN_PROGRESS: `
        query{
            getTourFestival
        }
    `,
    GET_TOUR_FESTIVAL_DEFAULT : `
        query {
            getStayFestivalState
        }
    `,
    GET_TOUR_FESTIVAL_TIME: `
        query{
            getTourFestivalTime {
              festivalStart : checkInStart
              festivalEnd : checkInEnd
          }
        }
        `,

    //상품 상세 리뷰 좋아요 공통 API
    LIKE_KUKKUK_API : (param) =>{
        return  new Promise(function (resolve, reject) {
            jsonp(`${PATH.V_MW_OM_ROOT_URL_HTTPS_}/Tour/reviewLikeAjaxForJsonp.tmall?contMapNo=${param.contMapNo}&type=${param.type}`, null, (err, response)=>{
                if (err){
                    reject(err)
                }else{
                    resolve(response);
                }
            });
        });
    },

    FETCH_DISPLAY_BANNER(param){
        return axios.post(PATH.V_TOUR_ROOT_GRAPHQL_ ,
          {query: COMMON_API.GET_DISPLAY_SPACE_BANNER,  variables : {dispSpceId : param}})
    },

    FETCH_CALENDAR_HOLIDAYS (){
        return axios.post(PATH.V_TOUR_ROOT_GRAPHQL_ ,{query: COMMON_API.GET_CALENDAR_HOLIDAYS})
    },

    //최근본 상품 적재
    PUT_MY_HISTORY_LATELY_PRODUCT(productNo){
        return axios.get(`${PATH.V_MW_TOUR_ROOT_URL_}/Tour/recent?prdNo=${productNo}`)
    },

    GET_TOUR_COMMON_CATEGORY : `
        ${COMMON_FRAGMENT.DISPLAY_CATEGORY_BO}
        query{
            getDisplayCategoryObjectTourGnb {
                MainCategoryList {
                    ...displayCategoryBO
                    displayCategoryBOList{
                        ...displayCategoryBO
                    }
                }
                AllCategoryList {
                    ...displayCategoryBO
                    displayCategoryBOList{
                        ...displayCategoryBO
                    }
                }
                travelCpnObject{
                    ...displayCategoryBO
                }
                ExhibitionList{
                    ...displayCategoryBO
                }
                bestRankObject{
                    ...displayCategoryBO
                }
            }
        }
    `
}


