import Vue from 'vue'
import Vuex from 'vuex'
import {mutations} from './modules/mutations'
import {actions} from './modules/actions'
import {getters} from './modules/getters'
import {state} from './modules/state'

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules : {
        TOUR_MAIN : {
            namespaced: true,
            state,
            getters,
            mutations,
            actions
        }
    }
});
