import {isNotEmptyValue,stringUtil} from '@/common/utils.js'
import {V_PC_OM_ROOT_URL_HTTPS_,V_PC_TOUR_ROOT_URL_} from '@/common/const.js';
import * as CONST from '@/common/constants/tourConst' ;
import moment from "moment";
import {PC_MAIN_SEARCH_TOUR_STORAGE_KEY} from "@/common/constants/tourConst";

export function disCountRate(selPrc, finalDscPrc){
    if(selPrc > 0 && finalDscPrc > 0){
        return Math.round((((parseInt(selPrc) - parseInt(finalDscPrc)) * 100.0) / selPrc));
    }
    return 0;
}

export function isDisplayObjectCheck(data){
    return isNotEmptyValue(data) && data.hasOwnProperty('getDisplaySpaceObject') &&  isNotEmptyValue(data.getDisplaySpaceObject)
}

export function goSellerPrdDetail(prdNo){
    window.location.href =`${V_PC_OM_ROOT_URL_HTTPS_}/product/SellerProductDetail.tmall?method=getSellerProductDetail&prdNo=${prdNo}`;
}

export function goCategoryList(dispCtgrNo, dealPrdYn){

    const dealYn = dealPrdYn?'&dealPrdYn=Y':'';
    window.location.href = `${V_PC_TOUR_ROOT_URL_}/tour/TourfrontCategoryAction.tmall?method=getCategory&radius=5000&${dealYn}&dispCtgrNo=${dispCtgrNo}`;
}

export function goSearch(param){
  window.location.href = `${V_PC_TOUR_ROOT_URL_}/tour/TourfrontSearchAction.tmall?method=search&radius=5000&${param}`;
}

export function goCategoryMain(dispCtgrNo) {
  window.location.href = `${V_PC_TOUR_ROOT_URL_}/tour/TourfrontCategoryAction.tmall?method=getCategory&dispCtgrNo=${dispCtgrNo}`;
}

export function getCategoryPrdList(data){
    let tabList= [];
    let active_tab = {};
    const bestIndex = Math.floor(Math.random() * data.length);

    data.forEach((prd, index)=>{
        const  {dispObjNm, dispObjNo} = prd;
        let tab_list = stringUtil.trim(dispObjNm).split(">"),
            tabName = tab_list[tab_list.length-1];

        tabList.push({tabName,dispObjNo});

        //랜덤으로 탭 설정
        if(index == bestIndex){
            active_tab.activeDispNo = dispObjNo;
            active_tab.activeTabName = tabName;
        }
    });

    return {...{list:data, tabList},...active_tab};
}

export function checkCategory(dispObjNo,isDeal){
    let dispCtgrNo = dispObjNo;
    if(dispObjNo ==0 || dispObjNo =='' || dispObjNo ==null){
        dispCtgrNo = isDeal?CONST.TOUR_DEAL_CATEGORY_NO:CONST.TOUR_BEST_CATEGORY_NO;
    }

    return dispCtgrNo;
}

export const QUICKSEARCH_TRIP_SEARCH_TYPE = new Map([
    ["JT",{type: "JT",id:CONST.PC_TOUR_MAIN_QUICKSEARCH_JEJUTOUR_CATEGORY_ID, name:"제주여행",isActive:true, index:1, label : 'jeju_search'}],
    ["DT",{type: "DT",id:CONST.PC_TOUR_MAIN_QUICKSEARCH_DOMESTICTOUR_CATEGORY_ID, name : "국내여행",isActive:false, index:2, label : 'domestic_search'}],
    ["OT",{type: "OT",id:'', name:"해외여행",isActive:false , index:3,  label : 'abroad_search'}],
    ["PT",{type: "PT",id:CONST.PC_TOUR_MAIN_QUICKSEARCH_OPASSTOUR_CATEGORY_ID, name:"해외패스",isActive:false, index:4,  label : 'pass_search'}]
]);

export const DOMESTIC_STARTING_POINT_CODE = new Map([
    ["ICN_GMP",{type: "ICN_GMP",code:CONST.DOMESTIC_STARTING_POINT_CODE_INCHEON_KIMPO, name:"인천/김포"}],
    ["PUS",{type: "PUS",code:CONST.DOMESTIC_STARTING_POINT_CODE_BUSAN, name : "부산"}],
    ["TAE",{type: "TAE",code:CONST.DOMESTIC_STARTING_POINT_CODE_DAEGU, name:"대구"}],
    ["KWJ_MWX",{type: "KWJ_MWX",code:CONST.DOMESTIC_STARTING_POINT_CODE_GWANGJU_MUAN, name:"광주/무안"}],
    ["CJU",{type: "CJU",code:CONST.DOMESTIC_STARTING_POINT_CODE_JEJU, name:"제주"}],
    ["ETC",{type: "ETC",code:CONST.DOMESTIC_STARTING_POINT_CODE_ETC, name:"기타"}]
]);

export const TOUR_PRD_TYPE = new Map([
    ["package",{type: "package",code:CONST.PC_OVERSEAS_TOUR_PRD_TYPE_PACKAGE, name:"패키지",isActive:false}],
    ["free",{type: "free",code:CONST.PC_OVERSEAS_TOUR_PRD_TYPE_FREE_TRAVEL, name : "자유/배낭",isActive:false}]
])

export const QUICKSEARCH_HOTEL_SEARCH_TYPE = new Map([
  ["DH",{type: "DH", name:"국내",isActive:false, index:1, id:CONST.PC_TOUR_MAIN_QUICKSEARCH_POPULAR_CITY_CATEGORY_ID_DHOTEL}],
  ["OH",{type: "OH", name : "해외",isActive:false, index:2, id:CONST.PC_TOUR_MAIN_QUICKSEARCH_POPULAR_CITY_CATEGORY_ID_AHOTEL}],
]);

export function isParamCheck(to_val1, from_val1,to_val2, from_val2 ){

  let isSame = true;

  if(typeof to_val2 !=='undefined'&& typeof from_val2 !=='undefined'){

      if(to_val1 !== from_val1 || to_val2 !== from_val2){
          isSame = false;
      }

  }else{
      if(to_val1 !== from_val1){
          isSame = false;
      }
  }

  return isSame;
}

export function calendarFormat(date){
    if(!date) return "";
    return  moment(date).format('YYYY-MM-DD');
}

export const addDay = (addDay,format) => {
  return moment()
    .add(addDay, "days")
    .format(format);
}

export const getDefaultDate = (days,format) => {
  return [addDay(days,format),addDay(days+1,format)];
}


export function roomTatalCnt(rooms){
  rooms = (typeof rooms == 'string') ? [rooms] : rooms;

  if(Array.isArray(rooms)){
    let result = rooms.reduce((acc,cur) =>{
      let curArr = cur.split(',');
      let adultCnt = curArr.shift(); //adult cnt

      acc.adultCnt += parseInt(adultCnt);
      acc.childCnt += curArr.length;
      acc.roomCnt ++;

      return acc;

    },{ roomCnt: 0, adultCnt : 0, childCnt : 0});

    return result;
  }
};

export function generatorHahkey(type, data){
    let hashKey = "";
    if(type =='getCategory'){
        hashKey = `${type}-${data.dispCtgrNo}`
    }else{
        hashKey = `${type}-${data.dispCtgrNo}-${data.attrGrpInfoList[0]}-${data.attrGrpInfoList[1]}-${data.dprtBgnDyGrpInfoList}`;
    }

    return hashKey
}

export function setLocalStorage( data){
    localStorage.setItem(PC_MAIN_SEARCH_TOUR_STORAGE_KEY, JSON.stringify(data));
}





