var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "billboard_box" }, [
    _c("h2", { staticClass: "sr-only" }, [_vm._v("배너모음")]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "swiperScroll",
            rawName: "v-swiperScroll",
            value: { options: _vm.swiperOption, stopRakeLog: true },
            expression: "{ options : swiperOption , stopRakeLog : true }",
          },
        ],
        staticClass: "billboard swiper-container",
      },
      [
        _c(
          "ul",
          { staticClass: "swiper-wrapper" },
          _vm._l(_vm.banners, function (banner, index) {
            return _c("li", { key: index, staticClass: "swiper-slide" }, [
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "log",
                      rawName: "v-log",
                      value: {
                        data: {
                          area: "billboardbox",
                          label: "banner",
                          body: {
                            content_type: "EXHIBITION",
                            content_name: banner.displayObjectName,
                            content_no: banner.displayObjectNo,
                          },
                        },
                      },
                      expression:
                        "{ data: { area: 'billboardbox', label: 'banner', body : {'content_type':'EXHIBITION','content_name': banner.displayObjectName,\n'content_no':banner.displayObjectNo} }}",
                    },
                  ],
                  attrs: { href: banner.displayObjectLinkUrl },
                },
                [
                  _c("img", {
                    attrs: {
                      src: _vm.V_UPLOAD_URL + banner.linkBannerImageUrl,
                      alt: banner.displayObjectName,
                    },
                    on: {
                      error: function ($event) {
                        return _vm.setPcPrdBaseNoImg($event, "400")
                      },
                    },
                  }),
                ]
              ),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _vm._m(0),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "billboard_controls" }, [
      _c("p", { staticClass: "controls" }, [
        _c(
          "button",
          { staticClass: "prev swiper-button-prev", attrs: { type: "button" } },
          [_vm._v("이전 배너 보기")]
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "next swiper-button-next", attrs: { type: "button" } },
          [_vm._v("다음 배너 보기")]
        ),
      ]),
      _vm._v(" "),
      _c("nav", {
        staticClass: "pagination swiper-pagination",
        attrs: { "aria-level": "배너 모음 페이지 목록" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }