var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "option_select_box_3" }, [
    _c(
      "div",
      { staticClass: "option_detail" },
      [
        _c("input", {
          class: [
            "info_input_2",
            {
              focus: _vm.isShowLayer && _vm.rooms && _vm.rooms.length > 0,
              active: _vm.rooms && _vm.rooms.length > 0,
            },
          ],
          attrs: { type: "text", title: "객실별 인원수" },
          domProps: {
            value:
              _vm.rooms && _vm.rooms.length > 0
                ? "객실수" +
                  _vm.roomInfo.roomCnt +
                  " ( 성인" +
                  _vm.roomInfo.adultCnt +
                  "  ,소아" +
                  _vm.roomInfo.childCnt +
                  " )"
                : "객실선택",
          },
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn_type_02",
            attrs: { type: "button" },
            on: { click: _vm.layerOpen },
          },
          [_vm._m(0)]
        ),
        _vm._v(" "),
        _c("room-layer-comp", {
          attrs: { roomList: _vm.rooms, isShowLayer: _vm.isShowLayer },
          on: { closedLayer: _vm.layerClosed, setRoominfo: _vm.setRoominfo },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "in_btn" }, [
      _c("span", { staticClass: "ico" }),
      _vm._v("객실추가"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }