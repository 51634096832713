var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "option_select_box_1" }, [
    _c(
      "div",
      { staticClass: "option_detail" },
      [
        _c("input", {
          staticClass: "info_input_2",
          attrs: {
            id: "info_input_2",
            name: "info_input_2",
            title: "도시 또는 숙소명 입력",
            placeholder: "도시 또는 숙소명 입력",
          },
          domProps: { value: _vm.keyword },
          on: { input: _vm.updateInput, focusin: _vm.focusin },
        }),
        _vm._v(" "),
        _c("keyword-layer", {
          attrs: {
            isShow: _vm.isShowModal,
            keyword: _vm.keyword,
            type: _vm.type,
            citys: _vm.type == "DHOTEL" ? _vm.dhotelCitys : _vm.AhotelCitys,
          },
          on: { changeDate: _vm.changeDate },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }