<template>
  <section :class="['c-trip-gnb',{'trip-gnb-menu__button--selected' : isShowLayer},{'c-trip-gnb--fixed' : isFixed}  ]" :style="{top:stickyTop}" ref="categoryNavigator">
    <div class="inner">
      <h1 class="sr-only">여행 11번가</h1>
      <div class="trip-gnb-menu">
        <p class="trip-gnb-menu__button" v-on:mouseover="isShowCategoryList(true)" v-on:mouseout="isShowCategoryList(false)">
          <button type="button" aria-haspopup="dialog" aria-controls="arTripCategory" @click="goTravelMain">여행
            <em>11</em>번가
          </button>
        </p>
        <gnb-all-category-list  :list="categorys[0].list.all || []" :exhibitions="exhibitions" v-on:isShowCategoryList="isShowCategoryList" @sendToggle="setToggle"  />
      </div>
      <gnb-navi-sub-list :data="categorys[0].list.main || []" />
      <div class="trip-gnb-best" v-if="bestRank">
        <a v-log="{data: {area: getLogGnbArea(''), label: getLogGnbLabel('')}}"
           :href="bestRank.url" class="link">
          <span class="text">{{ bestRank.dispCtgrNm }}</span>
        </a>
      </div>
      <div class="trip-gnb-coupon" v-if="travelCoupon">
        <a v-log="{data: {area: getLogGnbArea(''), label: getLogGnbLabel('')}}"
           :href="travelCoupon.url" class="link">
          <span class="text">{{ travelCoupon.dispCtgrNm }}</span>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import {COMMON_API} from "@/common/api/commonApi";
import {FETCH_GRAPHQL} from '@/common/utils/axios';
import { PC_AHOTEL_CATEGORY_NO,PC_DOMESTIC_CATEGORY_NO} from "@/common/constants/tourConst";
import { V_PC_TOUR_ROOT_URL_ } from "@/common/const";
import log from '@/common/directives/log';

export default {
  directives : {log},
  components: {
    gnbNaviSubList : () => import(/* webpackChunkName: "view-common" */"@/common/components/category/gnbNaviSubList"),
    gnbAllCategoryList : () => import(/* webpackChunkName: "view-common" */"@/common/components/category/gnbAllCategoryList")
  },
  data(){
    const CATEGORY_LIST = [
      { key: "all", name : "전체",  list:{}}
    ];

    return {
      categorys : CATEGORY_LIST,
      travelCoupon : {},
      bestRank : {},
      exhibitions : [],
      PC_AHOTEL_CATEGORY_NO,
      PC_DOMESTIC_CATEGORY_NO,
      isShowLayer : false,
      layerKey : "",
      isFixed : false,
      stickyTop : ""
    }
  },
  created() {
    this.fetchApi();
  },
  mounted() {
    this.stickyInit();
    this.fixedNavigator();
  },
  beforeDestroy() {
    pcComm.event.off("toggleGnb");
  },
  methods : {
    async fetchApi(){
      try {
        const response = await FETCH_GRAPHQL({
          query : COMMON_API.GET_TOUR_COMMON_CATEGORY
        });

        if (!response.data.data || !response.data.data.getDisplayCategoryObjectTourGnb) {
          throw Error('공통 카테고리 조회 실패');
        }

        this.commitCategory(response.data.data.getDisplayCategoryObjectTourGnb);

      }catch (e) {
        console.log(e);
      }
    },
    commitCategory(data){
      this.categorys.forEach(value=>{
        value.list = {main:data[`MainCategoryList`], all:data[`AllCategoryList`]};
      })
      this.travelCoupon = data.travelCpnObject;
      this.exhibitions = data.ExhibitionList;
      this.bestRank = data.bestRankObject
    },
    goTravelMain() {
      window.location.href = `${V_PC_TOUR_ROOT_URL_}/tour/TourfrontAction.tmall?method=main`;
    },
    isShowCategoryList(key) {
      this.isShowLayer = key
    },
    openLayer(key){
      let keys = this.categorys.map(item=>item.key);
      keys.forEach(ctg=>{
        if(ctg === key){
          this.$refs[`${ctg}_layer`][0].open();
          this.layerKey = key;
        }else{
          this.$refs[`${ctg}_layer`][0].close();
        }
      });
    },
    closeLayer(){
      if(this.isShowLayer){
        this.$refs[`${this.layerKey}_layer`][0].close();
        this.isShowLayer = false;
      }
    },
    setToggle(data){
      this.isShowLayer =data;
    },
    fixedNavigator() {
      const fixedClass = "c-trip-gnb--fixed";
      const tripHeader = state => {
        const $el  = this.$refs.categoryNavigator;
        console.log('state.isFixed' , state.isFixed)
        console.log('state.height' , state.height);
        if (state.isFixed && state.height > 0) {
          if (!$el.classList.contains(fixedClass)) {
            this.setSticky(true, `${state.height}px`);
          }
        } else {
          if ($el.classList.contains(fixedClass)) {
            this.setSticky(false, "");
          }
        }
      };
      pcComm.event.on("toggleGnb", tripHeader);
    },
    setSticky(isFixed, top){
      this.isFixed = isFixed;
      this.stickyTop = top;
    },
    stickyInit(){
      const scrollY =  window.scrollY || document.documentElement.scrollTop;
      if(scrollY > 0 && !this.isFixed){
        this.setSticky(true, `${this.$refs.categoryNavigator.clientHeight}px`);
      }
    },
    getLogGnbArea(key) {
      if(key == 'foreign') {return 'tour_gnb_category_abr'}
      if(key == 'domestic') {return 'tour_gnb_category_dom'}
      return 'tour_gnb_category'
    },
    getLogGnbLabel(key) {
      if(key == 'foreign' || key == 'domestic') {return 'category'}
      return 'coupon'
    }
  }
}
</script>
