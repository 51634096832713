const V_ROL_IMG_PREFIX_URL = "http://cdn.011st.com/11st-hotel/w420h420,crop_middle,q85/rolPic.jpg?src="
const V_IMG_URL  = "https://s.011st.com"
const TOUR_LARGE_LCTGR_NO = '980724' //여행 대 카테고리 번호
const TOUR_BENNER_DISPLAY_ID = 'BN_1017895_9818' //국내숙박 상품상세 배너 전구좌번호
const TOUR_BANNER_MOBILE_DISPLAY_ID = 'BN_1017895_9817'
export const TOUR_PACKAGE_MAIN_EVENT_NO = "TOURPKGMAIN_BN_NN_203A64B9" //패키지 메인 이벤트 베너(큰 베너)
export const TOUR_PACKAGE_MAIN_PROMOTION_NO = "TOURPKGMAINPRMO_BN_NN_8F25191B" //패키지 메인 프로모션 베너(작은배너, pc)
export const V_UPLOAD_URL = "//cdn.011st.com"

//사용안함
export function getRolImgUrl(){
    return (typeof _ROL_IMG_PREFIX_PC == "undefined") ? V_ROL_IMG_PREFIX_URL : _ROL_IMG_PREFIX_PC;
}

export function getIMGURL(){
    return (typeof _IMG_URL_ == "undefined") ? V_IMG_URL : _IMG_URL_;
}

export function getUploadURL(){
    return (typeof _UPLOAD_URL_ == "undefined") ? V_UPLOAD_URL : _UPLOAD_URL_;
}

export function getTourImageUrl(){
  return (typeof _TOUR_PRODUCT_IMG_URL_ == "undefined") ? V_UPLOAD_URL : _TOUR_PRODUCT_IMG_URL_;
}

export  function getTourLargeCategoryNo(){
    return TOUR_LARGE_LCTGR_NO;
}

export  function getTourBennerDisplayId() {
    return TOUR_BENNER_DISPLAY_ID
}

export  function getTourBannerMobileDisplayId() {
    return TOUR_BANNER_MOBILE_DISPLAY_ID
}

//MW OM
export const V_MW_OM_ROOT_URL_ORIGINAL = "//m.11st.co.kr"
export const V_MW_OM_ROOT_URL_ = "//m.11st.co.kr/MW"
export const V_MW_OM_ROOT_URL_HTTP_ = "http://m.11st.co.kr/MW"
export const V_MW_OM_ROOT_URL_HTTPS_ = "https://m.11st.co.kr/MW"

//MW TOUR
export const V_MW_TOUR_PROD_URL_ = "//tour.m.11st.co.kr"
export const V_MW_TOUR_ORIGIN_DOMAIN_ = `${process.env.V_MW_TOUR_ORIGIN_DOMAIN_}`
export const V_MW_TOUR_ROOT_URL_ = `${V_MW_TOUR_ORIGIN_DOMAIN_}/MW`;
export const V_MW_TOUR_ROOT_URL_HTTP_ = `http:${V_MW_TOUR_ROOT_URL_}`;
export const V_MW_TOUR_ROOT_URL_HTTPS_ = `https:${V_MW_TOUR_ROOT_URL_}`;

//PC OM
export const V_PC_OM_ROOT_URL_ = "//www.11st.co.kr"
export const V_PC_OM_ROOT_URL_HTTP_ = "http://www.11st.co.kr"
export const V_PC_OM_ROOT_URL_HTTPS_ = "https://www.11st.co.kr"

//PC TOUR
export const V_PC_TOUR_PROD_URL_ = "//tour.11st.co.kr"
export const V_PC_TOUR_ROOT_URL_ = `${process.env.V_PC_TOUR_ROOT_URL_}`;
export const V_PC_TOUR_ROOT_URL_HTTP_ = `http:${V_PC_TOUR_ROOT_URL_}`;
export const V_PC_TOUR_ROOT_URL_HTTPS_ = `https:${V_PC_TOUR_ROOT_URL_}`;

export const V_TOUR_ROOT_GRAPHQL_ = "/graphql";

//ImageUrl & CSS
export const V_UPLOAD_IMG_PATH  = "https://cdn.011st.com";
export const V_CDN_UPLOAD_URL = "//cdn.011st.com";
export const V_PC_COMMON_CSS_URL = "http://c.011st.com";
export const V_MW_COMMON_CSS_URL = "http://c.m.011st.com/MW";
export const V_PC_IMG_URL  = "//s.011st.com";

//Common URL
export const V_MW_CATALOG_URL = "http://catalog.11st.co.kr/MW"; //포토리뷰 전체 보기 URL

//Common Image
export const V_MW_OG_IMG_PATH  = `${V_UPLOAD_URL}/ui_img/mw/tour/package/share_travel_11st.jpg`;


//BackgroundImage
export const V_PC_MAIN_BG_IMG = `${V_UPLOAD_URL}/browsing/banner/2017/12/06/14985/2017120614120620560_9915099_1.jpg`;
export const V_MW_SEARCH_BG_IMG = `${V_MW_OM_ROOT_URL_HTTPS_}/img/tour/search_bgimg_720x160.jpg`


//Loading Image
export const V_MW_LOADING_IMG = `${V_IMG_URL}/img/trip/common/loading_tour.gif`

//No Image
export const V_MW_PRD_BASE_IMG_720_360 =  `${V_MW_OM_ROOT_URL_HTTPS_}/img/tour/product/no_image_720x360.png`;
export const V_MW_LIST_PRD_BASE_IMG = `${V_MW_OM_ROOT_URL_HTTPS_}/img/tour/listing/img_noimg_list.png`;
export const V_MW_PRD_BASE_IMG = `${V_MW_OM_ROOT_URL_HTTPS_}/img/tour/product/no_image_360x360.png`;
export const V_PC_AHOTEL_NO_IMG = `${V_PC_IMG_URL}/img/trip/abroad_hotel/img_noimg_search.jpg`;
export const V_PC_PRD_NO_IMG_230 =`${V_PC_IMG_URL}/img/prd_size/noimg_230.gif`; //PC 230 no image
export const V_PC_PRD_NO_IMG_300 =`${V_PC_IMG_URL}/img/prd_size/noimg_300.gif`; //PC 300 no image
export const V_PC_PRD_NO_IMG_385x189 =`${V_PC_IMG_URL}/img/prd_size/noimg_385x189.gif`; //PC 300 no image
export const V_PC_PRD_NO_IMG_400 =`${V_PC_IMG_URL}/img/prd_size/noimg_400x400.gif`; //PC 400 no image
export const V_PC_PRD_NO_IMG_200 =`${V_PC_IMG_URL}/img/prd_size/noimg_200.gif`; //PC 200 no image
export const V_PC_PRD_NO_IMG_100 =`${V_PC_IMG_URL}/img/prd_size/noimg_100.gif`; //PC 100 no image
export const V_PC_RESERVATION_NO_IMG =`${V_PC_OM_ROOT_URL_}/img/product/renew/roading.gif`; //PC 예약서 no image
export const V_PC_PRD_NO_IMG_65 =`${V_PC_IMG_URL}/img/prd_size/noimg_65.gif`; //PC 65 no image
//Time
export const DELAY_TIME_15M = 900000; //15분

export const V_MW_SHOP_PATH = 'https://shop.11st.co.kr/m'
export const V_PC_SHOP_PATH = 'https://shop.11st.co.kr/gateway/home/prdNo'
export const V_FLIGHT_ROOT_URL_HTTP_ = "http://flight.m.11st.co.kr/m";
export const V_PC_FLIGHT_ROOT_URL_HTTPS_ = "https://flight.11st.co.kr";

export const V_CATEGORY_PRODUCT_LIST_API_PATH = `${V_MW_TOUR_ROOT_URL_}/Tour/internalSearch.tmall`; //카테고리 상품 목록 조회 API
export const V_MW_SELLER_IMG_80x22 =`${V_MW_OM_ROOT_URL_}/img/tour/product/no_image_80x22.png`;
export const V_MW_OM_PRD_DETAIL_URL = `${V_MW_OM_ROOT_URL_}/Product/productBasicInfo.tmall?mallType=tour`; //OM  상품 상세 페이지 URL

export const LOGIN_URL = `${V_MW_OM_ROOT_URL_HTTPS_}/Login/login.tmall`;
export const LOGIN_URL_PC = `https://login.11st.co.kr/auth/front/login.tmall`;

export const LOGIN_URL_RETURN_URL = `${LOGIN_URL}?returnURL=${encodeURIComponent(encodeURIComponent(window.location.href))}`;

export const FORM_ACTION_URL = "/tour/reservation/TourReservationAction.tmall"; //PC 예약하기 url

export const CUSTOMER_CENTER_URL = `${V_MW_OM_ROOT_URL_}/Customer/V1/customerMain.tmall`
export const PC_CUSTOMER_CENTER_URL = "//cs.11st.co.kr/page/customer"
