<template>
  <div class="search_history_box" v-if="keyWords && keyWords.length > 0">
    <p class="title">최근 검색</p>
    <ul class="list_box">
      <li v-for="(word, index) in keyWords" :key="index">
        <div v-if="word.method== 'search'">
          <a href="javascript:" @click="search(word)">
            <strong>{{ word.startCityName }}</strong>
            <span class="type_1"></span><strong>{{ word.tourCityName }}</strong><span class="tx_bar"></span>
            <span class="date" v-if="word.dprtBgnDyGrpInfoList">{{ word.dprtBgnDyGrpInfoList.replace(/-/gi, "")}}</span>
          </a>
          <button type="button" class="btn_del" @click="deleteWord(word)">삭제</button>
        </div>
        <div v-else>
          <a href="javascript:" @click="search(word)"><strong>{{ word.dispCtgrName }}</strong></a>
          <button type="button" class="btn_del" @click="deleteWord(word)">삭제</button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {goCategoryList, goSearch,setLocalStorage} from '../../../../utils/main';
import qs from "qs";
export default {
    computed : {
        ...mapGetters({
            keyWords : 'TOUR_MAIN/GET_RECENT_SEARCH_KEYWORD',
            mainStorageData : 'TOUR_MAIN/GET_RECENT_SEARCH_STORAGE',
        })
    },
    methods:{
        deleteWord(curData){
            let list = Object.assign([],this.keyWords);

            const findIndex = list.findIndex(item => item.hashKey === curData.hashKey);
            if (findIndex > -1) {
                list.splice(findIndex, 1);
            }

            this.$store.commit('TOUR_MAIN/SET_RECENT_SEARCH_KEYWORD',list);
            this.mainStorageData.resetItem();

            if(list && list.length > 0){
                setLocalStorage(list);
            }
        },
        search(data){
            if(data.method== 'search'){
                const {dprtBgnDyGrpInfoList, startCity, tourCity, dispCtgrNo,} = data;
                let param = {dprtBgnDyGrpInfoList, startCity, tourCity, dispCtgrNo};
                let queryString = qs.stringify(param) +  "&attrGrpInfoList=" + data.attrGrpInfoList.join('&attrGrpInfoList=');
                goSearch(queryString);

            }else{
                goCategoryList(data.dispCtgrNo, false)
            }
        }
    }
}
</script>

