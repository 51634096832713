<template>
  <div id="gTabCont1" class="trip_type option_1">
    <flight-form v-show="!isInspectTimeToFlight"></flight-form>
    <p v-show="isInspectTimeToFlight" class="flight_inspection_box">더 나은 서비스 제공을 위해 항공권 가격 비교 서비스 점검을 하고 있습니다. 이용에 불편을드려서 죄송합니다.</p>
    <trip-company-list :bannerKey="bannerKey" :index="1"/>
  </div>
</template>

<script>
import flightForm from "./flight/flightForm"
import tripCompanyList from "../quickSearch/common/tripCompanyList";
import * as CONST from '@/common/constants/tourConst' ;
import {mapGetters} from "vuex";
export default {
    components: {
        flightForm,
        tripCompanyList
    },
    data() {
          return {
              bannerKey: CONST.PC_TOUR_MAIN_FLIGHT_BRAND_LOGO_KEY,
          }
    },
    computed : {
        ...mapGetters({
            isInspectTimeToFlight : 'TOUR_MAIN/GET_IS_INSPECT_TIME_TO_FLIGHT',
        })
    }
}
</script>

