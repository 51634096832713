var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "option_check_box_2" },
    [
      _c("keyword-box", {
        attrs: { type: "AHOTEL", popularWord: _vm.keyWord },
        on: { sendKeyword: _vm.setKeyword },
      }),
      _vm._v(" "),
      _c("calendar-box", {
        attrs: { startDate: _vm.defaultDate, minDate: _vm.minDate },
        on: { sendDate: _vm.setDate },
      }),
      _vm._v(" "),
      _c("room-box", { on: { interface: _vm.setRooms } }),
      _vm._v(" "),
      _c(
        "button",
        {
          directives: [
            {
              name: "log",
              rawName: "v-log",
              value: {
                data: {
                  area: "quicksearch_staying",
                  label: "abroad_search",
                  body: { search_keyword: _vm.param.code },
                },
              },
              expression:
                "{ data: { area: 'quicksearch_staying', label: 'abroad_search', body : { search_keyword : param.code }}}",
            },
          ],
          staticClass: "btn_type_01",
          attrs: { slot: "search_button", type: "button" },
          on: { click: _vm.search },
          slot: "search_button",
        },
        [_c("span", { staticClass: "in_btn" }, [_vm._v("검색")])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }