<template>
  <main id="container" class="content" role="main">
    <div class="main_article" :style="{backgroundImage: `url('${V_UPLOAD_URL+backgroundImg}')`}">
      <quick-search-comp/>
      <season-billboard-comp/>
      <promotion-banner-comp/>
      <line-banner-comp/>
      <trip-deal-comp/>
      <weekend-special-comp/>
      <best-prd-list-comp/>
      <!-- 여행사 바로가기 (일시적 비노출 처리) -->
      <!--  <company-goto-comp/> -->
      <strong-point-comp/>
    </div>
  </main>
</template>

<script>
import quickSearchComp from "../components/quickSearchComp";
import seasonBillboardComp from "../components/seasonBillboardComp";
import promotionBannerComp from "../components/promotionBannerComp"
import lineBannerComp from "../components/lineBannerComp";
import tripDealComp from "../components/tripDealComp";
import weekendSpecialComp from "../components/weekendSpecialComp";
import bestPrdListComp from "../components/bestPrdListComp";
// import companyGotoComp from "../components/companyGotoComp";
import strongPointComp from "../components/strongPointComp";
import {mapGetters} from "vuex";
import {V_UPLOAD_URL} from '@/common/const.js';
import {PC_MAIN_STORAGE_OLD_KEY} from "@/common/constants/tourConst";
import qs from "qs";
import {generatorHahkey,setLocalStorage} from '../../utils/main';
import {isEmptyValue} from '@/common/utils.js'
export default {
    components: {
        quickSearchComp,
        seasonBillboardComp,
        promotionBannerComp,
        lineBannerComp,
        tripDealComp,
        weekendSpecialComp,
        bestPrdListComp,
        // companyGotoComp,
        strongPointComp,
    },
    data(){
        return {
            V_UPLOAD_URL,

        }
    },
    computed : {
        ...mapGetters({
            backgroundImg : 'TOUR_MAIN/GET_MAIN_BACKGROUND_IMAGE',
            mainStorageData : 'TOUR_MAIN/GET_RECENT_SEARCH_STORAGE',
        })
    },
    created() {
        this.fetchBackgroundImg();
        this.isFlight()
        this.fetchInspectTimeToFlight(); // 항공 서비스점검
        this.initStorage();
    },
    methods:{
        fetchBackgroundImg(){
            this.$store.dispatch('TOUR_MAIN/FETCH_MAIN_BACKGROUND_IMAGE');
        },
        fetchInspectTimeToFlight(){
            this.$store.dispatch('TOUR_MAIN/FETCH_IS_INSPECT_TIME_TO_FLIGHT');
        },
        isFlight(){
            if(this.$route.query.hasOwnProperty('isFlight') && this.$route.query.isFlight){
                let isFlight = this.$route.query.isFlight ==='N'?false:true;
                this.$store.commit('TOUR_MAIN/SET_IS_SHOW_FLIGHT_QUICKSEARCH',isFlight);
            }
        },
        initStorage(){
            this.getOldStorage();
            this.getRecentKeyword();
        },
        getOldStorage(){
            //기존 스토리지 가져오기 : 추후에 사용안하면 삭제.
            let storage = localStorage.getItem(PC_MAIN_STORAGE_OLD_KEY);

            if(!isEmptyValue(storage)){

                let stgList = decodeURIComponent(storage).split('@$');

                if(stgList && stgList.length>0){
                    let new_list = [];

                    stgList.forEach(stg=>{
                        let item = stg.split('^'),
                            parseData = {};
                        const stgObj = qs.parse(decodeURIComponent(item[1]));

                        Object.keys(stgObj).forEach((key=>{
                            if(stgObj[key]){
                                parseData[key]=stgObj[key];
                            }
                        }))

                        if(parseData.method== "getCategory"){
                            let dispCtgrName = item[0].replace("<strong>","").replace("</strong>","");
                            let hashKey = generatorHahkey(parseData.method,parseData);
                            parseData = {...parseData, ...{dispCtgrName,hashKey}}

                        }else{
                            let startCityName =parseData.startCity.split("^")[0];
                            let tourCityName = parseData.tourCity.split("^")[0];
                            let hashKey = generatorHahkey(parseData.method,parseData);

                            parseData = {...parseData, ...{startCityName, tourCityName,hashKey}};
                        }
                        new_list.push(parseData);
                    });

                    setLocalStorage(new_list.slice(0,3));
                }

                localStorage.removeItem(PC_MAIN_STORAGE_OLD_KEY);
            }
        },
        getRecentKeyword(){
            let temp = this.mainStorageData.fetchItems();

            if(temp && temp.length > 0){
                let stgList = temp.slice(0,3);
                this.$store.commit('TOUR_MAIN/SET_RECENT_SEARCH_KEYWORD',stgList);
            }
        }
    }
}
</script>


