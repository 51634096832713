<template>
  <section class="trip_best_list_box" v-if="prdList && prdList.length > 0">
    <sub-title-comp :subTitle="tabList.title">
      <h1 slot="title">여행BEST!</h1>
    </sub-title-comp>
    <category-tab-comp :prdInfo="tabList" :id="'best'"/>

    <ul class="list_box"><!-- li 가로 크기 304px -->
      <product-item-comp v-for="( prd, index) in prdList" :key="index" :prd="prd" :index="index" :area="'tour_best'" :categoryNo="tabList.activeDispNo">
        <span slot="tag" class="num">{{ index + 1 }}</span>
        <img slot="thumb" :src="imgConvert(prd.orgImgUrl)" @error="setPcPrdBaseNoImg($event,230)" :alt="prd.prdNm">
        <a slot="company" :href="`${V_PC_SHOP_PATH}/${prd.prdNo}`">
          <span class="company" v-if="prd.nckNmImgUrl">
              <img :src="V_PC_IMG_URL+prd.nckNmImgUrl" :alt="prd.sellerHmpgNm"
                   @error="`${V_PC_IMG_URL}/img/tour/product/no_image_sellerlogo2.png`"></span>
          <span class="company" v-else v-html="prd.sellerHmpgNm"></span>
        </a>
        <div slot="border_frame_type2" class="border_frame"></div>
      </product-item-comp>
    </ul>

    <a href="javascript:" class="btn_more" @click="moveCategory(tabList.activeDispNo,false)" v-log="{ data: { area: 'tour_best', label: 'more', body :  {'content_type':'CATEGORY','content_name': tabList.activeTabName ,
'content_no':tabList.activeDispNo}}}"
>더 많은
      <strong>{{ tabList.activeTabName }}</strong> 상품 더 보기<span class="arrow"></span>
    </a>
  </section>
</template>

<script>
import log from '@/common/directives/log';
import {fetchApi} from '../mixin/service';
import * as KEY from '@/common/constants/tourConst' ;
import {mapGetters} from "vuex";
import {goCategoryList,checkCategory} from '../../utils/main';
import productItemComp from "./template/productItemComp";
import {V_PC_IMG_URL,V_PC_SHOP_PATH} from '@/common/const.js';
import {cropDimsImgUrl,imgUrlPatternRemove} from '@/common/utils/imageUtil.js';
import {setPcPrdBaseNoImg} from '@/common/utils.js';
import categoryTabComp from "./template/categoryTabComp";
import subTitleComp from "./template/subTitleComp";

export default {
    directives: {log},
    components: {
        subTitleComp,
        categoryTabComp,
        productItemComp
    },
    mixins : [fetchApi],
    computed : {
        ...mapGetters({
            prdList : 'TOUR_MAIN/GET_BEST_PRD_LIST',
            tabList : 'TOUR_MAIN/GET_BEST_TAB_CATEGORY',
        })
    },
    data(){
        return {
            V_PC_IMG_URL,
            V_PC_SHOP_PATH
        }
    },
    methods :{
        fetchData(){
            this.getSubTitle();
            this.getPrdList();
        },
        async getSubTitle(){
            const data = await this.fetchApi(KEY.PC_TOUR_MAIN_BEST_PRD_SUB_TITLE_KEY);
            if(data && data.length > 0){
                this.$store.commit('TOUR_MAIN/SET_BEST_PRD_SUB_TITLE',data[0].displayObjectName);
            }
        },
        getPrdList(){
            this.$store.dispatch('TOUR_MAIN/FETCH_BEST_PRD_LIST',KEY.PC_TOUR_MAIN_BEST_PRD_DISPLAY_LIST_KEY);
        },
        imgConvert(imgStr) {

            const imageUrl = imgUrlPatternRemove(imgStr);

            return cropDimsImgUrl({
                imgurl :imageUrl,
                height: 336,
                width: 336,
                thumbs : true,
                quality: true,
                autoRatio : true
            });
        },
        setPcPrdBaseNoImg,
        moveCategory(dispObjNo,dealPrdYn){
            goCategoryList(checkCategory(dispObjNo,false),dealPrdYn)
        }
    }
}
</script>

