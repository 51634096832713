<template>
      <div class="option_select_box_3">
        <div class="option_detail">
          <input type="text" :class="['info_input_2',{focus:isShowLayer&& (rooms&&rooms.length > 0),active:rooms&&rooms.length > 0}]" :value="rooms && rooms.length>0?`객실수${roomInfo.roomCnt} ( 성인${roomInfo.adultCnt}  ,소아${roomInfo.childCnt} )`:'객실선택'" title="객실별 인원수">
          <button type="button" class="btn_type_02" @click="layerOpen"><span class="in_btn"><span class="ico"></span>객실추가</span></button>
          <room-layer-comp :roomList="rooms" :isShowLayer="isShowLayer" @closedLayer="layerClosed" @setRoominfo="setRoominfo"/>
        </div>
      </div>
</template>

<script>
import {layerClick} from '../../../mixin/service';
import {roomTatalCnt} from '../../../../utils/main';
export default {
    mixins :[layerClick],
    components: {
      roomLayerComp : () => import(/* webpackChunkName: "view-main-modal" */'@/common/components/room/roomLayerComp')
    },
    data(){
        return {
            rooms : null,
            roomInfo : null
        }
    },

    methods : {
        s_close(e,el){
            this.isShowLayer = (!this.preventEventCall && (el.is(e.target) || el.has(e.target).length > 0));
        },
        setRoominfo(room){
            this.rooms= room;
            this.roomInfo =  roomTatalCnt(room);
            this.$emit('interface', room);
            this.layerClosed();
        }
    }
}
</script>

