<template>
  <div>
      <!-- 항공권 퀵서치 -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      flightFormURL : "https://flight.11st.co.kr/formTour"
    };
  },
  mounted() {
    this.getForm();
  },
  methods: {
    async getForm(){
      try{
          const response = await axios.get(this.flightFormURL);
          this.$nextTick(()=>{
              this.setInnerHTML(response.data)
          })
      }catch(e){
          console.log(e);
      }
    },
    setInnerHTML(html){
      this.$el.innerHTML = html;
      Array.from(this.$el.querySelectorAll("script")).forEach( oldScript => {
        if(oldScript.src.includes('jquery')) return;
        const newScript = document.createElement("script");
        Array.from(oldScript.attributes)
          .forEach( attr => newScript.setAttribute(attr.name, attr.value) );
        newScript.appendChild(document.createTextNode(oldScript.innerHTML));
        oldScript.parentNode.replaceChild(newScript, oldScript);
      });
    }
  },
};
</script>
