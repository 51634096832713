var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.banners.list && _vm.banners.list.length > 0
    ? _c(
        "section",
        { staticClass: "trip_bnr_box" },
        [
          _c("sub-title-comp", { attrs: { subTitle: _vm.banners.title } }, [
            _c("h1", { attrs: { slot: "title" }, slot: "title" }, [
              _vm._v("여행이 궁금할 때"),
            ]),
          ]),
          _vm._v(" "),
          _c("slide-banner-comp", { attrs: { banners: _vm.banners.list } }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }