<template>
  <div class="tit_area">
    <slot name="title"></slot>
    <p class="sub_text">{{ subTitle }}</p>
  </div>
</template>

<script>
export default {
  props : ['subTitle']
}
</script>
