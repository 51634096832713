import {dateUtil} from '@/common/utils.js'

export function localStorageDB(key,cnt){
    this.KEY = key;
    this.MAX_CNT = cnt;
}

localStorageDB.prototype.fetchItems = function(){
    let data = localStorage.getItem(this.KEY)
    if(data){
        return JSON.parse(data);
    }
    return [];
}

localStorageDB.prototype.deletePastDateInStorage = function(){
    let result = this.fetchItems();
    let temp = [];
    //체크인날짜가 지난 날짜, createdAt이 28일 경과인 경우 제외
    result.map(item => {
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() -1);        
        let expiredDate = new Date(item.createdAt + 28 * 24 * 60 * 60 * 1000); 
        let checkInDate = (item.checkIn) ? dateUtil.parseDateFormat(item.checkIn) : new Date();
        if( yesterday.getTime() < checkInDate.getTime() && yesterday.getTime() < expiredDate.getTime()){
            temp.push(item);
        }
    })
    localStorage.setItem(this.KEY, JSON.stringify(temp)); 
}

localStorageDB.prototype.generateKeywordData = function(data){
    return {
        ...data,
        createdAt : new Date().getTime()
    }
}

localStorageDB.prototype.getItemByHashKey = function(hashKey){
    let list = this.fetchItems();
    return list.find(item => item.hashKey === hashKey);
}

localStorageDB.prototype.saveItem = function(data){
    const item =  this.generateKeywordData(data);
    const items =  this.reorderList(this.fetchItems(),item);
    localStorage.removeItem(this.KEY);
    localStorage.setItem(this.KEY, JSON.stringify(items));
}

localStorageDB.prototype.saveItems = function(items){
    localStorage.removeItem(this.KEY);
    localStorage.setItem(this.KEY, JSON.stringify(items));
}

localStorageDB.prototype.resetItem = function(){
    localStorage.removeItem(this.KEY);
}

localStorageDB.prototype.reorderList = function(list,curData){
    if(!list || list.length == 0) return [curData];
    const findIndex = list.findIndex(item => item.hashKey === curData.hashKey);
    if (findIndex > -1) {
        list.splice(findIndex, 1);
    }
    list.unshift(curData);
    return (list.length > this.MAX_CNT) ? list.slice(0,this.MAX_CNT) : list;
}

localStorageDB.prototype.deletePastAndTodayInStorage = function(){
    let result = this.fetchItems();
    let temp = [];
    result.map(item => {
        var tomorrow = new Date(new Date().toDateString());
        tomorrow = new Date(tomorrow.setDate(tomorrow.getDate() + 1));
        let checkInDate = new Date(item.startDate);
        if(checkInDate.getTime() >= tomorrow.getTime()){
            temp.push(item)
        }
    })
    localStorage.setItem(this.KEY, JSON.stringify(temp));
}