<template>
  <div class="billboard_box">
    <h2 class="sr-only">배너모음</h2>
    <div class="billboard swiper-container" v-swiperScroll="{ options : swiperOption , stopRakeLog : true }">
      <ul class="swiper-wrapper"><!-- ul의 전체 크기는 li*갯수입니다. li의 가로값은 398px 입니다.-->
        <li class="swiper-slide" v-for="(banner, index) in banners" :key="index">
          <a :href="banner.displayObjectLinkUrl" v-log="{ data: { area: 'billboardbox', label: 'banner', body : {'content_type':'EXHIBITION','content_name': banner.displayObjectName,
'content_no':banner.displayObjectNo} }}">
            <img :src="V_UPLOAD_URL+banner.linkBannerImageUrl" :alt="banner.displayObjectName"  @error="setPcPrdBaseNoImg($event,'400')">
          </a>
        </li>
      </ul>
      <div class="billboard_controls">
        <p class="controls">
          <button type="button" class="prev swiper-button-prev">이전 배너 보기</button>
          <button type="button" class="next swiper-button-next">다음 배너 보기</button>
        </p>
        <nav class="pagination swiper-pagination" aria-level="배너 모음 페이지 목록"></nav>
      </div>
    </div>
  </div>
</template>

<script>
import log from '@/common/directives/log';
import {V_UPLOAD_URL} from '@/common/const.js';
import swiperScroll from "@/common/directives/swiper";
import {setPcPrdBaseNoImg} from '@/common/utils.js';

export default {
    directives: { swiperScroll, log },
    props : {
        banners : {
            type : Array,
            default(){
                return null
            }
        }
    },
    data(){
        return {
            V_UPLOAD_URL,
            setPcPrdBaseNoImg,
            swiperOption : {
                loop: true,
                centeredSlides: true,
                spaceBetween: 0,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                pagination: {
                    el: ".pagination",
                    bulletActiveClass: "swiper-pagination-bullet-active",
                    bulletClass: "swiper-pagination-bullet",
                    clickable: true
                },
            }
        }
    }
}
</script>
