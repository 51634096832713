<template>
  <div class="option_select_box_1">
    <div class="option_detail">
      <input class="info_input_2" id="info_input_2" name="info_input_2" title="도시 또는 숙소명 입력" placeholder="도시 또는 숙소명 입력" :value="keyword"  @input="updateInput" @focusin="focusin">
      <!-- 키워드 레이어-->
      <keyword-layer :isShow="isShowModal" :keyword="keyword" @changeDate="changeDate" :type="type" :citys="type=='DHOTEL'?dhotelCitys:AhotelCitys"/>
    </div>
  </div>
</template>

<script>
import keywordLayer from "../../layer/keywordLayer";
import {mapGetters} from "vuex";
export default {
    props : ['type','popularWord'],
    components: {
        keywordLayer
    },
    data(){
        return {
            keyword : "",
            ishandle : true,
            isShowModal : false,
        }
    },
    computed : {
        ...mapGetters({
              dhotelCitys : 'TOUR_MAIN/GET_DHOTEL_AUTO_COMPLETE_KEYWORD',
              AhotelCitys : 'TOUR_MAIN/GET_ATOTEL_AUTO_COMPLETE_KEYWORD'
        })
    },
    created(){
        window.addEventListener('click', this.close);
    },
    beforeDestroy() {
        window.removeEventListener('click', this.close);
    },
    methods : {
        updateInput(e){
            if(this.isShowModal){
                let value =  e.target.value.trim();
                this.keyword = value;
            }else{
                e.target.value = "";
                this.keyword = "";
            }
          this.$emit('updateInput', this.keyword);
        },
        focusin(){
            this.isShowModal = true;
        },
        close(e){
            let el = $(this.$el);
            let hasEl = (el.is(e.target) || el.has(e.target).length > 0);
            if (!hasEl){
                this.isShowModal = false;
                this.ishandle = true;
            }
        },
        changeDate(param){
            this.keyword = param.keyword;
            this.isShowModal = false;
            this.$emit('sendKeyword', param.data);
        }
    },
    watch : {
        popularWord(data){
            this.keyword = data;
        }
    }
}
</script>

