<template>
  <section class="weekend_special_box" v-if="prdList && prdList.length > 0">
    <div class="inner_box">
      <sub-title-comp :subTitle="prdinfo.title">
        <h1 slot="title">이번 주말에 뭐할까?</h1>
      </sub-title-comp>

      <div class="list_box" id="slideImage">
        <ul style="width:1525px;"><!-- ul의 전체 크기는 li*갯수입니다. li의 가로값은 마진 값 포함 305px 입니다.-->
          <product-item-comp v-for="(prd, index) in prdList" :key="index" :prd="prd" :index="index" :area="'weekend_tour'" :categoryNo="tabList.activeDispNo">
            <img slot="thumb" :src="imgConvert(prd.orgImgUrl)" @error="setPcPrdBaseNoImg($event,300)" :alt="prd.prdNm">
            <div slot="border_frame_type1" class="border_frame"></div>
            <a slot="company" href="javascript:" @click="moveCategory(prd,false)" data-log-actionid-area="weekend_tour" data-log-actionid-label="category"
            :data-log-body="JSON.stringify({'content_no':prd.dctgrNo?prd.dctgrNo:(prd.sctgrNo?prd.sctgrNo:prd.mctgrNo), 'content_type':'CATEGORY', 'content_name':prd.dctgrNm ? prd.dctgrNm : sctgrNm})">
              <!--                <span class="icon"><img src="/imgTemp/trip/ico_temp_01.png" alt="" /></span>-->
              {{ prd.dctgrNm ? prd.dctgrNm : sctgrNm }}<span class="arrow"></span>
            </a>
          </product-item-comp>
        </ul>

        <div class="paging">
          <button type="button" :class="['ico',{on:page == prdinfo.currentPage}]"
                  v-for="(page, index) in prdinfo.pageNavigate" :key="index" @click="pageChange(page)">상품목록 {{ page }}
          </button>
        </div>

        <p class="billboard_func" v-if="prdinfo.totalCnt > prdinfo.pageSize">
          <button type="button" class="btn_paging btn_prev" data-log-actionid-area="weekend_tour" data-log-actionid-label="prev"
                  @click="pageChange(prdinfo.currentPage-1)">이전 상품
          </button>
          <button type="button" class="btn_paging btn_next" data-log-actionid-area="weekend_tour" data-log-actionid-label="next"
                  @click="pageChange(prdinfo.currentPage+1)">다음 상품
          </button>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import log from '@/common/directives/log';
import {fetchApi} from '../mixin/service';
import * as KEY from '@/common/constants/tourConst' ;
import {mapGetters} from "vuex";
import productItemComp from "./template/productItemComp";
import {V_PC_IMG_URL} from '@/common/const.js';
import {setPcPrdBaseNoImg} from '@/common/utils.js';
import {cropDimsImgUrl,imgUrlPatternRemove} from '@/common/utils/imageUtil.js';
import {goCategoryList} from '../../utils/main';
import subTitleComp from "./template/subTitleComp";

export default {
    directives: {log},
    components: {
        subTitleComp,
        productItemComp
    },
    mixins : [fetchApi],
    computed : {
        ...mapGetters({
            prdinfo : 'TOUR_MAIN/GET_WEEKEND_SPECIAL_INFO',
            prdList : 'TOUR_MAIN/GET_WEEKEND_SPECIAL_PAGING_LIST',
            tabList : 'TOUR_MAIN/GET_BEST_TAB_CATEGORY'
        })
    },
    data(){
        return {
            V_PC_IMG_URL,
            imageSlide : null,
        }
    },

    methods :{
        fetchData(){
            this.getSubTitle();
            this.getPrdList();
        },
        async getSubTitle(){
            const data = await this.fetchApi(KEY.PC_TOUR_MAIN_WEEKEND_SPECIAL_SUB_TITLE_KEY);
            if(data && data.length > 0){
                this.$store.commit('TOUR_MAIN/SET_WEEKEND_SPECIAL_SUB_TITLE',data[0].displayObjectName);
            }
        },
        getPrdList(){
            this.$store.dispatch('TOUR_MAIN/FETCH_WEEK_DISPLAY_PRD_LIST',KEY.PC_TOUR_MAIN_WEEKEND_SPECIAL_DISPLAY_LIST_KEY);
        },
        imgConvert(imgStr) {

            const imageUrl = imgUrlPatternRemove(imgStr);

            return cropDimsImgUrl({
                imgurl :imageUrl,
                height: 470,
                width: 470,
                thumbs : true,
                quality: false,
                autoRatio : false
            });
        },
        setPcPrdBaseNoImg,
        moveCategory({ dctgrNo,mctgrNo,sctgrNo}, dealPrdYn){
            let dispCtgrNo = dctgrNo?dctgrNo:(sctgrNo?sctgrNo:mctgrNo);
            goCategoryList(dispCtgrNo,dealPrdYn);
        },
        pageChange(page){
            let movePage = page;
            if(page>this.prdinfo.pageNum) movePage = 1;
            if(page==0) movePage = this.prdinfo.pageNum;

            if(movePage > 0 && movePage <=this.prdinfo.pageNum && movePage !== this.prdinfo.currentPage){
                this.$store.commit('TOUR_MAIN/SET_WEEKEND_SPECIAL_CURRENTPAGE',movePage)
            }
        }
    }
}
</script>
