var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "season_billboard_box f-clear" },
    [
      _c("recom-trip-comp"),
      _vm._v(" "),
      _vm.billBanner && _vm.billBanner.length > 0
        ? _c("billboard-comp", { attrs: { banners: _vm.billBanner } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }