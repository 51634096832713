const setPageId = (pageInfo = {}) => {
    if (window.rakeLogPageInfo) {
      window.rakeLogPageInfo.PageInfo = {
        ...window.rakeLogPageInfo.pageInfo,
        ...pageInfo
      };
      if (rakeLog) rakeLog.refresh();
    }
  }
const getPageId = () =>{
  const rakeLogPageInfo = window.rakeLogPageInfo || {};
  const pageInfo = rakeLogPageInfo.PageInfo || {};
  return (pageInfo) ?  pageInfo : ''
} 

const getLogProps = (logData = {}) => {
  const { area, label, body } = logData;

  let log = {
    'data-log-actionid-area': area || '',
    'data-log-actionid-label': label || '',
  };

  if(body){
    log = {
      ...log,
      'data-log-body': JSON.stringify(body)
    }
  }

  return log;
};

const setLogPropsOnElement = (el, logData) => {
  $(el).attr(getLogProps(logData));
};




const handleRakeLog = (() => {
  
  //라우터 이동이 아닌 url 직접 호출시 중복 호출 제어
  let isAlreadySentRakeLog = true;

  return (pageId, next)=>{

  /**
   * router 이동후 impression 호출하도록 제어
   */
      const scrollHandler = (window.rakeLog && window.rakeLog.scrollHandler) ? window.rakeLog.scrollHandler : null;
      if(scrollHandler){
        window.rakeLog.scrollHandler = () => false;
      }
      next();
      setTimeout(()=>{
        if(!isAlreadySentRakeLog){
          setPageId(pageId);
        }
        isAlreadySentRakeLog = false;
  
        if(scrollHandler){
          scrollHandler();
          window.rakeLog.scrollHandler = scrollHandler;
        }

      },500)

  }
})()



export {
    setPageId,
    getPageId,
    setLogPropsOnElement,
    handleRakeLog
}