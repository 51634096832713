var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main_wrap", attrs: { id: "wrap" } },
    [
      _c("gnb-header"),
      _vm._v(" "),
      _c("category-navigator"),
      _vm._v(" "),
      _c("router-view"),
      _vm._v(" "),
      _c("gnb-footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }