var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.prdList && _vm.prdList.length > 0
    ? _c(
        "section",
        { staticClass: "trip_best_list_box" },
        [
          _c("sub-title-comp", { attrs: { subTitle: _vm.tabList.title } }, [
            _c("h1", { attrs: { slot: "title" }, slot: "title" }, [
              _vm._v("여행BEST!"),
            ]),
          ]),
          _vm._v(" "),
          _c("category-tab-comp", {
            attrs: { prdInfo: _vm.tabList, id: "best" },
          }),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "list_box" },
            _vm._l(_vm.prdList, function (prd, index) {
              return _c(
                "product-item-comp",
                {
                  key: index,
                  attrs: {
                    prd: prd,
                    index: index,
                    area: "tour_best",
                    categoryNo: _vm.tabList.activeDispNo,
                  },
                },
                [
                  _c(
                    "span",
                    { staticClass: "num", attrs: { slot: "tag" }, slot: "tag" },
                    [_vm._v(_vm._s(index + 1))]
                  ),
                  _vm._v(" "),
                  _c("img", {
                    attrs: {
                      slot: "thumb",
                      src: _vm.imgConvert(prd.orgImgUrl),
                      alt: prd.prdNm,
                    },
                    on: {
                      error: function ($event) {
                        return _vm.setPcPrdBaseNoImg($event, 230)
                      },
                    },
                    slot: "thumb",
                  }),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      attrs: {
                        slot: "company",
                        href: _vm.V_PC_SHOP_PATH + "/" + prd.prdNo,
                      },
                      slot: "company",
                    },
                    [
                      prd.nckNmImgUrl
                        ? _c("span", { staticClass: "company" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.V_PC_IMG_URL + prd.nckNmImgUrl,
                                alt: prd.sellerHmpgNm,
                              },
                              on: {
                                error: function ($event) {
                                  _vm.V_PC_IMG_URL +
                                    "/img/tour/product/no_image_sellerlogo2.png"
                                },
                              },
                            }),
                          ])
                        : _c("span", {
                            staticClass: "company",
                            domProps: { innerHTML: _vm._s(prd.sellerHmpgNm) },
                          }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "border_frame",
                    attrs: { slot: "border_frame_type2" },
                    slot: "border_frame_type2",
                  }),
                ]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              directives: [
                {
                  name: "log",
                  rawName: "v-log",
                  value: {
                    data: {
                      area: "tour_best",
                      label: "more",
                      body: {
                        content_type: "CATEGORY",
                        content_name: _vm.tabList.activeTabName,
                        content_no: _vm.tabList.activeDispNo,
                      },
                    },
                  },
                  expression:
                    "{ data: { area: 'tour_best', label: 'more', body :  {'content_type':'CATEGORY','content_name': tabList.activeTabName ,\n'content_no':tabList.activeDispNo}}}",
                },
              ],
              staticClass: "btn_more",
              attrs: { href: "javascript:" },
              on: {
                click: function ($event) {
                  return _vm.moveCategory(_vm.tabList.activeDispNo, false)
                },
              },
            },
            [
              _vm._v("더 많은\n      "),
              _c("strong", [_vm._v(_vm._s(_vm.tabList.activeTabName))]),
              _vm._v(" 상품 더 보기"),
              _c("span", { staticClass: "arrow" }),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }