var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.bannerInfo && _vm.bannerInfo.linkBannerImageUrl
    ? _c(
        "ul",
        {
          class: "trip_company_list_" + _vm.index,
          style: {
            backgroundImage:
              "url('" +
              (_vm.V_UPLOAD_URL + _vm.bannerInfo.linkBannerImageUrl) +
              "')",
          },
        },
        [_c("li", [_vm._v(_vm._s(_vm.bannerInfo.displayObjectName))])]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }