export const API_LIST = {
  // 달력 휴일 리스트
  GET_MAIN_HOLIDAYS: `
    query {
        getHolidays {
            holidays
        }
    }
    `,
  // 검색 주요지역선택 리스트
  GET_MAIN_DOMESTIC_AREA_INFOS: `
    query {
      getDomesticAreaInfos {
        areaName,
        areaDetailList {
          areaDetailName
          hotelCount
          sido
          sigungu
        }
      }
    }
  `,
  // 퀵서치 배너 전시구좌
  GET_MAIN_DISPLAY_SPACE_OBJECT: `
    query ($dispSpceId : String){
      getDisplaySpaceObject(dispSpceId: $dispSpceId) {
        linkBannerImageUrl
        linkBannerImageUrl2
        displayObjectName
        displayObjectLinkUrl
        displayObjectLinkUrl2
        extraText
      }
    }
  `,
  // 검색어 자동완성 정보
  GET_MAIN_DOMESTIC_ACK: `
    query ($keyword : String) {
      getDomesticACK(keyword : $keyword) {
        title,
        keywordBoList {
          showLabel
          keyword
          grpType
          sido
          sigungu
          posLat
          posLon
          addInfos
          attrGrpInfo
          ctlgGrpCd
          checkId
          checkOut
          hotelCount
        }
      }
    }
  `,
  // 인기여행지&테마상품 전시구좌
  GET_MAIN_DISPLAY_SPACE_OBJECT_AND_PRODUCTLIST: `
    query ($dispSpceId : String, $limit : Int){
      getDisplaySpaceObjectAndProductList(dispSpceId: $dispSpceId, limit : $limit) {
        displaySpaceId
        displayObjectNo
        displayObjectName
        displayObjectLinkUrl
        displayProductList {
          productNo
          productName
          sellPrice
          finalDiscountPrice
          webFinalDiscountPrice
          originalImageUrl
          productTypeCode
          minorSellCn
          deliveryCostInstBasicCode
          sellerHomepageName
          reviewCount
          shockKingDealPrd
          tourFestivalCouponTargetYn
        }
      }
    }
  `,
  // 여행딜 - limit는 상품 갯수
  GET_MAIN_DISPLAY_SPACE_PRODUCTLIST: `
    query ($dispSpceId : String, $limit : Int){
      getDisplaySpaceProductList(dispSpceId: $dispSpceId, limit : $limit) {
        productNo
        productName
        sellPrice
        finalDiscountPrice
        webFinalDiscountPrice
        originalImageUrl
        productTypeCode
        minorSellCn
        deliveryCostInstBasicCode
        sellerHomepageName
        reviewCount
        shockKingDealPrd
        additionContents
        tourFestivalCouponTargetYn
      }
    }
  `,
  GET_RECENT_SEARCH_LIST: `
    query {
        getDomesticRecentKeywordData {
            hashKey
            checkIn
            checkOut
            nightCnt
            roomInfoStr
            room
            posLat
            posLon
            sido
            sigungu
            catalogNo
            isKeywordSearch
            createdAt
    }
  }`,
  //최근검색어 저장
  UPDATE_RECENT_SEARCH_OBJ: `
   mutation ($tourRecentKeywordParameter : TourRecentKeywordParameter) {
       setDomesticRecentKeywordData(tourRecentKeywordParameter : $tourRecentKeywordParameter)
             {
                 hashKey
                 checkIn
                 checkOut
                 nightCnt
                 roomInfoStr
                 room
                 posLat
                 posLon
                 sido
                 sigungu
                 catalogNo
                 isKeywordSearch
                 createdAt
             }
     }
 `,
  //최근검색어 삭제
  DELETE_RECENT_SEARCH_OBJ: `
   mutation ($hashKey : String) {
       deleteDomesticRecentKeywordData(hashKey : $hashKey)
             {
                 hashKey
                 checkIn
                 checkOut
                 nightCnt
                 roomInfoStr
                 room
                 posLat
                 posLon
                 sido
                 sigungu
                 catalogNo
                 isKeywordSearch
                 createdAt
             }
     }
 `,
 MAIN_RECENTLY_VIEWED_LIST : `
 query ($tourSearchParameterBO : TourSearchParameterBO){
  getRecentSearchCatalogList(tourSearchParameterBO : $tourSearchParameterBO){
                catalogNo
                catalogName
                hotelAddress
                imageUrl
                selPrice
                finalDscPrice
                attributeList {
                  catalogAttributeName
                }
                serviceCodeList
                isDealYn
                acmdId
                accommodationDetailTypeName
                checkIn
                checkOut
                reviewCount
                reviewStar
          }
  }
 `,
 MAIN_RECO_CATALOGLIST : `
 query ($tourSearchParameterBO : TourSearchParameterBO){
  getRecommendationCatalogList(tourSearchParameterBO : $tourSearchParameterBO){
                catalogNo
                catalogName
                hotelAddress
                imageUrl
                selPrice
                finalDscPrice
                attributeList {
                  catalogAttributeName
                }
                serviceCodeList
                isDealYn
                acmdId
                accommodationDetailTypeName
                checkIn
                checkOut
          }
  }
 `

};
