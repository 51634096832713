var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.citys && _vm.citys.length > 0
    ? _c("div", { staticClass: "popular_trip" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "list_box" },
          _vm._l(_vm.citys, function (city, index) {
            return _c("li", { key: index }, [
              _c("span", { staticClass: "tx_bar" }),
              _c(
                "a",
                {
                  attrs: { href: "javascript:" },
                  on: {
                    click: function ($event) {
                      return _vm.setCity(city)
                    },
                  },
                },
                [_vm._v(_vm._s(city.dispObjNm))]
              ),
            ])
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "title" }, [
      _c("span", { staticClass: "ico" }),
      _vm._v("인기도시"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }