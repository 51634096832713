import {localStorageDB} from "@/common/utils/localStorage.util";
import {PC_MAIN_SEARCH_TOUR_STORAGE_KEY} from "@/common/constants/tourConst";

export const state =  {
    backgroundImgs : null,
    // companyList : {
    //     title : "",
    //     list : []
    // },
    bestPrdList:null,
    bestTabCategory:{
        title:''
    },
    weekendSpcInfo :{
        pageSize : 4,
        pageNum : 0,
        currentPage : 1,
        pageNavigate :null,
        title : "",
    },
    weekendSpcList : null,
    dealTabCategory :{
        title : "일상의 지친 당신을 위해 준비한 여행",
    },
    dealPrdList:null,
    lineBanner:null,
    promotionBanner:{
        title : "",
        list :null
    },
    recomTourList : null,
    billboardBanners:null,
    categoryList : null,
    majorList : null,
    popularCityList: null,
    dhotelKeyWords : null,
    ahotelKeyWords: null,
    isFlight : true,
    inspectTimeToFlight : false,
    recentKeyword : null,
    mainStorageData: new localStorageDB(PC_MAIN_SEARCH_TOUR_STORAGE_KEY,3),
    areaDetailList : {}                                // 국내숙박 검색 주요지역선택 리스트
}
