var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      ref: "categoryNavigator",
      class: [
        "c-trip-gnb",
        { "trip-gnb-menu__button--selected": _vm.isShowLayer },
        { "c-trip-gnb--fixed": _vm.isFixed },
      ],
      style: { top: _vm.stickyTop },
    },
    [
      _c(
        "div",
        { staticClass: "inner" },
        [
          _c("h1", { staticClass: "sr-only" }, [_vm._v("여행 11번가")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "trip-gnb-menu" },
            [
              _c(
                "p",
                {
                  staticClass: "trip-gnb-menu__button",
                  on: {
                    mouseover: function ($event) {
                      return _vm.isShowCategoryList(true)
                    },
                    mouseout: function ($event) {
                      return _vm.isShowCategoryList(false)
                    },
                  },
                },
                [
                  _c(
                    "button",
                    {
                      attrs: {
                        type: "button",
                        "aria-haspopup": "dialog",
                        "aria-controls": "arTripCategory",
                      },
                      on: { click: _vm.goTravelMain },
                    },
                    [
                      _vm._v("여행\n          "),
                      _c("em", [_vm._v("11")]),
                      _vm._v("번가\n        "),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("gnb-all-category-list", {
                attrs: {
                  list: _vm.categorys[0].list.all || [],
                  exhibitions: _vm.exhibitions,
                },
                on: {
                  isShowCategoryList: _vm.isShowCategoryList,
                  sendToggle: _vm.setToggle,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("gnb-navi-sub-list", {
            attrs: { data: _vm.categorys[0].list.main || [] },
          }),
          _vm._v(" "),
          _vm.bestRank
            ? _c("div", { staticClass: "trip-gnb-best" }, [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "log",
                        rawName: "v-log",
                        value: {
                          data: {
                            area: _vm.getLogGnbArea(""),
                            label: _vm.getLogGnbLabel(""),
                          },
                        },
                        expression:
                          "{data: {area: getLogGnbArea(''), label: getLogGnbLabel('')}}",
                      },
                    ],
                    staticClass: "link",
                    attrs: { href: _vm.bestRank.url },
                  },
                  [
                    _c("span", { staticClass: "text" }, [
                      _vm._v(_vm._s(_vm.bestRank.dispCtgrNm)),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.travelCoupon
            ? _c("div", { staticClass: "trip-gnb-coupon" }, [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "log",
                        rawName: "v-log",
                        value: {
                          data: {
                            area: _vm.getLogGnbArea(""),
                            label: _vm.getLogGnbLabel(""),
                          },
                        },
                        expression:
                          "{data: {area: getLogGnbArea(''), label: getLogGnbLabel('')}}",
                      },
                    ],
                    staticClass: "link",
                    attrs: { href: _vm.travelCoupon.url },
                  },
                  [
                    _c("span", { staticClass: "text" }, [
                      _vm._v(_vm._s(_vm.travelCoupon.dispCtgrNm)),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }