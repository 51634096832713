var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c(
      "button",
      {
        staticClass: "info_input_1",
        attrs: { type: "button" },
        on: { click: _vm.moveToCategoryMain },
      },
      [_c("span", { staticClass: "in_btn" }, [_vm._v(_vm._s(_vm.data[0]))])]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }