var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "trip_type option_1", attrs: { id: "gTabCont1" } },
    [
      _c("flight-form", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isInspectTimeToFlight,
            expression: "!isInspectTimeToFlight",
          },
        ],
      }),
      _vm._v(" "),
      _c(
        "p",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isInspectTimeToFlight,
              expression: "isInspectTimeToFlight",
            },
          ],
          staticClass: "flight_inspection_box",
        },
        [
          _vm._v(
            "더 나은 서비스 제공을 위해 항공권 가격 비교 서비스 점검을 하고 있습니다. 이용에 불편을드려서 죄송합니다."
          ),
        ]
      ),
      _vm._v(" "),
      _c("trip-company-list", {
        attrs: { bannerKey: _vm.bannerKey, index: 1 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }