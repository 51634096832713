<template>
  <section class="trip_bnr_box" v-if="banners.list && banners.list.length>0">
    <sub-title-comp :subTitle="banners.title">
      <h1 slot="title">여행이 궁금할 때</h1>
    </sub-title-comp>

    <slide-banner-comp :banners="banners.list"/>

  </section>
</template>

<script>
import * as KEY from "@/common/constants/tourConst";
import {mapGetters} from "vuex";
import {fetchApi} from '../mixin/service';
import slideBannerComp from "./template/slideBannerComp";
import subTitleComp from "./template/subTitleComp";
export default {
    components: {
        subTitleComp,
        slideBannerComp
    },
    mixins : [fetchApi],
    computed : {
        ...mapGetters({
            banners : 'TOUR_MAIN/GET_PROMOTION_BANNER_LIST',
        })
    },
    methods:{
        fetchData(){
            this.getSubTitle();
            this.getBannerList();
        },
        async getSubTitle(){
            const data = await this.fetchApi(KEY.PC_TOUR_MAIN_TOURISM_OFFICE_SUB_TITLE_KEY);
            if(data && data.length > 0){
                this.$store.commit('TOUR_MAIN/SET_PROMOTION_BANNER_SUB_TITLE',data[0].displayObjectName);
            }
        },
        async getBannerList(){
            const data = await this.fetchApi(KEY.PC_TOUR_MAIN_TOURISM_OFFICE_BANNER_KEY);
            if(data && data.length > 0){
                this.$store.commit('TOUR_MAIN/SET_PROMOTION_BANNER_LIST',data);
            }
        }
    }
}
</script>
