<template>
  <ul v-if="bannerInfo && bannerInfo.linkBannerImageUrl" :class="'trip_company_list_'+index"
      :style="{backgroundImage: `url('${V_UPLOAD_URL+bannerInfo.linkBannerImageUrl}')`}"><!-- 1: 항공권, 2: 숙박, 3: 여행상품 -->
    <li>{{ bannerInfo.displayObjectName }}</li>
  </ul>
</template>

<script>
import {fetchApi} from '../../../mixin/service';
import {V_UPLOAD_URL} from '@/common/const.js';
export default {
    mixins : [fetchApi],
    props : ['bannerKey', 'index'],
    data(){
        return {
            bannerInfo : null,
            V_UPLOAD_URL
        }
    },
    methods : {
        async fetchData(){
            const data = await this.fetchApi(this.bannerKey);
            if(data && data.length > 0){
                this.bannerInfo = data[0]
            }
        }
    }
}
</script>
