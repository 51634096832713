<template>
  <li v-if="prd">
    <div class="product_type1">
      <a href="javascript:" @click="goSellerPrdDetail(prd.prdNo)" v-log="{ data: { area: area, label: 'product', body : { content_type : 'PRODUCT', position_l2:index+1, last_discount_price: prd.finalDscPrice,
content_no:prd.prdNo,content_name:prd.prdNm,product_no:prd.prdNo,product_price:prd.finalDscPrc, category_no : categoryNo}}}">
        <div class="thumb">
          <slot name="tag"></slot>
          <slot name="thumb"></slot>
          <span class="line_frame" style="display:none;"></span>
        </div>
        <div class="summary_in"><!-- 안쪽 여백이 필요할 경우 해당 div 추가 -->
          <div :class="['subject',{subject_row2 : prd.prdNm.length>19}]">{{ prd.prdNm }}</div>
          <slot name="promotionText"></slot>
          <div :class="['info',{have_price_normal:prd.selPrc > prd.finalDscPrc}]">
            <!-- <span class="sale sale_tx"><span class="t">특별가</span></span>-->
            <span class="sale" v-if="discountRate(prd.selPrc,prd.finalDscPrc) > 0"><span
              class="n">{{ discountRate(prd.selPrc, prd.finalDscPrc) }}</span>%</span>
            <span class="price">
              <span class="price_normal" v-if="prd.selPrc > prd.finalDscPrc"><span class="skip">정상가</span><span
                class="n">{{ prd.selPrc | comma }}</span>원</span>
              <span class="skip">판매가</span>
              <span class="n">{{ prd.finalDscPrc | comma }}</span>원<span
              v-show="prd.prdTypCdDe==TOUR_CONST.PC_PRD_TYPE_TOUR || prd.prdTypCdDe==TOUR_CONST.PC_PRD_TYPE_HOTEL || prd.optYn=='Y'">~</span>
            </span>
            <slot name="buyQty"></slot>
          </div>
        </div>
        <slot name="border_frame_type1"></slot>
      </a>
      <div class="add">
        <slot name="star"></slot>
        <slot name="company"></slot>
      </div>
      <slot name="border_frame_type2"></slot>
    </div>
  </li>
</template>

<script>
import log from '@/common/directives/log';
import {comma,discountRate} from '@/common/utils.js';
import {goSellerPrdDetail} from '../../../utils/main';
import * as CONST from '@/common/constants/tourConst' ;
export default {
    directives: {log},
    props : {
        prd : {
            type : Object,
            default(){
                return {}
            }
        },
        index : {
          type : Number,
          default : () => 0
        },
        area : {
          type : String,
          default : () => ""
        },
        categoryNo : {
          type : Number,
          default : () => 0
        }
    },
    methods :{
        discountRate,
        goSellerPrdDetail,
    },
    filters : {
        comma
    },
    data(){
        return {
            TOUR_CONST : CONST
        }
    }
}
</script>

