<template>
  <div id="wrap" class="main_wrap">
    <gnb-header></gnb-header>
    <category-navigator></category-navigator>
    <router-view></router-view>
    <gnb-footer></gnb-footer>
  </div>
</template>

<script>
import gnbHeader from "../common/components/gnbHeader";
import categoryNavigator from "../common/components/categoryNavigatorComp";
import gnbFooter from "../common/components/gnbFooter";

export default {
  components: {
    gnbHeader,
    categoryNavigator,
    gnbFooter
  },
};
</script>
