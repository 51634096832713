import 'es6-promise/auto'
import Vue from 'vue'
import * as Util from '@/common/utils.js'
import {V_PC_OM_ROOT_URL_HTTPS_} from '@/common/const.js';

//vue Router
import VueRouter from 'vue-router'
import { routes } from './routes.js'
import { store } from './store/store'
import plugins from "@/common/plugins"
import App from '../AppPC'
import { VLazyImagePlugin } from "v-lazy-image"

Vue.use(VueRouter);
Vue.use(plugins);
Vue.use(VLazyImagePlugin);


const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {

    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }

  },
  // get rid of #
  mode: 'history'
});

router.beforeEach((to, from, next) => {
  let ieVersion = Util.getIeVersion();
  if (ieVersion != -1 && ieVersion < 10) {
    alert("해당 페이지는 Internet Explorer 10 이상이 필요합니다.");
    window.location.href = `${V_PC_OM_ROOT_URL_HTTPS_}/browsing/MallPlanDetail.tmall?method=getMallPlanDetail&isPlan=Y&planDisplayNumber=2012749`
  }
  next();
})

new Vue({
  el: '#wrap',
  store,
  router,
  render: h => h(App)
})
