
const MAIN_FRAGMENT = {
  DISPLAY_OBJECT_BO : `
      fragment displayObjectBo on DpDispObjBO{
          dispObjNm
          extraText
          dispObjNo
          dispObjLnkUrl
          lnkBnnrImgUrl
          slctIconCd
          dlvIcon
          tmpltType
          goodCnt
          tourProducts @skip(if: $isDispPrd){
              prdNo
              dealPrdYn
              prdNm
              finalDscPrc
              selPrc
              buySatisfy
              sellerNm
              prdEvlTotQty
              advrtStmt
              baseImgUrl
              lnkBnnrImgUrl
              selQty
              cardIconYN
              tourFestivalCouponTargetYn
              moviePlayContentBO {
                  prdNo
                  prdNm
                  selEndDy
                  imgUrl
                  finalDscPrc
                  selBgnDy
                  selQty
                  minorSelCnYn
                  selPrc
                  advrtStmt
                  discountRate
                  soldOutYn
                  movie {
                      movieNo
                      movieDomain
                      movieUrl
                      movieImgUrl
                      moviePlayCnt
                      movieRunningTime
                      transcodingInfo
                      audioYn
                  }
              }
          }
          dpLivePrdBOList @include(if: $isDispPrd){
              prdNo
              prdNm
              selPrc
              finalDscPrc
              orgImgUrl
              lnkBnnrImgUrl
              prdTypCdDe
              optYn
              nckNmImgUrl
              sellerHmpgNm
              dispObjNo
              dealPrdYn
              advrtStmt
              selQty
              buySatisfy
          }
          dpLivePrdBO @include(if: $isDispPrd){
              prdNo
              prdNm
              selPrc
              finalDscPrc
              orgImgUrl
              prdTypCdDe
              optYn
              nckNmImgUrl
              sellerHmpgNm
              dispObjNo
              dctgrNo
              mctgrNo
              sctgrNo
              dctgrNm
              sctgrNm
          }
      }`,
};


export const MAIN_API = {
  // 핫딜 테마, 상품목록 조회
  GET_DISPLAY_SPACE_OBJECT_AND_PRODUCTLIST : `
      ${MAIN_FRAGMENT.DISPLAY_OBJECT_BO}
      query ($displaySpaceId: String!, $limit: Long!, $relObjLimit: Long, $cacheTTL:Long, $isDispPrd:Boolean!){
          getHotDealThemaAndProducts(displaySpaceId:$displaySpaceId limit:$limit relObjLimit:$relObjLimit cacheTTL:$cacheTTL){
              ...displayObjectBo
          }
      }
  `,

  // 땡처리 항공권 노출여부 조회
  GET_IS_LOWFLIGHT:`
      query {
          getIsLowFlight
      }
  `,

  GET_AIRLINE_TICKET_SALE : `
      query {
          getFlightLowPrice{
              pcDomain
              moDomain
              landingPcUrl
              landingMoUrl
              inventoryList{
                  areaCd
                  areaKorNm
                  airCd
                  airKorNm
                  agtCd
                  agtKorNm
                  depCityCd
                  depCityKorNm
                  depDt
                  depTm
                  depDay
                  arrCityCd
                  arrCityKorNm
                  arrDt
                  arrTm
                  arrDay
                  adTotAmt
                  tripDur
                  airImgUrl
                  landingParam
              }
          }
      }
  `,

  //PC 베스트상품 카테고리+상품목록
  GET_BEST_DISPLAY_PRD_LIST :`
      ${MAIN_FRAGMENT.DISPLAY_OBJECT_BO}
      query ($displaySpaceId:String!,$isDispPrd:Boolean!){
          getBestDispPrdList(displaySpaceId:$displaySpaceId){
              ...displayObjectBo
          }
      }
  `,

  //PC 이번주주말에머할까 상품목록
  GET_WEEK_DISPLAY_PRD_LIST : `
      ${MAIN_FRAGMENT.DISPLAY_OBJECT_BO}
      query ($displaySpaceId:String!,$isDispPrd:Boolean!){
          getWeekDispPrdList(displaySpaceId:$displaySpaceId){
              ...displayObjectBo
          }
      }
  `,

  //PC 여행딜 카테고리+상품목록
  GET_TRIP_DEAL_PRD_LIST : `
      ${MAIN_FRAGMENT.DISPLAY_OBJECT_BO}
      query ($displaySpaceId:String!,$isDispPrd:Boolean!){
          getTripDealPrdList(displaySpaceId:$displaySpaceId){
              ...displayObjectBo
          }
      }
  `,

  //PC 여행 추천상품목록 조회
  GET_RECOMMEND_TOUR_LIST : `
      ${MAIN_FRAGMENT.DISPLAY_OBJECT_BO}
       query($isDispPrd:Boolean!){
          getRecommendTourList{
              ...displayObjectBo
          }
       }
  `,

  //PC 여행 퀵서치 여행상품 카테고리목록 조회
  GET_TOUR_CATEGORY_LIST :`
      query($categoryId:String){
          getTourCategoryList(categoryId:$categoryId){
              dispCtgrNo
              dispCtgrNm
          }
      }
  `,

  //PC 여행 퀵서치 여행상품 도착도시목록 조회
  GET_TOUR_MAJOR_CITY_LIST :`
    query {
        getTourMajorCityList{
            country
            cityList{
                cityAttrCd
                cityName
            }
        }
    }
  `,

  //PC 여행 퀵서치 인기도시 목록 조회
  GET_POPULAR_CITY_LIST :`
      ${MAIN_FRAGMENT.DISPLAY_OBJECT_BO}
      query($categoryId:String, $isDispPrd:Boolean!){
          getPopularCityList(categoryId:$categoryId){
              ...displayObjectBo
          }
      }
  `,

  // 국내숙박 검색어 자동완성
  GET_AUTO_COMPLETE_KEYWORD: `
      query ($keyword : String) {
          getDomesticACK(keyword : $keyword) {
              title,
              keywordBoList {
                  showLabel
                  sido
                  sigungu
                  posLat
                  posLon
                  addInfos
                  ctlgGrpCd
              }
          }
      }
  `,
  //해외호텔 검색어 자동완성
  GET_AHOTEL_AUTO_COMPLETE_KEYWORD :`
    query ($keyword : String) {
          getAhotelAutoKeyWordList(keyword : $keyword) {
              code
              name
              type
          }
    }`,

  GET_IS_INSPECT_TIME_TO_FLIGHT:`
      query {
          getIsInspectTimeToFlight
      }
  `,
};

