import frontTourmain from './view/frontTourMain';
import { handleRakeLog } from "@/common/rakeLog";

export const routes = [
    {
        path : '/tour/TourfrontAction.tmall',
        name : 'main',
        component: frontTourmain,
        beforeEnter : (to, from, next) =>
            handleRakeLog({
                page_id:'/tour11/main',
                page_version: "201709191009"
            },next)
    },
]
