<template>
  <div class="trip_type option_3">
    <fieldset>
      <legend>여행상품 검색</legend>
      <ul class="option_type_box">
        <li v-for="[key, tab] of tabs" :key="key" @click="selectedTab(key)">
          <input :id="'option_type_3_'+tab.index" name="type_select_2" class="radio_design" type="radio"
                  :checked="tab.isActive"/>
          <label :for="'option_type_3_'+tab.index" :class="['css-label',{on:tab.isActive}]">{{ tab.name }}</label>
        </li>
      </ul>
      <!-- 제주/국내여행 -->
      <div class="option_check_box_1" v-if="selectedKey !== 'OT'">
        <div class="option_select_box_1">
          <div class="option_detail">
            <div :class="['comp_select_type_1',{focus:isOpenBox,active:isSelectCtg}]">
              <button type="button" class="btn_select" @click="isOpenBox =! isOpenBox">
                <span class="in_btn" v-if="selectGtg">{{ selectGtg.dispCtgrNm }}</span>
                <span class="in_btn"
                      v-else>{{ `${selectedKey == 'JT' || selectedKey == 'DT' ? '여행상품' : '지역'} 선택` }}</span>
                <span class="arrow"></span>
              </button>
              <ul class="select_list" v-if="categorys && categorys.length > 0">
                <li v-for="(ctg, index) in categorys" :key="index">
                  <button type="button" @click="selectedCtg(ctg)"><span class="in_btn">{{ ctg.dispCtgrNm }}</span>
                  </button>
                </li>
              </ul>
              <ul class="select_list" v-else>
                <li>
                  <button type="button"><span class="in_btn">선택해주세요</span></button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <button type="button" class="btn_type_01" @click="goSearch"  v-log="{ data: { area: 'quicksearch_tour', label: tabsMap.get(selectedKey).label }}">검색</button>
      </div>

      <foreign-travel v-else />
      <search-history/>
    </fieldset>
    <trip-company-list :bannerKey="bannerKey" :index="3"/>
  </div>
</template>

<script>
import foreignTravel from "./travel/foreignTravel";
import searchHistory from "./common/searchHistory";
import tripCompanyList from "./common/tripCompanyList";
import * as KEY from "@/common/constants/tourConst";
import {QUICKSEARCH_TRIP_SEARCH_TYPE, goCategoryList, generatorHahkey, setLocalStorage} from '../../../utils/main'
import {mapGetters} from "vuex";
import {localStorage} from "@/main/desktop/mixin/service";
import log from '@/common/directives/log';

export default {
    directives: {log},
    mixins : [localStorage],
    components: {
        foreignTravel,
        searchHistory,
        tripCompanyList
    },
    computed : {
        ...mapGetters({
            categorys : 'TOUR_MAIN/GET_TOUR_CATEGORY_LIST',
            mainStorageData : 'TOUR_MAIN/GET_RECENT_SEARCH_STORAGE',
        })
    },
    data() {
        return {
            bannerKey: KEY.PC_TOUR_MAIN_TRAVEL_BRAND_LOGO_KEY,
            tabs: Array.from(QUICKSEARCH_TRIP_SEARCH_TYPE),
            tabsMap: QUICKSEARCH_TRIP_SEARCH_TYPE,
            selectedKey: 'JT',
            isOpenBox : false,
            isSelectCtg : false,
            selectGtg :null,
            localStorage : {
                method: "getCategory",
                radius: "5000"
            }
        }
    },
    created() {
        this.fetchApi(this.tabsMap.get('JT').id);
        this.selectedTab('JT')
    },
    methods: {
        goSearch(){

            if(!this.selectGtg || !this.selectGtg.dispCtgrNo){
                let text = (this.selectedKey =='JT' || this.selectedKey =='DT')?"여행상품":"지역";
                alert(`${text} 선택해 주세요.`);
                return;
            }

            let param ={
                dispCtgrName : this.selectGtg.dispCtgrNm,
                dispCtgrNo : this.selectGtg.dispCtgrNo,
            }
            this.setStorageData(param);
            goCategoryList(this.selectGtg.dispCtgrNo, false);

        },
        selectedTab(selectedKey) {
            this.tabsMap.forEach((value, key) => value.isActive = key === selectedKey);
            this.selectedKey = selectedKey;
            this.initSelectBox();
            this.fetchApi(this.tabsMap.get(selectedKey).id);
        },
        fetchApi(key) {
            this.$store.dispatch('TOUR_MAIN/FETCH_TOUR_CATEGORY_LIST', key);
            this.$store.dispatch('TOUR_MAIN/FETCH_TOUR_MAJOR_CITY_LIST');

        },
        initSelectBox(){
            this.isOpenBox = false;
            this.isSelectCtg = false;
            this.selectGtg =null;
        },
        selectedCtg(data){
            this.isOpenBox = false;
            this.isSelectCtg = true;
            this.selectGtg = data;
        }
    }
}
</script>

