var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "trip_strong_point_box" }, [
    _c("ul", { staticClass: "list_box" }, [
      _c("li", [
        _c("span", { staticClass: "ico_visual_01" }),
        _vm._v(" "),
        _c("dl", [
          _c("dt", [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "log",
                    rawName: "v-log",
                    value: {
                      data: {
                        area: "about_tour",
                        label: "btn",
                        body: {
                          content_type: "CATAGORY",
                          content_no: _vm.TOUR_CONST.PC_FLIGHT_CATEGORY_NO,
                          content_name: "항공권 가격비교",
                        },
                      },
                    },
                    expression:
                      "{ data: { area: 'about_tour', label: 'btn', body : { content_type : 'CATAGORY', content_no: TOUR_CONST.PC_FLIGHT_CATEGORY_NO,content_name:'항공권 가격비교'}}}",
                  },
                ],
                attrs: { href: _vm.V_PC_FLIGHT_ROOT_URL_HTTPS_ },
              },
              [_vm._v("항공권 가격비교"), _c("span", { staticClass: "arrow" })]
            ),
          ]),
          _vm._v(" "),
          _vm._m(0),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("span", { staticClass: "ico_visual_02" }),
        _vm._v(" "),
        _c("dl", [
          _c("dt", [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "log",
                    rawName: "v-log",
                    value: {
                      data: {
                        area: "about_tour",
                        label: "btn",
                        body: {
                          content_type: "CATAGORY",
                          content_no: _vm.TOUR_CONST.PC_AHOTEL_CATEGORY_NO,
                          content_name: "해외호텔",
                        },
                      },
                    },
                    expression:
                      "{ data: { area: 'about_tour', label: 'btn', body : { content_type : 'CATAGORY', content_no: TOUR_CONST.PC_AHOTEL_CATEGORY_NO,content_name:'해외호텔'}}}",
                  },
                ],
                attrs: {
                  href:
                    _vm.V_PC_TOUR_ROOT_URL_ +
                    "/tour/TourfrontCategoryAction.tmall?method=getCategory&dispCtgrNo=" +
                    _vm.TOUR_CONST.PC_AHOTEL_CATEGORY_NO,
                },
              },
              [_vm._v("해외호텔"), _c("span", { staticClass: "arrow" })]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "log",
                    rawName: "v-log",
                    value: {
                      data: {
                        area: "about_tour",
                        label: "btn",
                        body: {
                          content_type: "CATAGORY",
                          content_no: _vm.TOUR_CONST.DHOTEL_CATEGORY_NO,
                          content_name: "국내숙박",
                        },
                      },
                    },
                    expression:
                      "{ data: { area: 'about_tour', label: 'btn', body : { content_type : 'CATAGORY', content_no: TOUR_CONST.DHOTEL_CATEGORY_NO,content_name:'국내숙박'}}}",
                  },
                ],
                attrs: {
                  href:
                    _vm.V_PC_TOUR_ROOT_URL_ +
                    "/tour/TourfrontDomesticAction.tmall?method=main&isWingBnnr=N",
                },
              },
              [_vm._v("국내숙박"), _c("span", { staticClass: "arrow" })]
            ),
          ]),
          _vm._v(" "),
          _vm._m(1),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("span", { staticClass: "ico_visual_03" }),
        _vm._v(" "),
        _c("dl", [
          _c("dt", [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "log",
                    rawName: "v-log",
                    value: {
                      data: {
                        area: "about_tour",
                        label: "btn",
                        body: {
                          content_type: "CATAGORY",
                          content_no:
                            _vm.TOUR_CONST.PC_FOREIGN_PASS_CATEGORY_NO,
                          content_name: "패스/현지투어",
                        },
                      },
                    },
                    expression:
                      "{ data: { area: 'about_tour', label: 'btn', body : { content_type : 'CATAGORY', content_no: TOUR_CONST.PC_FOREIGN_PASS_CATEGORY_NO,content_name:'패스/현지투어'}}}",
                  },
                ],
                attrs: {
                  href:
                    _vm.V_PC_TOUR_ROOT_URL_ +
                    "/tour/TourfrontCategoryAction.tmall?method=getCategory&dispCtgrNo=" +
                    _vm.TOUR_CONST.PC_FOREIGN_PASS_CATEGORY_NO,
                },
              },
              [_vm._v("패스/현지투어"), _c("span", { staticClass: "arrow" })]
            ),
          ]),
          _vm._v(" "),
          _vm._m(2),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("span", { staticClass: "ico_visual_04" }),
        _vm._v(" "),
        _c("dl", [
          _c("dt", [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "log",
                    rawName: "v-log",
                    value: {
                      data: {
                        area: "about_tour",
                        label: "btn",
                        body: {
                          content_type: "CATAGORY",
                          content_no:
                            _vm.TOUR_CONST.PC_FOREIGN_TOUR_CATEGORY_NO,
                          content_name: "해외여행",
                        },
                      },
                    },
                    expression:
                      "{ data: { area: 'about_tour', label: 'btn', body : { content_type : 'CATAGORY', content_no: TOUR_CONST.PC_FOREIGN_TOUR_CATEGORY_NO,content_name:'해외여행'}}}",
                  },
                ],
                attrs: {
                  href:
                    _vm.V_PC_TOUR_ROOT_URL_ +
                    "/tour/TourfrontCategoryAction.tmall?method=getCategory&dispCtgrNo=" +
                    _vm.TOUR_CONST.PC_FOREIGN_TOUR_CATEGORY_NO,
                },
              },
              [_vm._v("해외여행"), _c("span", { staticClass: "arrow" })]
            ),
          ]),
          _vm._v(" "),
          _vm._m(3),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dd", [
      _vm._v("여기저기 헤매지 말고, 11번가에서"),
      _c("br"),
      _vm._v(" "),
      _c("strong", [_vm._v("전세계 노선의 스케쥴과 가격")]),
      _vm._v("을 비교해 보세요."),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dd", [
      _vm._v("국내는 물론, 해외까지 "),
      _c("strong", [
        _vm._v("내가 가고싶은"),
        _c("br"),
        _vm._v(" 지역의 숙소를 실시간으로 예약"),
      ]),
      _vm._v("할 수 있어요!"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dd", [
      _vm._v("여행지를 결정했다면, "),
      _c("strong", [_vm._v("패스/입장권을"), _c("br"), _vm._v(" 미리구매")]),
      _vm._v("하고 알뜰한 여행하세요!"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dd", [
      _vm._v("11번가에는 "),
      _c("strong", [_vm._v("국내유명 여행사들이 다 모였다!")]),
      _c("br"),
      _vm._v("하나하나 검색하지말고 "),
      _c("strong", [_vm._v("한번에 비교검색")]),
      _vm._v("하세요."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }