var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tit_area" },
    [
      _vm._t("title"),
      _vm._v(" "),
      _c("p", { staticClass: "sub_text" }, [_vm._v(_vm._s(_vm.subTitle))]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }