<template>
  <section class="season_billboard_box f-clear">
    <!-- 시즌별 추천 여행지 -->
    <recom-trip-comp/>
    <!-- 빌보드 -->
    <billboard-comp v-if="billBanner && billBanner.length>0" :banners="billBanner"/>
  </section>
</template>

<script>
import recomTripComp from "./template/recomTripComp";
import billboardComp from "./template/billboardComp";
import * as KEY from "@/common/constants/tourConst";
import {fetchApi} from '../mixin/service';
import {mapGetters} from "vuex";
export default {
    components: {
        recomTripComp,
        billboardComp
    },
    mixins : [fetchApi],
    computed : {
        ...mapGetters({
           billBanner : 'TOUR_MAIN/GET_BILLBOARD_BANNER_LIST',
        })
    },
    methods : {
        fetchData(){
            this.fetchRecomTripApi();
            this.fetchBillboardApi();
        },
        fetchRecomTripApi(){
            this.$store.dispatch('TOUR_MAIN/FETCH_RECOMMEND_TOUR_LIST');
        },
        async fetchBillboardApi(){
            const data = await this.fetchApi(KEY.PC_TOUR_MAIN_BILL_BOARD_BANNER_KEY);
            if(data && data.length > 0){
                this.$store.commit('TOUR_MAIN/SET_BILLBOARD_BANNER_LIST',data)
            }
        }
    }
}
</script>
