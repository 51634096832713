<template>
    <div id="gnb"></div>
</template>

<script>
export default {
    mounted(){
        // const user_info = { 'name' : '이윤지', 'level': 'VIP'}
        // SIMPLE : 예약페이지, DEFAULT: 여행페이지
        const gnbUserType =  {
            "type" : this.getRenderType(this.$route.name)
        }

        pcComm.renderGnb('gnb', gnbUserType)
    },
    methods : {
        getRenderType : (name) => (name === "reservation" || name === "error") ?  "SIMPLE" : "DEFAULT"
    },
    watch :{
        $route(to, from) {
            let to_type = this.getRenderType(to.name),
                form_type = this.getRenderType(from.name);

            if(to_type !== form_type){
                pcComm.event.rerender({type : to_type})
            }
        }
    }
}
</script>
