<template>
  <div class="list_box">
    <ul id="tripBannerList"><!-- [D] 610px *개수 -->
      <li v-for="(banner, index) in banners" :key="index" >
        <a :href="banner.displayObjectLinkUrl" class="prd_area">
          <img :src="V_UPLOAD_URL+banner.linkBannerImageUrl" :alt="banner.displayObjectName"
               class="prd_bnr">
          <span class="bg_gradation"></span>
          <div class="prd_info">
            <img :src="V_UPLOAD_URL+banner.linkBannerImageUrl2" :alt="banner.displayObjectName"
                 class="bnr_s">
            <strong class="bnr_title">{{ banner.displayObjectName }}</strong>
            <span class="bnr_txt" v-html="banner.extraText"></span>
          </div>
        </a>
      </li>
    </ul>
<!--    <p class="tripbnr_func" v-if="banners.length > 2">-->
<!--      <button type="button" class="btn_paging btn_prev" @click="onSlidePrev">이전</button>-->
<!--      <button type="button" class="btn_paging btn_next" @click="onSlideNext">다음</button>-->
<!--    </p>-->
  </div>
</template>

<script>
import {V_UPLOAD_URL} from '@/common/const.js';

export default {
    props : {
        banners : {
            type : Array,
            default(){
                return null
            }
        }
    },
    data(){
        return {
            V_UPLOAD_URL,
        }
    }
}
</script>
