<template>
  <div class="hotel_recomd_box" v-show="isShow">
    <div v-if="citys && citys.length > 0">
      <div class="list_box" v-if="city.keywordBoList && city.keywordBoList.length > 0" v-for="(city, index1) in citys" :key="index1">
        <h2 class="tit">{{city.title}}</h2>
        <ul>
          <li v-for="(list, index2) in city.keywordBoList" :key="index2">
            <a href="javascript:" v-html="wordHighlight(list)" @click="setKeyword(list)"></a></li>
        </ul>
      </div>
    </div>

    <div v-else>
      <div class="local_box" v-if="type === 'DHOTEL'">
        <h2 class="tit">주요 지역 선택</h2>
        <div class="local_name" v-if="areaDetailList && areaDetailList.length > 0" >
          <ul>
            <!--시도 리스트-->
            <li :class="country.stat2" v-for="(country, index) in countryAreaList" :key="index"><!-- [D] name 선택시 li에 selected클래스 추가 -->
              <a href="javascript:void(0);" @click="countryClick(index)" class="name">{{ country.areaName }}</a>
            </li>
          </ul>
        </div>
        <div class="list_box">
          <ul>
            <!--시군구 리스트 -->
            <li v-for="(city, index) in cityList" :key="index">
              <a href="javascript:void(0);" @click="setKeyword(city)">
                <span class="txt">{{city.areaDetailName}}</span><span class="num">{{moneyComma(city.hotelCount)}}개의 숙소</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div v-else>
        <p class="no_result"></p>
      </div>
    </div>
  </div>
</template>

<script>
import {stringUtil} from '@/common/utils.js';
import {mapGetters} from "vuex";
export default {
    props: ['isShow','keyword','type','citys'],
    data() {
      return {
        countryAreaList   : {},         // 지역 영역
        cityList          : {},         // 선택된 구역의 도시리스트
        countrySelectNo   : 0,          // 선택된 지역 영역(항목)
      }
    },
    created() {
      this.fetchDomesticAreaInfos()
    },
    computed : {
        ...mapGetters({
          areaDetailList : 'TOUR_MAIN/GET_MAIN_DOMESTIC_AREA_INFOS',
        })
      },
    methods : {
        fetchDomesticAreaInfos(){
          this.$store.dispatch('TOUR_MAIN/FETCH_MAIN_DOMESTIC_AREA_INFOS');
        },
        //초기 지역 세팅
        defaultSetting() {
          if(this.countryAreaList && this.countryAreaList.length > 0){
            this.countryAreaList.forEach(obj => obj.stat2 = {'selected': false});
            this.countryAreaList[0].stat2 = {'selected': true};
            this.countrySelectNo        = 0;
            this.cityList               = this.countryAreaList[0].areaDetailList;
          }
        },
        //지역 영역 클릭시의 지역처리
        countryClick(index) {
          if (index != this.countrySelectNo) {
            this.countryAreaList[this.countrySelectNo].stat2 = {'selected' : false};
            this.countryAreaList[index].stat2 = {'selected' : true};
            this.countrySelectNo = index;
            this.cityList = this.countryAreaList[index].areaDetailList;
          }
        },
        //천단위 콤마 처리
        moneyComma : value => stringUtil.moneyComma(value),
        close(){
            this.$emit('closedLayer');
        },
        wordHighlight(data){
            let patt = new RegExp('' + this.keyword, 'g');
            let result_word = "";

            if(this.type=='DHOTEL'){
                let text = data.showLabel.replace(patt, "<strong>" + this.keyword + "</strong>");
                result_word = `${text}, ${data.addInfos}`;

            }else{
                if(data.name.includes(this.keyword)){
                    result_word= data.name.split(this.keyword).join("<strong>"+this.keyword+"</strong>");
                }else{
                    result_word= data.name;
                }
            }
            return result_word;
        },
        setKeyword(data){
            let keyword = "";
            if(this.type=='DHOTEL'){
                keyword = data.showLabel || data.areaDetailName;
                keyword = keyword === '전체' ? data.sido : keyword
            }else{
                keyword = data.name;
            }
            this.$emit('changeDate', {data,keyword});
        },
        fetchApi(data){
            this.$store.dispatch(`TOUR_MAIN/FETCH_${this.type}_AUTO_COMPLETE_KEYWORD`,data);
        },
    },
    watch : {
        areaDetailList: {
          handler(areaDetailList) {
            this.countryAreaList = areaDetailList;     // 지역 영역 세팅
            this.defaultSetting();             // 초기 설정
          },
          immediate: true
        },
        keyword(data){
            if(this.isShow){
                this.fetchApi(data)
            }
        }
    }
}
</script>
