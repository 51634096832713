<template>
  <section class="trip_deal_box" v-if="prdList && prdList.length > 0">
    <sub-title-comp :subTitle="tabList.title">
      <h1 slot="title">여행딜</h1>
    </sub-title-comp>
    <category-tab-comp :prdInfo="tabList" :id="'deal'"/>

    <ul class="list_box"><!-- ul의 전체 크기는 li*갯수입니다. li의 가로값은 304px 입니다.-->
      <product-item-comp v-for="( prd, index) in prdList" :key="index" :prd="prd" :index="index" :area="'tour_deal'" :categoryNo="tabList.activeDispNo">
        <span slot="tag" class="icon_shockingdeal" v-if="prd.dealPrdYn =='Y'">쇼킹딜</span>
        <div slot="promotionText" class="txt_op">{{ prd.advrtStmt }}</div>
        <img slot="thumb" :src="imgConvert(prd.lnkBnnrImgUrl)" @error="setPcPrdBaseNoImg($event,'385x189')"
             :alt="prd.prdNm">
        <span slot="buyQty" class="buy_history" v-if="prd.selQty > 0">{{ prd.selQty |comma }}개 구매</span>
        <div slot="star" v-if="cutPercent(prd.buySatisfy)>0">
          <span :class="'selr_star selr_star'+cutPercent(prd.buySatisfy)">판매자 평점 별5개 중 5개</span>
          <strong class="num">{{ reviewScore(prd.buySatisfy) }}</strong>
        </div>
        <span slot="company" class="company" v-if="prd.nckNmImgUrl"><img :src="V_PC_IMG_URL+prd.nckNmImgUrl"
                                                                         :alt="prd.sellerHmpgNm"
                                                                         @error="`${V_PC_IMG_URL}/img/tour/product/no_image_sellerlogo2.png`"></span>
        <span slot="company" class="company" v-else v-html="prd.sellerHmpgNm"></span>
      </product-item-comp>
    </ul>
    <!--    <a href="#" class="btn_more">봄이면 벚꽃 보러 떠나기 좋은 곳! <strong>일본</strong> 딜상품 더 보기<span class="arrow"></span></a>-->
  </section>
</template>

<script>
import * as KEY from '@/common/constants/tourConst' ;
import categoryTabComp from "./template/categoryTabComp";
import {fetchApi} from '../mixin/service';
import {mapGetters} from "vuex";
import subTitleComp from "./template/subTitleComp";
import productItemComp from "./template/productItemComp";
import {cropDimsImgUrl,imgUrlPatternRemove} from '@/common/utils/imageUtil.js';
import {setPcPrdBaseNoImg,comma} from '@/common/utils.js';
import {V_PC_IMG_URL} from '@/common/const.js';
import log from '@/common/directives/log';

export default {
    directives: {log},
    components: {
        productItemComp,
        subTitleComp,
        categoryTabComp
    },
    mixins : [fetchApi],
    computed : {
        ...mapGetters({
            prdList : 'TOUR_MAIN/GET_DEAL_PRD_LIST',
            tabList : 'TOUR_MAIN/GET_DEAL_TAB_CATEGORY'
        })
    },
    data(){
        return {
            V_PC_IMG_URL
        }
    },
    methods : {
        fetchData(){
            this.getSubTitle();
            this.getPrdList();
        },
        async getSubTitle(){
            const data = await this.fetchApi(KEY.PC_TOUR_MAIN_TRIP_DEAL_SUB_TITLE_KEY);
            if(data && data.length > 0){
                this.$store.commit('TOUR_MAIN/SET_TRIP_DEAL_SUB_TITLE',data[0].displayObjectName);
            }
        },
        getPrdList(){
            this.$store.dispatch('TOUR_MAIN/FETCH_TRIP_DEAL_PRD_LIST',KEY.PC_TOUR_MAIN_TRIP_DEAL_DISPLAY_LIST_KEY);
        },
        imgConvert(imgStr) {
          const imageUrl = imgUrlPatternRemove(imgStr);
          return cropDimsImgUrl({
            imgurl :imageUrl,
            height: 360,
            width: 720,
            thumbs : true,
            quality: true,
            autoRatio : true,
            sharpen : true
          });
        },
        setPcPrdBaseNoImg,
        cutPercent(point){
            if(0 >= point ||  point < 10){
                return "0";
            }

            if(point >= 100){
                return "100";
            }

            let result = `${String(point).substr(0, 1)}0`;

            return result;
        },
        reviewScore(score){
            let scorePer = this.cutPercent(score)
            return (Math.round((parseInt(scorePer) /10) *10) / 20).toFixed(1)
        }
    },
    filters : {
        comma
    },
}
</script>
