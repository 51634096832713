var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "trip_type option_3" },
    [
      _c(
        "fieldset",
        [
          _c("legend", [_vm._v("여행상품 검색")]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "option_type_box" },
            _vm._l(_vm.tabs, function (ref) {
              var key = ref[0]
              var tab = ref[1]
              return _c(
                "li",
                {
                  key: key,
                  on: {
                    click: function ($event) {
                      return _vm.selectedTab(key)
                    },
                  },
                },
                [
                  _c("input", {
                    staticClass: "radio_design",
                    attrs: {
                      id: "option_type_3_" + tab.index,
                      name: "type_select_2",
                      type: "radio",
                    },
                    domProps: { checked: tab.isActive },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      class: ["css-label", { on: tab.isActive }],
                      attrs: { for: "option_type_3_" + tab.index },
                    },
                    [_vm._v(_vm._s(tab.name))]
                  ),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _vm.selectedKey !== "OT"
            ? _c("div", { staticClass: "option_check_box_1" }, [
                _c("div", { staticClass: "option_select_box_1" }, [
                  _c("div", { staticClass: "option_detail" }, [
                    _c(
                      "div",
                      {
                        class: [
                          "comp_select_type_1",
                          { focus: _vm.isOpenBox, active: _vm.isSelectCtg },
                        ],
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn_select",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                _vm.isOpenBox = !_vm.isOpenBox
                              },
                            },
                          },
                          [
                            _vm.selectGtg
                              ? _c("span", { staticClass: "in_btn" }, [
                                  _vm._v(_vm._s(_vm.selectGtg.dispCtgrNm)),
                                ])
                              : _c("span", { staticClass: "in_btn" }, [
                                  _vm._v(
                                    _vm._s(
                                      (_vm.selectedKey == "JT" ||
                                      _vm.selectedKey == "DT"
                                        ? "여행상품"
                                        : "지역") + " 선택"
                                    )
                                  ),
                                ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "arrow" }),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.categorys && _vm.categorys.length > 0
                          ? _c(
                              "ul",
                              { staticClass: "select_list" },
                              _vm._l(_vm.categorys, function (ctg, index) {
                                return _c("li", { key: index }, [
                                  _c(
                                    "button",
                                    {
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectedCtg(ctg)
                                        },
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "in_btn" }, [
                                        _vm._v(_vm._s(ctg.dispCtgrNm)),
                                      ]),
                                    ]
                                  ),
                                ])
                              }),
                              0
                            )
                          : _c("ul", { staticClass: "select_list" }, [
                              _vm._m(0),
                            ]),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "log",
                        rawName: "v-log",
                        value: {
                          data: {
                            area: "quicksearch_tour",
                            label: _vm.tabsMap.get(_vm.selectedKey).label,
                          },
                        },
                        expression:
                          "{ data: { area: 'quicksearch_tour', label: tabsMap.get(selectedKey).label }}",
                      },
                    ],
                    staticClass: "btn_type_01",
                    attrs: { type: "button" },
                    on: { click: _vm.goSearch },
                  },
                  [_vm._v("검색")]
                ),
              ])
            : _c("foreign-travel"),
          _vm._v(" "),
          _c("search-history"),
        ],
        1
      ),
      _vm._v(" "),
      _c("trip-company-list", {
        attrs: { bannerKey: _vm.bannerKey, index: 3 },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("button", { attrs: { type: "button" } }, [
        _c("span", { staticClass: "in_btn" }, [_vm._v("선택해주세요")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }