<template>
  <div class="tab_menu_type_1" v-if="prdInfo.tabList">
    <ul>
      <li
        :class="{first:index==0, last:(index+1)==prdInfo.tabList.length, selected:tab.dispObjNo == prdInfo.activeDispNo}"
        v-for="(tab,index) in prdInfo.tabList" :key="index" @mouseover="doMouseOver(tab)">
        <a href="javascript:" @click="moveCategory(tab.dispObjNo,true)" v-log="{ data: { area: 'tour_'+id, label: 'category', body : {
'content_no':tab.dispObjNo, 'content_type':'CATEGORY', 'content_name':tab.tabName} }}">{{ tab.tabName }}</a>
        <span class="ico_dot"></span>
      </li>
    </ul>
  </div>
</template>

<script>
import {goCategoryList,checkCategory} from '../../../utils/main';
import * as CONST from '@/common/constants/tourConst' ;
import log from '@/common/directives/log';

export default {
    directives: {log},
    props : ['prdInfo','id'],
    data(){
        return {
            TOUR_CONST : CONST
        }
    },
    methods : {
        moveCategory(dispObjNo,dealPrdYn){
            let isDeal = (this.id=='deal');
            goCategoryList(checkCategory(dispObjNo,isDeal),dealPrdYn)
        },
        doMouseOver(tab){
            this.$store.commit(`TOUR_MAIN/SET_${this.id.toUpperCase()}_TAB_ACTIVE_DISP_NO`,tab);
        }
    }
}
</script>

