import { setLogPropsOnElement } from '../rakeLog';

const addClickListenerOnElement = (el, shouldPreventAllEvent) => {
  el.addEventListener('click', (e) => {
    if (shouldPreventAllEvent) e.stopImmediatePropagation();
    else e.stopPropagation();
    window.rakeLog.sendRakeLog(el, 'click');
  });
};

const setLogData = (el, value) => {
  if (value.shouldNotSetting) return;
  setLogPropsOnElement(el, value.data);
  if (value.shouldManualSendClickLog) {
    addClickListenerOnElement(el, value.shouldPreventAllEvent);
  }
};

export default {
  inserted(el, { value = {} }) {
    setLogData(el, value);
  },
  componentUpdated(el, { value = {} }) {
    setLogData(el, value);
  },
};
