var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "season_recom_list" }, [
    _c("h1", [
      _c("img", {
        attrs: {
          src: _vm.V_PC_IMG_URL + "/img/trip/common/tit_sam.png",
          alt: "요즘가면 좋은 추천 여행지",
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "list_box" }, [
      _vm.banners && _vm.banners.length > 0
        ? _c(
            "ul",
            _vm._l(_vm.banners, function (banner, index) {
              return _c("li", { key: index }, [
                banner
                  ? _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "log",
                            rawName: "v-log",
                            value: {
                              data: {
                                area: "recommend_tour",
                                label: "banner",
                                body: {
                                  content_type: "CATEGORY",
                                  content_name: banner.dispObjNm,
                                  content_no: banner.dispObjNo,
                                  position_l2: index + 1,
                                },
                              },
                            },
                            expression:
                              "{ data: { area: 'recommend_tour', label: 'banner', body : {'content_type':'CATEGORY','content_name': banner.dispObjNm,\n'content_no':banner.dispObjNo,'position_l2':index+1} }}",
                          },
                        ],
                        attrs: { href: banner.dispObjLnkUrl },
                      },
                      [
                        _c("div", { staticClass: "thumb_box" }, [
                          _c("img", {
                            attrs: {
                              src: _vm.V_UPLOAD_URL + banner.lnkBnnrImgUrl,
                              alt: banner.dispObjNm,
                            },
                            on: {
                              error: function ($event) {
                                return _vm.setPcPrdBaseNoImg($event, "200")
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "text_box" }, [
                          _c("p", {
                            domProps: { innerHTML: _vm._s(banner.extraText) },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "category_box" }, [
                          _c("div", { staticClass: "dim" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "info" }, [
                            _c("span", { class: "icon " + banner.slctIconCd }, [
                              _vm._v(_vm._s(banner.dlvIcon)),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "text" }, [
                              _vm._v(_vm._s(banner.dispObjNm)),
                            ]),
                            _vm._v(" "),
                            banner.tmpltType == "ctgr"
                              ? _c("span", { staticClass: "result" }, [
                                  _vm._v("여행상품 "),
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm._f("comma")(banner.goodCnt))
                                    ),
                                  ]),
                                  _vm._v("개"),
                                  _c("span", { staticClass: "arrow" }),
                                ])
                              : banner.tmpltType == "prd"
                              ? _c("span", { staticClass: "result" }, [
                                  _vm._v("상품보기"),
                                  _c("span", { staticClass: "arrow" }),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    )
                  : _c("div", { staticClass: "thumb_box" }, [
                      _c("img", {
                        attrs: { src: _vm.V_PC_PRD_NO_IMG_100, alt: "준비중" },
                      }),
                    ]),
              ])
            }),
            0
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }