<template>
  <section class="quick_search_box">
    <div class="inner">
      <ul class="tab_menu">
        <li :class="['trip_tyep_'+tab.val,{ selected: tab.isActive }]" v-for="[key, tab] of tabs" :key="key"><a
          href="javascript:" @click="selectedTab(key)" v-log="{ data: { area: 'quicksearch', label: 'tab', body : {'btn_name':tab.name}}}" >{{ tab.name }}</a></li>
      </ul>

      <flight-search v-show="isFlight&&tabsMap.get('flight').isActive"/>
      <hotel-search v-show="tabsMap.get('hotel').isActive"/>
      <travel-prd-search v-show="tabsMap.get('trip').isActive"/>
    </div>
  </section>
</template>

<script>
import log from '@/common/directives/log';
import flightSearch from "./quickSearch/flightSearch";
import hotelSearch from "./quickSearch/hotelSearch";
import travelPrdSearch from "./quickSearch/travelPrdSearch";
import {mapGetters} from "vuex";
export default {
    directives: {log},
    components: {
        flightSearch,
        hotelSearch,
        travelPrdSearch
    },
    data(){
        let tabType = new Map([
            ["flight", { type: "flight", isActive: false, name: "항공권", val:'1'}],
            ["hotel", { type: "hotel", isActive: false, name: "숙박상품", val:'2' }],
            ["trip", { type: "trip", isActive: false, name: "여행상품", val:'3' }]
        ]);

        return {
            tabs: Array.from(tabType),
            tabsMap: tabType,
        };
    },
    computed : {
        ...mapGetters({
            isFlight : 'TOUR_MAIN/GET_IS_SHOW_FLIGHT_QUICKSEARCH',
        })
    },
    mounted() {
        this.selectedTab('hotel')
    },
    methods :{
        selectedTab(selectedKey) {
            this.tabsMap.forEach((value, key) => value.isActive = key === selectedKey);
        },
    }
}
</script>
