var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.banner
    ? _c("div", { staticClass: "promotion_box" }, [
        _c(
          "a",
          {
            staticClass: "lk_bnr",
            attrs: {
              href: "javascript:",
              target: "_blank",
              title: "새 창 이동",
            },
            on: { click: _vm.openPopup },
          },
          [
            _c("img", {
              attrs: {
                src: _vm.V_UPLOAD_URL + _vm.banner.linkBannerImageUrl,
                alt: _vm.banner.displayObjectName,
              },
            }),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }