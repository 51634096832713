<template>
    <div id="footer"></div>
</template>

<script>
export default {
    mounted(){
         pcComm.renderFooter("footer", {
            "myHistory": false,
            "footerAd": false,
            "floatingBanner" : true
        })
    }
}
</script>
