<template>
  <div class="option_check_box_1">
    <keyword-box :type="'DHOTEL'" @sendKeyword="setKeyword" @updateInput="updateInput"/>
    <calendar-box :startDate="defaultDate" @sendDate="setDate" :minDate="minDate"/>

    <button type="button" v-log="{ data: { area: 'quicksearch_staying', label: 'domestic_search', body : { search_keyword : param.placeName }}}" class="btn_type_01" @click="search(false)"><span class="in_btn">검색</span></button>
    <button type="button" class="btn_type_02" @click="search(true)"><span class="in_btn">지도검색</span></button>
  </div>
</template>

<script>
import log from '@/common/directives/log';
import keywordBox from "./keywordBox";
import calendarBox from "./calendarBox";
import {getDefaultDate} from "@/main/utils/main";
import {DHOTEL_CATEGORY_NO} from '@/common/constants/tourConst';
import {V_PC_TOUR_ROOT_URL_} from "@/common/const";
import qs from "qs";
export default {
    directives: {log},
    data(){
        return {
            defaultDate : null,
            minDate : new Date(), //국내숙박은 여행은 현재날짜 부터 선택 가능해야 함.
            param: {
              placeName : '',
              mallType:'tour',
              dispCtgrNo: DHOTEL_CATEGORY_NO,
              room:"2,0",
              isKeywordSearch:""
            },
            prdCatalogNo : ""
        }
    },
    components: {
        calendarBox,
        keywordBox
    },
    created() {
        this.initDefault();
    },
    methods : {
        setDate(date){
            this.defaultDate = Object.assign([],date);
            this.setParamDate(date)

        },
        setParamDate(date){
          this.param.checkIn = date[0].replace(/-/gi, "");
          this.param.checkOut = date[1].replace(/-/gi, "");
        },
        // 검색모듈에 없는 키워드 입력 값인 경우
        updateInput(word) {
          this.param.placeName = word
          this.param.isKeywordSearch = 'Y'
        },
        // 키워드 검색에서 선택된 도시/숙소 있는 경우
        setKeyword(word){
            const {showLabel,sido,sigungu,posLat,posLon,ctlgGrpCd,areaDetailName} = word;
            let placeName = showLabel || areaDetailName
            placeName = placeName === '전체' ? sido : placeName
            let param = {
                placeName : placeName?placeName:"",
                sido: sido?sido:"",
                sigungu : sigungu?sigungu:"",
                latitude : posLat?posLat:"",
                longitude: posLon?posLon:"",
                isKeywordSearch:"N"
            };
            this.prdCatalogNo = ctlgGrpCd
            this.param = {...this.param,...param};
        },

        initDefault(){
            let todayDate = new Date(),
              calcDay = 2 - todayDate.getDay(); // 체크인/체크아웃 날짜 다음주 화요일/수요일로 default값 변경

            let date = getDefaultDate((7+calcDay),'YYYY-MM-DD');
            this.defaultDate = date
            this.setParamDate(date);
        },
        goPrdDetail(){
            const {checkIn, checkOut, room} = this.param,
              catalogNo = this.prdCatalogNo.split('_')[1];

            let url = `${V_PC_TOUR_ROOT_URL_}/tour/TourfrontDomesticAction.tmall/detail?${qs.stringify({checkIn,checkOut,room,catalogNo})}`;
            window.location.href = url;
        },
        goSearchList(urlStr, param){
            let url = `${V_PC_TOUR_ROOT_URL_}${urlStr}?${qs.stringify(param)}`;
            window.location.href = url;
        },
        search(isMap){
            if(this.prdCatalogNo){
                this.goPrdDetail();

            }else{

                if(isMap){
                    this.goSearchList("/tour/TourfrontDomesticAction.tmall/map",{...this.param, ...{isMapYn:'Y'}});
                }else{
                    this.goSearchList("/tour/TourfrontDomesticAction.tmall/list",this.param);
                }
            }
        }
    }

}
</script>
