<template>
  <div class="option_check_box_2">
    <div class="option_select_box_1">
      <div class="option_detail">
        <city-select-box :data="departure"/>
        <span class="type_info_1">왕복</span>
        <city-select-box :data="arrival"/>
      </div>
    </div>
    <button type="button" class="btn_type_01" v-log="{ data: { area: 'quicksearch_tour', label: 'abroad_search' }}" @click="moveToCategoryMain">검색</button>
  </div>
</template>

<script>
import {DOMESTIC_STARTING_POINT_CODE, TOUR_PRD_TYPE, goCategoryMain} from '../../../../utils/main'
import {PC_FOREIGN_TOUR_CATEGORY_NO} from '@/common/constants/tourConst';
import CitySelectBox from "./citySelectBox";
import {mapGetters} from "vuex";
import {localStorage} from "../../../mixin/service"
import log from '@/common/directives/log';

export default {
    directives: {log},
    mixins : [localStorage],
    data(){
        return {
            departure :["출발지",""],
            arrival : ["도착지",""],
            tourPrdType : "",
            tourType: Array.from(TOUR_PRD_TYPE),
            typeMap: TOUR_PRD_TYPE,
            localStorage:{
                dispCtgrNo: PC_FOREIGN_TOUR_CATEGORY_NO,
                method: "search",
                radius: "5000",
            }
        }
    },
    components: {
        CitySelectBox,
        departureAndArrivalCity : () => import(/* webpackChunkName: "view-main-modal" */'../../layer/departureAndArrivalCity'),
        domesticDepartureCity : () => import(/* webpackChunkName: "view-main-modal" */'../../layer/domesticDepartureCity'),
    },
    created() {
        this.initCity();
    },
    computed : {
        ...mapGetters({
            mainStorageData : 'TOUR_MAIN/GET_RECENT_SEARCH_STORAGE',
        })
    },
    methods : {
        setDate(date){
            this.startDate = date[0];
        },
        initCity(){
            const {code,name} = DOMESTIC_STARTING_POINT_CODE.get('ICN_GMP');
            this.departure = [name,code];
        },
        moveToCategoryMain(){
            goCategoryMain(PC_FOREIGN_TOUR_CATEGORY_NO);
        }
    }
}
</script>

