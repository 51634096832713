import * as Path from '@/common/const.js';
import moment from "moment";

export const comma = function setComma(value){
    if (value == 0) return 0;
    if (typeof value === 'undefined') return;
    if(typeof value == 'string'){
        value = Number(value);
    }
    return Math.round(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

}

export const isEmpty = (value) => {
  if (value === null) return true
  if (typeof value === 'undefined') return true
  if (typeof value === 'string' && value === '') return true
  if (Array.isArray(value) && value.length < 1) return true
  if (typeof value === 'object' && value.constructor.name === 'Object' && Object.keys(value).length < 1) return true
  if (typeof value === 'object' && value.constructor.name === 'String' && Object.keys(value).length < 1) return true

  return false
}

export const isEmptyValue = (value) => ( !value || ( value != null && typeof value == "object" && !Object.keys(value)?.length ) )
export const isNotEmptyValue = (value) => !isEmptyValue(value);

export const stringUtil = {

   trim : function(str){
     if (isEmptyValue(str)) return '';
     return str.replace(/\s*/g, "");
   },

  //TODO : export로 빼놓음 삭제 예정 사용하지 말기.
  moneyComma : function(value) {
    if (typeof value === 'undefined' || value == 0 || value == null) return 0;
    return Math.round(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },

  //hh.mm
  timeComma : function(str){
    if (str === '' || str === null ||typeof str === 'undefined') return '';
    let h = str.substr(0, 2),
      m = str.substr(2,4);
    return `${h}:${m}`;
  },

  //yyyy.mm.dd
  dateComma :function(str){
    if (str === '' || str === null ||typeof str === 'undefined') return '';
    let y = str.substr(0, 4),
      m = str.substr(4, 2),
      d = str.substr(6, 2);
    return `${y}.${m}.${d}`;
  },

  //mm.dd
  dayComma :function(str){
    if (str === '' || str === null||typeof str === 'undefined') return '';
    let m = str.substr(4, 2),
      d = str.substr(6, 2);
    return `${m}.${d}`;
  },
  daySlash :function(str){
    if (str === '' || str === null||typeof str === 'undefined') return '';
    let m = str.substr(4, 2),
        d = str.substr(6, 2);
    return `${m}/${d}`;
  },

  //yyyy.mm.dd(요일) hh:mi
  getDateText : function (str, day){
    if (!/^(\d){12}$/.test(str)) return null;
    var y = str.substr(0, 4),
      m = str.substr(4, 2),
      d = str.substr(6, 2),
      h = str.substr(8, 2),
      mi = str.substr(10, 2);
    return y+"."+m+"."+d+" ("+day+") "+h+":"+mi;
  },

  //TODO : export로 빼놓음 삭제 예정 사용하지 말기.
  mRound:function(value){
    if (typeof value === 'undefined' || value == null) return;
    return Math.round(value);
  }
}

export const dateUtil = {
    yyyymmdd: function(date) {
        var mm = date.getMonth() + 1,
            dd = date.getDate();

        return [date.getFullYear(), (mm < 10 ? '0' : ''), mm, (dd < 10 ? '0' : ''), dd].join('');
    },

    parseMonthFormat : function(str){
      if(!/^(\d){6}$/.test(str)) return null;
      var y = str.substr(0,4),
        m = str.substr(4,2);
      return new Date(y,m-1);
    },
    parseDateFormat : function(str){
        if(!/^(\d){8}$/.test(str)) return null;
        var y = str.substr(0,4),
            m = str.substr(4,2),
            d = str.substr(6,2);
        return new Date(y,m-1,d);
    },
    parseDateTimeFormat: function (str) {
      if (!/^(\d){12}$/.test(str)) return null;
      var y = str.substr(0, 4),
        m = str.substr(4, 2),
        d = str.substr(6, 2),
        h = str.substr(8, 2),
        mi = str.substr(10, 2);
      return new Date(y, m - 1, d, h, mi);
    },
    getDay: function (str) {

      var date = this.parseDateFormat(str);

      var daysArray = ['일', '월', '화', '수', '목', '금', '토'];
      if (date) {
        var dayNum = date.getDay();
        return daysArray[dayNum];
      }
      return "";
    },
    dateDiffInDays : function(checkin, checkout, defaultVal){

        if(isNotEmptyValue(checkin) && isNotEmptyValue(checkout)){
            var date1 = dateUtil.parseDateFormat(checkin.replace(/-/gi,""));
            var date2 = dateUtil.parseDateFormat(checkout.replace(/-/gi,""));
            if(date1 !== null && date2 !== null){
                var diffDays =(date2.getTime() - date1.getTime())/1000/60/60/24;
                return diffDays;
            }
        }
        return defaultVal;

    }
}

export function getCookie(cName) {
    cName = cName + '=';
    var cookieData = document.cookie;
    var start = cookieData.indexOf(cName);
    var cValue = '';
    if(start != -1){
         start += cName.length;
         var end = cookieData.indexOf(';', start);
         if(end == -1)end = cookieData.length;
         cValue = cookieData.substring(start, end);
    }
    return unescape(cValue);
}

export function isApp(){
    var appType = getCookie("appType");
    return (appType.indexOf("appmw") > -1);
}

export function isiPhone(){
    return /^((?!chrome|android).)*iPhone/i.test(navigator.userAgent);
}

export function tourImageUrl(url){
    if(isEmptyValue(url)) return null;

    let domainList = ['http', 'https'],
        resultUrl ='',
        dataProp = domainList.reduce((prop,value) =>{
            if(url.indexOf(value) > -1){
                prop.imgDomain = true;
            }
            return prop;
        },{imgDomain:false});

    if(!dataProp.imgDomain){
        resultUrl = Path.getUploadURL()+url;
    }else{
        resultUrl = url;
    }

    return resultUrl;

}

//IE 버전 확인
export function getIeVersion() {
	var rv = -1; // Return value assumes failure.
	if (navigator.appName == 'Microsoft Internet Explorer') {
		var ua = navigator.userAgent;
		var re = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");
		if (re.exec(ua) != null)
			rv = parseFloat(RegExp.$1);
	}
	return rv;
}

export function isSafari() {
  var ua = window.navigator.userAgent.toLowerCase();
  return (ua.indexOf('safari') >= 0 && ua.indexOf('chrome') < 0 && ua.indexOf('android') < 0);
}

export const openGraph = {
  setImage({el = null}){
      try {
        if(snsShareInfo){  //declared in app-tour inc_snsShare.js
          snsShareInfo.options.image = (el && !el.src.match(/no_image|img_noimg/g) && el.src.match(/\b(https?:\/\/.*?\.[a-z]{2,4}\b)/g)) ? el.src : Path.V_MW_OG_IMG_PATH
        }
      } catch(e){}
  }
}

//상품 목록 노이미지
export function setBasePrdImgUrl(event){
  event.target.src = Path.V_MW_PRD_BASE_IMG;
}

//딜 상품 노이미지
export function setBaseDealPrdImgUrl(event){
  event.target.src = Path.V_MW_PRD_BASE_IMG_720_360;
}

export function setBaseListImgUrl(event){
  event.target.src = Path.V_MW_LIST_PRD_BASE_IMG;
}

export function setPcPrdBaseNoImg(event,size){
    // s.011st.com 에 NO_IMG 사이즈 없는 경우엔 404 무한호출 발생할 수 있음
    event.target.src = Path['V_PC_PRD_NO_IMG_'+size];
}


//// SSL 적용 여부에 따른 프로토콜 처리
export const getSSLPath = (url) =>{
  if(!url) return;
  const isSSL = ('https:' == document.location.protocol);
  return (isSSL) ? url.replace(/^http:\/\//i, 'https://') : url;
}

export const timeout = ms => new Promise(resolve => setTimeout(resolve, ms));

//배너 랜텀 노출
export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

//로그 삽입
export function sendRakeLog(data) {
    try{
        rakeLog.sendRakeLog(data);
    }catch(e){}
}

//배너 background prefix
export function prefixCheck(data){
    if(isEmptyValue(data)){
        return '';
    }

    return data.includes('#') ? data : `#${data}`;
}

//할인률
export function discountRate(selPrc,finalDscPrc){
    if(parseInt(selPrc) > 0 && parseInt(finalDscPrc) > 0){
        return Math.round((((parseInt(selPrc) - parseInt(finalDscPrc)) * 100.0) / selPrc));
    }
    return 0;
}

//셀러 노이미지
export function setSellerImgUrl(event){
  event.target.src = Path.V_MW_SELLER_IMG_80x22;
}

export function numberRoundDown(value, exp = 10){
    const number = parseInt(value);
    return (number) ? Math.floor(number/exp) * exp : 0
}




//소수점 반올림
export function mRound(value){
  if (typeof value === 'undefined' || value == null) return;
  return Math.round(value);
}

//메뉴 sticky
export function sticky(gnbTopSpacing, className, zIndex=1) {
    let topSpacing = gnbTopSpacing || null;

    if (topSpacing == null) {
      topSpacing = $("#wrap").hasClass("header-fixed") ? 57 : 0
    }
    new skpui.magnet(`.${className}`, {useSticky: false, topSpacing: topSpacing, zIndex: zIndex});
}

//객실 박수
export function diffDays({checkIn, checkOut}){
    const checkin = moment(checkIn, "YYYYMMDD");
    const checkout = moment(checkOut, "YYYYMMDD");
    const diff = moment.duration(checkout.diff(checkin)).asDays();
    return diff;
}

//이메일 체크
export function validEmail(sMemID) {
  let regEmail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
  return regEmail.test(sMemID);
}

export function kilometer(value) {
  if (typeof value === 'undefined' || value == null) return;

  return (value / 1000.0).toFixed(1);
}

export function distanceUnit(value){
  if (typeof value === 'undefined' || value == null) return;

  let result = 0;
  if( value < 1000){
      result =  value.toFixed(1) + "m";
  }else{
      result =  (value/1000).toFixed(1) + "km";
  }

  return result;
}

// 디바이스 정보
export function  checkDevice(){

    let device = {
        isIOS : false,
        isAOS : false,
        version : ''
    }

    let ua = navigator.userAgent.toLowerCase(),
        agentTest = (/iphone|ipad|xipod|android/i.test(ua));

    if(agentTest){
        device.isIOS = ua.match(/(ipad|iphone|ipod)/g) && ua.match(/(ipad|iphone|ipod)/g).length > 0||false;
        if(device.isIOS){
            device.version = window.Number(ua.substr(ua.indexOf('os') + 3, 3).replace('_', '.'));
        }
        device.isAOS = /(android)/i.test(ua)||false;
    }

    return device;
}

export const debounce = (func, delay = 500) => {
  let timeout = null;
  return (...args) =>{
      if(timeout){
          clearTimeout(timeout);
      }
      timeout = setTimeout(()=>{
          func(...args)
      },delay);
  }
}

export const replaceNewline = (str) => str.replace(/(?:\r\n|\r|\n)/g, '<br/>')

export const isOnlyKorean = (str) => (/^[ㄱ-ㅎ|가-힣]+$/.test(str))


// 브라우저 체크
export const device = {
  getUA : () => navigator.userAgent.toLowerCase(),
  isIOS : () => device.getUA().match(/(ipad|iphone|ipod)/g) && device.getUA().match(/(ipad|iphone|ipod)/g).length > 0,
  isAOS : () => (/(android)/i.test(device.getUA())),
  version : () =>{
    let version = 0;
    if (device.isIOS()) {
        var match = device.getUA().match(/os (\d+_\d+)/i);
        version = match && match.length > 1 && match[1] ? +match[1].replace('_', '.') : 0;
    } else if (device.isAOS()) {
      version = parseFloat(device.getUA().slice(device.getUA().indexOf('android') + 8));
    } else {
      version = 0;
    }
    return version;
  }
}

export const isSupportedDevice = () =>{
  if (device.isAOS()) {
    return device.version() >= 4.4;
  } else if (device.isIOS()) {
    return device.version() >= 10;
  }
  return true;
}

export const isLogin = () =>{
  var arg = "TMALL_AUTH=";
  var alen = arg.length;
  var clen = document.cookie.length;
  var i = 0;

  while(i < clen){
      var j = i + alen;
      if(document.cookie.substring(i, j) == arg){
          return true;
      }
      i = document.cookie.indexOf(" ", i) + 1;
      if(i == 0) break;
  }
  return false;
}

export const goLoginPage = (url) => {
  let path = `https:${Path.V_MW_TOUR_ORIGIN_DOMAIN_}${url}`;
  window.location.href = `${Path.LOGIN_URL}?returnURL=${encodeURIComponent(path)}`;
}

export const goLoginPagePC = (url) => {
    window.location.href = `${Path.LOGIN_URL_PC}?returnURL=${encodeURIComponent(Path.V_PC_TOUR_ROOT_URL_HTTPS_+url)}`;
}

export const redirectHTTPS = (fullPath) =>{
  if (!document.location.protocol.includes("https")) {
    window.location.replace(`https:${Path.V_MW_TOUR_ORIGIN_DOMAIN_}${fullPath}`);
  }
}

export const redirectPCHTTPS = (fullPath) =>{
    if (!document.location.protocol.includes("https")) {
        window.location.href = `https:${Path.V_PC_TOUR_ROOT_URL_}${fullPath}`;
    }
}

export const isChangeSearchCondition = (to, from) => {
  let isCheck = false;
  const tkeys = Object.keys(to);
  const fkeys = Object.keys(from);
  const keys = fkeys.length > tkeys.length ? fkeys : tkeys;
  for (let key of keys) {
    let f = `${from[key]}`,
      t = `${to[key]}`;
      if (f != t) {
          isCheck = true;
          break;
      }
  }
  return isCheck;
}

export const extractTourDomainPath = (fullPath) =>{
  if(fullPath.includes(Path.V_MW_TOUR_PROD_URL_)){
    return fullPath.split(Path.V_MW_TOUR_PROD_URL_)[1];
  }else if(fullPath.includes(Path.V_PC_TOUR_PROD_URL_)){
    return fullPath.split(Path.V_PC_TOUR_PROD_URL_)[1];
  }
  return fullPath;
}

export const scrollToPosition = (KEY) => {
  let positionY = localStorage.getItem(KEY) ?? 0;
  if(positionY > 0){
    window.scrollTo(0,positionY);
  }
}

//상품 포토리뷰 상세URL
export const photoReviewListURL = (productNo, isPhotoReviewYn, contentsMappingNo, contentImageNo) => `${Path.V_MW_OM_ROOT_URL_}/Review/photoReviewList.tmall?inLocation=PD&isPhotoReviewYn=${isPhotoReviewYn}&prdNo=${productNo}&contMapNo=${contentsMappingNo}&contImgNo=${contentImageNo}`


//상품 포토리뷰 갤러리 URL
export const photoReviewGalleryURL = (productNo) => `${Path.V_MW_OM_ROOT_URL_}/Review/photoReviewGallery.tmall?inLocation=PD&isPhotoReviewYn=Y&prdNo=${productNo}`


//판매자에게 문의하기 URL
export const sellerQuestionURL = (productNo) => `${Path.V_MW_OM_ROOT_URL_}/Product/Detail/productQaViewV2.tmall?prdNo=${productNo}&tabNo=3&openType=tour&tReturnURL=` + encodeURIComponent(location.href)

//protocol 제거
export const removeProtocol = (data) => data.replace(/(^\w+:|^)/, '')

export const sellerQuestionURLMW = (productNo) => `${Path.V_MW_OM_ROOT_URL_}/Product/Detail/productQaViewV2.tmall?prdNo=${productNo}&tabNo=3&openType=tour&tReturnURL=` + encodeURIComponent(location.href)
export const sellerQuestionURLPC = (productNo) => `${Path.V_PC_OM_ROOT_URL_}/product/ProductQnaForm.tmall?method=insertProductQnAForm&isSSL=Y&prdNo=${productNo}&hostUrl=www.11st.co.kr`

export const convertStringToDate = function convertStringToDate(yyyymmdd, hhmm){
  var year   = parseInt(yyyymmdd.substring(0,4));
  var month  = parseInt(yyyymmdd.substring(4,6))-1;
  var date   = parseInt(yyyymmdd.substring(6,8));
  var hour = parseInt(hhmm.substring(0,2));
  var mm = parseInt(hhmm.substring(2,4));

  var d = new Date(year,month,date,hour,mm);

  return d;
}

export const parseDate = function parseDate(date){

  var daysArray = ['일', '월', '화', '수', '목', '금', '토'];

  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      date = '' + d.getDate(),
      day = d.getDay(),
      dayStr = daysArray[day],
      hour = ''+ d.getHours(),
      year = d.getFullYear(),
      minute = '' + d.getMinutes();

  if (month.length < 2) month = '0' + month;
  if (date.length < 2) date = '0' + date;
  if (hour.length < 2 ) hour = '0' + hour;
  if (minute.length < 2) minute = '0' + minute;

  return {
      year : year,
      month : month,
      date : date,
      day : dayStr,
      hour : hour,
      minute : minute
  }
}