<template>
  <div class="option_check_box_2">
    <keyword-box :type="'AHOTEL'" :popularWord="keyWord" @sendKeyword="setKeyword"/>
    <calendar-box :startDate="defaultDate"  @sendDate="setDate" :minDate="minDate"/>
    <room-box @interface="setRooms"/>
    <button v-log="{ data: { area: 'quicksearch_staying', label: 'abroad_search', body : { search_keyword : param.code }}}" slot="search_button" type="button" class="btn_type_01" @click="search"><span class="in_btn">검색</span></button>
  </div>
</template>

<script>
import log from '@/common/directives/log';
import keywordBox from "./keywordBox";
import calendarBox from "./calendarBox";
import roomBox from "./roomBox";
import {AHOTE_CATEGORY_NO} from "@/common/constants/tourConst";
import {V_PC_TOUR_ROOT_URL_} from '@/common/const';
import qs from "qs";
import {isEmptyValue} from '@/common/utils.js';
export default {
    directives: {log},
    props : ['popularCity'],
    data(){
        return {
            defaultDate : null,
            minDate : new Date(this.$moment().add(1,'days').format('YYYY-MM-DD')), //해외여행은 현재날짜 +1 부터 선택 가능해야 함.
            param: {
                mallType:'tour',
                count_per_page: 2000,
                dispCtgrNo : AHOTE_CATEGORY_NO,
                room:['2']
            },
            keyWord:""
        }
    },
    components: {
        calendarBox,
        keywordBox,
        roomBox
    },
    methods : {
        setDate(date){
            this.defaultDate = Object.assign([],date);
            this.param.checkin = date[0];
            this.param.checkout = date[1];
        },
        setKeyword(word){
            const {type,code} = word;
            let param = {type,code};

            if(!this.isListRouter(word.type)){
                param = {...param,...{hotel_id:code}};
            }

            this.param = {...this.param,...param};
        },
        setRooms(room){
            this.param.room = room;
        },
        search(){
            if(isEmptyValue(this.param.code) || isEmptyValue(this.param.type)){
                alert('검색어를 다시 확인해주세요.');
                return;
            }

            if(isEmptyValue(this.param.checkin) || isEmptyValue(this.param.checkout)){
                alert('체크인/아웃 정보를 선택해 주세요.');
                return;
            }

            const rooms = `&room=${this.param.room.join("&room=")}`,
                  {mallType,dispCtgrNo,checkin,checkout} = this.param;

            let data = {},
                url = "";
            if(this.isListRouter(this.param.type)){
                const {count_per_page,type,code} = this.param;
                data = {count_per_page,mallType,dispCtgrNo,type,code,checkin,checkout};

                //리스트로 이동
                url = `${V_PC_TOUR_ROOT_URL_}/tour/ahotel/getHotelList.tmall`;

            }else{
                data = {mallType,dispCtgrNo,hotel_id:this.param.hotel_id,checkin,checkout};

                //상세로 이동
                url = `${V_PC_TOUR_ROOT_URL_}/tour/ahotel/hotelProductDetail.tmall`;
            }

            let query =  qs.stringify(data) + rooms;
            window.location.href = `${url}?${query}`;

        },
        isListRouter(type){
            return (type == 'airport' || type == 'city' || type == 'spot')
        }
    },
    watch : {
        popularCity(word){
            if(word){
                this.keyWord = word.name;
                this.setKeyword(word);
            }
        }
    }
}
</script>

