var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isShow,
          expression: "isShow",
        },
      ],
      staticClass: "hotel_recomd_box",
    },
    [
      _vm.citys && _vm.citys.length > 0
        ? _c(
            "div",
            _vm._l(_vm.citys, function (city, index1) {
              return city.keywordBoList && city.keywordBoList.length > 0
                ? _c("div", { key: index1, staticClass: "list_box" }, [
                    _c("h2", { staticClass: "tit" }, [
                      _vm._v(_vm._s(city.title)),
                    ]),
                    _vm._v(" "),
                    _c(
                      "ul",
                      _vm._l(city.keywordBoList, function (list, index2) {
                        return _c("li", { key: index2 }, [
                          _c("a", {
                            attrs: { href: "javascript:" },
                            domProps: {
                              innerHTML: _vm._s(_vm.wordHighlight(list)),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.setKeyword(list)
                              },
                            },
                          }),
                        ])
                      }),
                      0
                    ),
                  ])
                : _vm._e()
            }),
            0
          )
        : _c("div", [
            _vm.type === "DHOTEL"
              ? _c("div", { staticClass: "local_box" }, [
                  _c("h2", { staticClass: "tit" }, [_vm._v("주요 지역 선택")]),
                  _vm._v(" "),
                  _vm.areaDetailList && _vm.areaDetailList.length > 0
                    ? _c("div", { staticClass: "local_name" }, [
                        _c(
                          "ul",
                          _vm._l(
                            _vm.countryAreaList,
                            function (country, index) {
                              return _c(
                                "li",
                                { key: index, class: country.stat2 },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "name",
                                      attrs: { href: "javascript:void(0);" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.countryClick(index)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(country.areaName))]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "list_box" }, [
                    _c(
                      "ul",
                      _vm._l(_vm.cityList, function (city, index) {
                        return _c("li", { key: index }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "javascript:void(0);" },
                              on: {
                                click: function ($event) {
                                  return _vm.setKeyword(city)
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "txt" }, [
                                _vm._v(_vm._s(city.areaDetailName)),
                              ]),
                              _c("span", { staticClass: "num" }, [
                                _vm._v(
                                  _vm._s(_vm.moneyComma(city.hotelCount)) +
                                    "개의 숙소"
                                ),
                              ]),
                            ]
                          ),
                        ])
                      }),
                      0
                    ),
                  ]),
                ])
              : _c("div", [_c("p", { staticClass: "no_result" })]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }