var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list_box" }, [
    _c(
      "ul",
      { attrs: { id: "tripBannerList" } },
      _vm._l(_vm.banners, function (banner, index) {
        return _c("li", { key: index }, [
          _c(
            "a",
            {
              staticClass: "prd_area",
              attrs: { href: banner.displayObjectLinkUrl },
            },
            [
              _c("img", {
                staticClass: "prd_bnr",
                attrs: {
                  src: _vm.V_UPLOAD_URL + banner.linkBannerImageUrl,
                  alt: banner.displayObjectName,
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "bg_gradation" }),
              _vm._v(" "),
              _c("div", { staticClass: "prd_info" }, [
                _c("img", {
                  staticClass: "bnr_s",
                  attrs: {
                    src: _vm.V_UPLOAD_URL + banner.linkBannerImageUrl2,
                    alt: banner.displayObjectName,
                  },
                }),
                _vm._v(" "),
                _c("strong", { staticClass: "bnr_title" }, [
                  _vm._v(_vm._s(banner.displayObjectName)),
                ]),
                _vm._v(" "),
                _c("span", {
                  staticClass: "bnr_txt",
                  domProps: { innerHTML: _vm._s(banner.extraText) },
                }),
              ]),
            ]
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }