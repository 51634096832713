<template>
  <div class="popular_trip" v-if="citys && citys.length > 0">
    <p class="title"><span class="ico"></span>인기도시</p>
    <ul class="list_box">
      <li v-for="(city, index) in citys" :key="index"><span class="tx_bar"></span><a href="javascript:" @click="setCity(city)">{{city.dispObjNm}}</a></li>
    </ul>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    props: ['categoryId'],
    created() {
        this.fetchApi(this.categoryId);
    },
    computed : {
        ...mapGetters({
            citys : 'TOUR_MAIN/GET_POPULAR_CITY_LIST'
        })
    },
    methods : {
        fetchApi(key) {
            this.$store.dispatch('TOUR_MAIN/FETCH_POPULAR_CITY_LIST', key);
        },
        getKeywordInfo(list){
            let reslut = list.filter(item=>item.type === "city");

            if(reslut && reslut.length > 0){
                this.$emit("setPopularCity",reslut[0]);
            }
        },
        fetchKeywordApi(key){
            this.$store.dispatch('TOUR_MAIN/FETCH_POPULAR_CITY_KEYWORD',key).then(response=>{
                if(response.data.data.getAhotelAutoKeyWordList) {
                    this.getKeywordInfo(response.data.data.getAhotelAutoKeyWordList)
                }
            });
        },
        setCity(data){
            this.fetchKeywordApi(data.dispObjNm);
        }
    }
}
</script>

