<template>
  <div class="promotion_box" v-if="banner">
    <a href="javascript:" @click="openPopup" class="lk_bnr" target="_blank" title="새 창 이동">
      <img :src="V_UPLOAD_URL+banner.linkBannerImageUrl" :alt="banner.displayObjectName">
    </a>
  </div>
</template>

<script>
import * as KEY from '@/common/constants/tourConst' ;
import {mapGetters} from "vuex";
import {V_UPLOAD_URL} from '@/common/const.js';
import {fetchApi} from '../mixin/service';
export default {
    mixins : [fetchApi],
    computed : {
        ...mapGetters({
            banner : 'TOUR_MAIN/GET_LINE_BANNER',
        })
    },
    data(){
        return {
            V_UPLOAD_URL,
        }
    },
    methods:{
        async fetchData(){
            const data = await this.fetchApi(KEY.PC_TOUR_MAIN_LINE_RENTCAR_BANNER_KEY);
            if(data && data.length > 0){
                this.$store.commit('TOUR_MAIN/SET_LINE_BANNER',data[0])
            }
        },
        openPopup() {
            window.open(this.banner.displayObjectLinkUrl);
        },
    }
}
</script>

