var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "content", attrs: { id: "container", role: "main" } },
    [
      _c(
        "div",
        {
          staticClass: "main_article",
          style: {
            backgroundImage:
              "url('" + (_vm.V_UPLOAD_URL + _vm.backgroundImg) + "')",
          },
        },
        [
          _c("quick-search-comp"),
          _vm._v(" "),
          _c("season-billboard-comp"),
          _vm._v(" "),
          _c("promotion-banner-comp"),
          _vm._v(" "),
          _c("line-banner-comp"),
          _vm._v(" "),
          _c("trip-deal-comp"),
          _vm._v(" "),
          _c("weekend-special-comp"),
          _vm._v(" "),
          _c("best-prd-list-comp"),
          _vm._v(" "),
          _c("strong-point-comp"),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }