var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.prdInfo.tabList
    ? _c("div", { staticClass: "tab_menu_type_1" }, [
        _c(
          "ul",
          _vm._l(_vm.prdInfo.tabList, function (tab, index) {
            return _c(
              "li",
              {
                key: index,
                class: {
                  first: index == 0,
                  last: index + 1 == _vm.prdInfo.tabList.length,
                  selected: tab.dispObjNo == _vm.prdInfo.activeDispNo,
                },
                on: {
                  mouseover: function ($event) {
                    return _vm.doMouseOver(tab)
                  },
                },
              },
              [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "log",
                        rawName: "v-log",
                        value: {
                          data: {
                            area: "tour_" + _vm.id,
                            label: "category",
                            body: {
                              content_no: tab.dispObjNo,
                              content_type: "CATEGORY",
                              content_name: tab.tabName,
                            },
                          },
                        },
                        expression:
                          "{ data: { area: 'tour_'+id, label: 'category', body : {\n'content_no':tab.dispObjNo, 'content_type':'CATEGORY', 'content_name':tab.tabName} }}",
                      },
                    ],
                    attrs: { href: "javascript:" },
                    on: {
                      click: function ($event) {
                        return _vm.moveCategory(tab.dispObjNo, true)
                      },
                    },
                  },
                  [_vm._v(_vm._s(tab.tabName))]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "ico_dot" }),
              ]
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }