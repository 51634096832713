import {calendarFormat, generatorHahkey, setLocalStorage} from "@/main/utils/main";

export const fetchApi={
    created() {
        this.fetchData();
    },
    methods:{
        fetchApi(key){
            return new Promise((resolve, reject) => {
                this.$store.dispatch('TOUR_MAIN/FETCH_DISPLAY_OBJECT',key).then(response=>{
                   resolve(response.data.data.getDisplaySpaceObject)
                });
            })
        }
    }
}

export const layerClick={
    data(){
        return {
            isShowLayer : false,
            preventEventCall : false,
        }
    },
    created(){
        window.addEventListener('click', this.close);
    },

    beforeDestroy() {
        window.removeEventListener('click', this.close);
    },

    methods:{
        close(e){
            let el = $(this.$el);
            this.s_close(e,el);
            this.preventEventCall = false;
        },
        layerClosed(){
            this.isShowLayer = false;
            this.preventEventCall = true;
        },
        layerOpen() {
            this.isShowLayer = true;
            this.preventEventCall = false;
        },
    }
}

export const calendar = {
    mixins :[layerClick],
    data(){
        return {
          isSelectDate : false,
          dateList : null
        }
    },
    components: {
        calendarLayer : () => import(/* webpackChunkName: "view-main-modal" */'@/common/components/calendar/calendarLayer_pc')
    },
    methods : {
        s_close(e,el){
            this.isShowLayer = (!this.preventEventCall && ((el.is(e.target) || el.has(e.target).length > 0) || e.target.attributes.getNamedItem('time') !== null));
        },
        setDate(date){
            this.dateList = Object.assign([],date);
            this.isSelectDate = true;
            this.$emit('sendDate', date);
            this.layerClosed();
        },
        changeDate(date){

            let selectDate = [];
            date.forEach(data=>{
                selectDate.push(calendarFormat(data));
            });

            this.checkDate(selectDate);
        }
    }
}


export const localStorage = {
    methods : {
        setStorageData(data){
            let hashKey = generatorHahkey(this.localStorage.method,data);
            let stg_param = {...data,...{hashKey}};

            let org_stg = this.mainStorageData.fetchItems();
            let new_stg = this.mainStorageData.reorderList(org_stg,stg_param);

            this.mainStorageData.resetItem();

            if(new_stg && new_stg.length > 0){
                setLocalStorage(new_stg);
            }
        }
    }
}

