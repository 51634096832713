<template>
  <section class="trip_strong_point_box">
    <ul class="list_box">
      <li>
        <span class="ico_visual_01"></span>
        <dl>
          <dt><a v-log="{ data: { area: 'about_tour', label: 'btn', body : { content_type : 'CATAGORY', content_no: TOUR_CONST.PC_FLIGHT_CATEGORY_NO,content_name:'항공권 가격비교'}}}" :href="V_PC_FLIGHT_ROOT_URL_HTTPS_">항공권 가격비교<span class="arrow"></span></a></dt>
          <dd>여기저기 헤매지 말고, 11번가에서<br/> <strong>전세계 노선의 스케쥴과 가격</strong>을 비교해 보세요.</dd>
        </dl>
      </li>
      <li>
        <span class="ico_visual_02"></span>
        <dl>
          <dt>
            <a v-log="{ data: { area: 'about_tour', label: 'btn', body : { content_type : 'CATAGORY', content_no: TOUR_CONST.PC_AHOTEL_CATEGORY_NO,content_name:'해외호텔'}}}"
              :href="`${V_PC_TOUR_ROOT_URL_}/tour/TourfrontCategoryAction.tmall?method=getCategory&dispCtgrNo=${TOUR_CONST.PC_AHOTEL_CATEGORY_NO}`">해외호텔<span
              class="arrow"></span></a> 
            <a v-log="{ data: { area: 'about_tour', label: 'btn', body : { content_type : 'CATAGORY', content_no: TOUR_CONST.DHOTEL_CATEGORY_NO,content_name:'국내숙박'}}}" :href="`${V_PC_TOUR_ROOT_URL_}/tour/TourfrontDomesticAction.tmall?method=main&isWingBnnr=N`">국내숙박<span
              class="arrow"></span></a>
          </dt>
          <dd>국내는 물론, 해외까지 <strong>내가 가고싶은<br/> 지역의 숙소를 실시간으로 예약</strong>할 수 있어요!</dd>
        </dl>
      </li>
      <li>
        <span class="ico_visual_03"></span>
        <dl>
          <dt>
            <a v-log="{ data: { area: 'about_tour', label: 'btn', body : { content_type : 'CATAGORY', content_no: TOUR_CONST.PC_FOREIGN_PASS_CATEGORY_NO,content_name:'패스/현지투어'}}}"
              :href="`${V_PC_TOUR_ROOT_URL_}/tour/TourfrontCategoryAction.tmall?method=getCategory&dispCtgrNo=${TOUR_CONST.PC_FOREIGN_PASS_CATEGORY_NO}`">패스/현지투어<span
              class="arrow"></span></a>
          </dt>
          <dd>여행지를 결정했다면, <strong>패스/입장권을<br/> 미리구매</strong>하고 알뜰한 여행하세요!</dd>
        </dl>
      </li>
      <li>
        <span class="ico_visual_04"></span>
        <dl>
          <dt><a v-log="{ data: { area: 'about_tour', label: 'btn', body : { content_type : 'CATAGORY', content_no: TOUR_CONST.PC_FOREIGN_TOUR_CATEGORY_NO,content_name:'해외여행'}}}"
            :href="`${V_PC_TOUR_ROOT_URL_}/tour/TourfrontCategoryAction.tmall?method=getCategory&dispCtgrNo=${TOUR_CONST.PC_FOREIGN_TOUR_CATEGORY_NO}`">해외여행<span
            class="arrow"></span></a></dt>
          <dd>11번가에는 <strong>국내유명 여행사들이 다 모였다!</strong><br/>하나하나 검색하지말고 <strong>한번에 비교검색</strong>하세요.</dd>
        </dl>
      </li>
    </ul>
  </section>
</template>

<script>
import log from '@/common/directives/log';
import {V_PC_TOUR_ROOT_URL_,V_PC_FLIGHT_ROOT_URL_HTTPS_} from '@/common/const.js';
import * as CONST from '@/common/constants/tourConst' ;
export default {
    directives: {log},
    data(){
        return {
            V_PC_TOUR_ROOT_URL_,
            V_PC_FLIGHT_ROOT_URL_HTTPS_,
            TOUR_CONST : CONST
        }
    }
}
</script>

