var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "option_check_box_2" }, [
    _c("div", { staticClass: "option_select_box_1" }, [
      _c(
        "div",
        { staticClass: "option_detail" },
        [
          _c("city-select-box", { attrs: { data: _vm.departure } }),
          _vm._v(" "),
          _c("span", { staticClass: "type_info_1" }, [_vm._v("왕복")]),
          _vm._v(" "),
          _c("city-select-box", { attrs: { data: _vm.arrival } }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        directives: [
          {
            name: "log",
            rawName: "v-log",
            value: {
              data: { area: "quicksearch_tour", label: "abroad_search" },
            },
            expression:
              "{ data: { area: 'quicksearch_tour', label: 'abroad_search' }}",
          },
        ],
        staticClass: "btn_type_01",
        attrs: { type: "button" },
        on: { click: _vm.moveToCategoryMain },
      },
      [_vm._v("검색")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }