<template>
  <div class="option_select_box_2">
    <div class="option_detail">
      <button type="button" :class="['info_input_1',{focus:isShowLayer,active:isSelectDate}]"  @click="layerOpen">
        <span class="in_btn">{{ startDate &&  startDate.length >0? startDate[0] : calnderOption.startText}}</span><span class="cal"></span>
      </button>
      <button type="button" :class="['info_input_1',{focus:isShowLayer,active:isSelectDate}]"  @click="layerOpen">
        <span class="in_btn">{{ startDate &&  startDate.length >0? startDate[1] : calnderOption.endText}}</span><span class="cal"></span>
      </button>
      <calendar-layer :isShowLayer="isShowLayer" :sendDate="dateList" :option="calnderOption" @closedLayer="layerClosed" @changeDate="changeDate"/>
    </div>
  </div>
</template>

<script>
import {calendar} from '../../../mixin/service'
import {isParamCheck} from "@/main/utils/main";

export default {
    mixins :[calendar],
    props : {
        startDate:{
            type: Array,
            default() {
                return null
            }
        },
        minDate : {
            type: Date,
            default() {
                return {}
            }
        }
    },
    data(){
        let calnderOption = {
            multiSelect : 2,      //복수 선택 : 출발일만 선택하게 함.
            startText : "체크인",  //달력에 표시하는 시작날짜 text 설정
            endText : "체크아웃",         //달력에 표시하는 종료날짜 text 설정
            minDate : this.minDate //국내숙박은 여행은 현재날짜 부터 선택 가능해야 함.
        };
        return {
            calnderOption,
        }
    },
    mounted() {
        this.initDate();
    },
    methods:{
        initDate(){
            this.dateList = Object.assign([],this.startDate);
        },
        checkDate(selectDate){
            if(!isParamCheck(selectDate[0],this.dateList[0],selectDate[1],this.dateList[1])){
                this.setDate(selectDate);
            }
        }
    }
}
</script>

